/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */

/* eslint-disable @intercom/intercom/no-bare-strings */
import { CONVERSATION_RATING_IDS } from 'predicates/models/data/conversation-ratings';

export const MEDIAN = 'median';
export const MEAN = 'mean';
export const MAX = 'max';
export const MIN = 'min';
export const COUNT = 'count';
export const CARDINALITY = 'cardinality';
export const RANGE = 'range';
export const SORTING_LABEL_NAME = 'label_name';
export const SORTING_LABEL_NUMERIC = 'label_numeric';

export const REPORTING_FILTER_SELECT_ALL = 'any';

export const CONVERSATION_CREATED_AT = 'conversation_created_at';
export const CONVERSATION_CREATED_AT_DAY_OF_WEEK = 'conversation_created_at_day_of_week';
export const COMMENT_CREATED_AT = 'comment_created_at';
export const COMMENT_CREATED_AT_DAY_OF_WEEK = 'comment_created_at_day_of_week';
export const TEAMMATE_RESPONSE_TIME = 'response_time';
export const FIRST_TEAMMATE_RESPONSE_TIME = 'first_response_time';
export const COUNTRY = 'user_location.country_name';
export const CONTINENT = 'user_location.continent_code';
export const GEO_GROUPING_LIMIT = 10;
export const COUNTRY_GROUPING_TERM_SIZE = 1024;
export const CONTINENT_GROUPING_TERM_SIZE = 7;
export const FIRST_USER_CONVERSATION_PART_CREATED_AT = 'first_user_conversation_part_created_at';
export const FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK =
  'first_user_conversation_part_created_at_day_of_week';
export const MAX_FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK =
  'max_first_user_conversation_part_created_at_day_of_week';
export const FIRST_CLOSED_AT = 'first_closed_at';
export const FIRST_REOPENED_AT = 'first_reopened_at';
export const STATE_START = 'state_start';
export const CLOSE_DATE = 'close_date';
export const MEETING_BOOKED_AT = 'meeting_booked_at';
export const TIME = 'time';
export const BUCKET = 'bucket';
export const PERCENT = 'percent';
export const VALUE = 'value';
export const HOUR_OF_DAY = 'hour_of_day';
export const DAY_OF_WEEK = 'day_of_week';
export const IS_RESOLVED_FIRST_CONTACT = 'is_resolved_first_contact';
export const CLUSTERED_TEAMMATE_REPLIES_COUNT = 'clustered_teammate_replies_count';
export const CONVERSATION_STATE = 'conversation_state';
export const NEW_CONVERSATION_STATE = 'new_conversation_state';
export const PREVIOUS_CONVERSATION_STATE = 'previous_conversation_state';
export const FIRST_ADMIN_REPLY_AT = 'first_admin_reply_at';
export const CONVERSATION_ID = 'conversation_id';
export const REASSIGNMENTS_COUNT_AFTER_ADMIN_REPLY = 'reassignments_count_after_admin_reply';
export const ASSIGNMENT_BEFORE_FIRST_ADMIN_REPLY_TIME = 'assignment_before_first_admin_reply_time';
export const LAST_ASSIGNMENT_TO_CLOSE_TIME = 'last_assignment_to_close_time';
export const LAST_TEAM_ASSIGNED_BEFORE_REPLY = 'last_team_assigned_before_first_admin_reply';

export const COMMENT_TEAM_ASSIGNED = 'comment_team_assignee_id';
export const TEAM_GROUPING_TERM_SIZE = 10000; //limits ES aggregation
export const TEAM_GROUPING_LIMIT = 10; //limits the legend

export const AVAILABLE_CONTINENT_MAPPINGS = {
  AF: 'Africa',
  AN: 'Antarctica',
  AS: 'Asia',
  EU: 'Europe',
  NA: 'North America',
  OC: 'Oceania',
  SA: 'South America',
};

export const AVAILABLE_CHANNEL_MAPPINGS = {
  Desktop: 'Chat',
  Push: 'Mobile Push',
};

export const AVAILABLE_FIN_ANSWER_TYPE_MAPPINGS = {
  ai_answer: 'AI answer',
  custom_answer: 'Custom answer',
};

export const AVAILABLE_FIN_RESOLUTION_STATE_MAPPINGS = {
  confirmed_resolved: 'Confirmed resolved',
  assumed_resolved: 'Assumed resolved',
  routed_to_team: 'Routed to team',
  negative_feedback: 'Negative feedback',
  remaining: 'Unresolved',
};

export const AVAILABLE_SLA_TARGET_TYPE_MAPPINGS = {
  first_reply_time: 'First response time',
  next_reply_time: 'Next response time',
  resolution_time: 'Time to resolve',
  time_to_close: 'Time to close',
};

export const COLOR_MAPPINGS = {
  'fin.resolution_state': {
    confirmed_resolved: 'vis-mint-40',
    assumed_resolved: 'vis-mint-80',
    remaining: 'gray-light',
  },
  'fin.participated': {
    0: 'vis-azure-80',
    1: 'vis-azure-40',
    remaining: 'vis-azure-80',
  },
  'workflow_conversation_rating.rating_index': {
    [CONVERSATION_RATING_IDS.AMAZING]: 'vis-green-40',
    [CONVERSATION_RATING_IDS.GREAT]: 'vis-green-60',
    [CONVERSATION_RATING_IDS.OK]: 'vis-yellow-70',
    [CONVERSATION_RATING_IDS.BAD]: 'vis-yellow-50',
    [CONVERSATION_RATING_IDS.TERRIBLE]: 'vis-salmon-40',
    Unknown: 'gray',
  },
  'bot_conversation_rating.rating_index': {
    [CONVERSATION_RATING_IDS.AMAZING]: 'vis-green-40',
    [CONVERSATION_RATING_IDS.GREAT]: 'vis-green-60',
    [CONVERSATION_RATING_IDS.OK]: 'vis-yellow-70',
    [CONVERSATION_RATING_IDS.BAD]: 'vis-yellow-50',
    [CONVERSATION_RATING_IDS.TERRIBLE]: 'vis-salmon-40',
    Unknown: 'gray',
  },
  'conversation_rating.rating_index': {
    [CONVERSATION_RATING_IDS.AMAZING]: 'vis-green-40',
    [CONVERSATION_RATING_IDS.GREAT]: 'vis-green-60',
    [CONVERSATION_RATING_IDS.OK]: 'vis-yellow-70',
    [CONVERSATION_RATING_IDS.BAD]: 'vis-yellow-50',
    [CONVERSATION_RATING_IDS.TERRIBLE]: 'vis-salmon-40',
    Unknown: 'gray',
  },
  rating_index: {
    [CONVERSATION_RATING_IDS.AMAZING]: 'vis-green-40',
    [CONVERSATION_RATING_IDS.GREAT]: 'vis-green-60',
    [CONVERSATION_RATING_IDS.OK]: 'vis-yellow-70',
    [CONVERSATION_RATING_IDS.BAD]: 'vis-yellow-50',
    [CONVERSATION_RATING_IDS.TERRIBLE]: 'vis-salmon-40',
    Unknown: 'gray',
  },
  conversation_state: {
    opened: 'vis-pink-50',
    snoozed: 'vis-turquoise-50',
    closed: 'vis-slateblue-50',
  },
  'user.location.continent_code': {
    AF: 'vis-orange-50',
    AN: 'vis-blue-50',
    AS: 'vis-mint-50',
    EU: 'vis-azure-50',
    NA: 'vis-salmon-50',
    OC: 'vis-yellow-50',
    SA: 'vis-turquoise-50',
    Unknown: 'gray',
  },
  channel_type: {
    'Android App': 'vis-salmon-50',
    Desktop: 'vis-azure-50',
    Email: 'vis-yellow-50',
    Instagram: 'vis-slateblue-50',
    'iOS App': 'vis-mint-50',
    'Phone call': 'vis-olive-50',
    'Phone switch': 'vis-blue-50',
    SMS: 'vis-orange-50',
    Twitter: 'vis-pink-50',
    WhatsApp: 'vis-turquoise-50',
    Unknown: 'gray',
  },
};

export const SERIES_COLOR_PALETTE = [
  'vis-azure',
  'vis-yellow',
  'vis-mint',
  'vis-salmon',
  'vis-slateblue',
  'vis-olive',
  'vis-pink',
  'vis-turquoise',
  'vis-orange',
  'vis-blue',
];

export const CSAT_METRICS = [
  'conversation_rating.human.csat',
  'conversation_rating.human.csat.first_user_conversation_part_created_at',
  'conversation_rating.workflows.csat',
  'conversation_rating.ai_agent.csat',
  'conversation_rating.any_agent.csat',
];

export const CSAT_COLOR_MAPPINGS = [
  [0, 'vis-salmon-40'],
  [0.25, 'vis-yellow-50'],
  [0.5, 'vis-yellow-70'],
  [0.75, 'vis-green-60'],
  [1, 'vis-green-40'],
];

export const DEFAULT_HEATMAP_COLORS = [
  [0, 'vis-blue-80'],
  [0.14, 'vis-blue-70'],
  [0.28, 'vis-blue-60'],
  [0.5, 'vis-blue-50'],
  [0.68, 'vis-blue-40'],
  [0.82, 'vis-blue-30'],
  [1, 'vis-blue-20'],
];

export const COUNTRY_ALIASES = {
  Unknown: 'Other',
  'Russian Federation': 'Russia',
  'Republic of Lithuania': 'Lithuania',
  'Iran, Islamic Republic of': 'Iran',
  'Republic of Moldova': 'Moldova',
  'Moldova, Republic of': 'Moldova',
  'Korea, Republic of': 'Republic of Korea',
  'Republic of the Congo': 'Congo',
  'DR Congo': 'Democratic Republic of the Congo',
  'Congo, The Democratic Republic of the': 'Democratic Republic of the Congo',
};

export const COUNTRY_NAME_TO_VALUE_LOOKUP = aggregateCountryNamesToArray(COUNTRY_ALIASES);

export const CHANNEL_GROUPING_TERM_SIZE = 7;
export const CHANNEL = 'channel_type';
export const CHANNEL_GROUPING_LIMIT = 100;

// Return a hash of 'public label' => [values], with `values` being the accumulated list of acceptable names for a country
// i.e. {'France, Republic of': 'France', 'Republic of France': 'France'} will produce { 'France': ['France', 'Republic of France', 'France, Republic of'] }
function aggregateCountryNamesToArray(countries) {
  return Object.keys(countries).reduce((acc, key) => {
    let expectedName = countries[key];
    if (acc[expectedName]) {
      acc[expectedName].push(key);
    } else {
      acc[expectedName] = [expectedName, key];
    }

    return acc;
  }, {});
}

export const INBOUND = 'inbound';
export const INBOUND_TEXT = 'Customer';
export const OUTBOUND = 'outbound';
export const OUTBOUND_TEXT = 'Reply to Bot/Message';
export const CONVERSATION_TYPE_MAPPINGS = {
  [INBOUND]: ['UserMessage', 'BotIntroMessage'],
  [OUTBOUND]: [
    'BotAutoMessage',
    'VisitorAutoMessage',
    'AutoMessage',
    'TriggeredMessage',
    'ManualMessage',
    'CampaignMessage',
    'DisplayableContent',
  ],
};

export const PARTICIPANT_TYPES = [
  { value: 'user', text: 'User' },
  { value: 'teammate', text: 'Teammate' },
];

export const PARTICIPANT_TYPE_VALUES = PARTICIPANT_TYPES.map((type) => type.value);

export const AVAILABLE_FILTER_CONFIGS = {
  continents: {
    selectorLabelIntlKey: 'reporting.flexible-filters.continent.label',
    selectorValue: 'continents',
    component: 'reporting/flexible/filter-bar/continent-filter',
    icon: 'globe',
    isPaywalled: true,
  },
  countries: {
    selectorLabelIntlKey: 'reporting.flexible-filters.countries.label',
    selectorLabel: 'Country',
    selectorValue: 'countries',
    component: 'reporting/flexible/filter-bar/country-filter',
    icon: 'globe',
    isPaywalled: true,
  },
  channels: {
    selectorLabelIntlKey: 'reporting.flexible-filters.channels.label',
    selectorLabel: 'Channel',
    selectorValue: 'channels',
    component: 'reporting/flexible/filter-bar/channel-filter',
    icon: 'multiplatform',
    isPaywalled: true,
  },
  startedByUser: {
    selectorLabelIntlKey: 'reporting.flexible-filters.started-by-user.label',
    selectorLabel: 'Started by',
    selectorValue: 'startedByUser',
    component: 'reporting/flexible/filter-bar/started-by-user-filter',
    icon: 'conversation',
  },
  scopingTagIds: {
    selectorLabelIntlKey: 'reporting.flexible-filters.scoping-tag-ids.label',
    selectorLabel: 'Tag',
    selectorValue: 'scopingTagIds',
    component: 'reporting/flexible/filter-bar/tag-filter',
    icon: 'tag',
  },
  teammatesParticipated: {
    selectorLabelIntlKey: 'reporting.flexible-filters.teammates-participated.label',
    selectorLabel: 'Teammate',
    selectorValue: 'teammatesParticipated',
    component: 'reporting/flexible/filter-bar/teammate-participated-filter',
    icon: 'person',
  },
  teamsParticipated: {
    selectorLabelIntlKey: 'reporting.object-catalog.humanized-names.team-replied-to',
    selectorLabel: 'Team participated',
    selectorValue: 'teamsParticipated',
    component: 'reporting/flexible/filter-bar/team-participated-filter',
    icon: 'multiple-people',
  },
  botInboxTime: {
    selectorLabelIntlKey: 'reporting.flexible-filters.bot-inbox-time.label',
    selectorLabel: 'Bot inbox time',
    selectorValue: 'botInboxTime',
    component: 'reporting/flexible/filter-bar/bot-inbox-time-filter',
    icon: 'conversation',
  },
  teammatesAssigned: {
    selectorLabelIntlKey: 'reporting.object-catalog.humanized-names.teammate-assigned',
    selectorLabel: 'Teammate assigned',
    selectorValue: 'teammatesAssigned',
    component: 'reporting/flexible/filter-bar/teammate-assigned-filter',
    icon: 'person',
  },
  teamsAssigned: {
    selectorLabelIntlKey: 'reporting.object-catalog.humanized-names.team-currently-assigned',
    selectorLabel: 'Team assigned',
    selectorValue: 'teamsAssigned',
    component: 'reporting/flexible/filter-bar/team-assigned-filter',
    icon: 'multiple-people',
  },
  slas: {
    selectorLabelIntlKey: 'reporting.flexible-filters.slas.label',
    selectorLabel: 'SLA',
    selectorValue: 'slas',
    component: 'reporting/flexible/filter-bar/sla-filter',
    icon: 'sla',
  },
  topics: {
    selectorLabelIntlKey: 'reporting.flexible-filters.topics.label',
    selectorLabel: 'Topic',
    selectorValue: 'topics',
    component: 'reporting/flexible/filter-bar/topic-filter',
    icon: 'insights',
  },
  participantTypes: {
    selectorLabelIntlKey: 'reporting.flexible-filters.participant-types.label',
    selectorLabel: 'Participant type',
    selectorValue: 'participantTypes',
    component: 'reporting/flexible/filter-bar/participant-type-filter',
    icon: 'multiple-people',
  },
  ticket_type_ids: {
    selectorLabel: 'Ticket type',
    selectorLabelIntlKey: 'app.lib.reporting.custom.available-filter-configs.ticket-type-id.label',
    selectorValue: 'ticket_type_ids',
    type: 'ticket_type_id',
    component: 'reporting/custom/chart-builder/filter-bar/ticket-type-filter',
    isStandardAttribute: true,
    tooltipText: 'This filters by ticket type.',
    tooltipTextIntlKey: 'app.lib.reporting.custom.available-filter-configs.ticket-type-id.tooltip',
    icon: 'ticket',
    attributeType: 'integer',
    loadKnownValuesSources: ['tickets'],
  },
};

export const AVAILABLE_FILTERS_WITH_CUSTOM_ATTRIBUTES = Object.keys(AVAILABLE_FILTER_CONFIGS)
  .map((key) => AVAILABLE_FILTER_CONFIGS[key].selectorValue)
  .concat('customAttributes', 'teammateId');

export const REPLIES_ONLY_FILTER = {
  type: 'not_in_category',
  data: {
    property: 'is_reply',
    values: [false],
  },
};

export const USER_PART_EXISTS_FILTER = {
  type: 'not_in_category',
  data: {
    property: 'conversation_has_user_reply',
    values: [false],
  },
};

export const EXCLUDE_BOT_PARTS_FILTER = {
  type: 'not_in_category',
  data: {
    property: 'is_bot',
    values: [true],
  },
};

export const CONVERSATION_PART_REPLIES_FILTERS = [
  REPLIES_ONLY_FILTER,
  USER_PART_EXISTS_FILTER,
  EXCLUDE_BOT_PARTS_FILTER,
];

export const TEAMMATE_REPLIES_FILTERS = [REPLIES_ONLY_FILTER, EXCLUDE_BOT_PARTS_FILTER];

export const CLOSES_ONLY_FILTER = {
  type: 'category',
  data: {
    property: 'new_conversation_state',
    values: ['closed'],
  },
};

export const CLOSES_WITH_USER_PART_FILTERS = [CLOSES_ONLY_FILTER, USER_PART_EXISTS_FILTER];

export const EXCLUDE_BOT_ADMIN = {
  type: 'not_in_category',
  data: {
    property: 'is_bot',
    values: [true],
  },
};

export const TEAM_PROPERTY_IDENTIFIERS = new Set([
  'team.id',
  'assigned_team_id',
  '$admin_participant_ids',
  'team_assignee_id',
  'team.id',
  'comment_team_assignee_id',
  'team',
]);

export const TEAMMATE_PROPERTY_IDENTIFIERS = new Set([
  'admin_participant_ids',
  'admin_assignee_id',
  '$admin_participant_ids',
  'teammate_id',
  'conversation_rating.admin_id',
  'comment_admin_assignee_id',
  'teammate_handled',
  'first_reply_teammate_id',
  'attributed_to_admin_id',
  'last_human_authored_closer_teammate_id',
  'first_human_authored_closer_teammate_id',
  'teammate_closed',
  'mentions',
  'admin_id',
  'call_admin_ids',
  'teammate',
  'assigned_teammate_id',
  'teammate_assignee_at_close',
  'teammate.id',
  'rated_actor_id.human_id',
  'rating_participants.human_ids',
]);
