/* RESPONSIBLE TEAM: team-ai-agent-2 */

export type PlaygroundGroupSummaryWireFormat = {
  id: string;
  group_name: string;
  group_description: string;
};

export class PlaygroundGroupSummary {
  id: number;
  name: string;
  description: string;

  constructor(id: number, name: string, description: string) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  static deserialize(data: PlaygroundGroupSummaryWireFormat): PlaygroundGroupSummary {
    return new PlaygroundGroupSummary(Number(data.id), data.group_name, data.group_description);
  }
}
