/* RESPONSIBLE TEAM: team-knowledge-foundations */

const CAN_MANAGE_ARTICLES_PERMISSION = 'can_manage_articles';
const CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION = 'can_create_and_edit_draft_articles';
const CAN_MANAGE_ARTICLES_SAVED_VIEWS = 'can_articles__saved_views__manage';

const RTL_LANGUAGES = ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi'];

const MAX_ARTICLES_IN_A_COLLECTION = 500;

export {
  CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION,
  CAN_MANAGE_ARTICLES_PERMISSION,
  RTL_LANGUAGES,
  CAN_MANAGE_ARTICLES_SAVED_VIEWS,
  MAX_ARTICLES_IN_A_COLLECTION,
};
