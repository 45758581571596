/* RESPONSIBLE TEAM: team-ml */
import ENV from 'embercom/config/environment';

export default {
  zendesk_chat: {
    authorizeUrl: (accountName) =>
      `https://${accountName}.zendesk.com/oauth2/chat/authorizations/new`,
    redirectUrl:
      ENV.environment === 'production'
        ? `https://${location.hostname}/zendesk_chat_oauth`
        : 'http://localhost:3000/zendesk_chat_oauth',
  },
  zendesk_tickets: {
    authorizeUrl: (accountName) => `https://${accountName}.zendesk.com/oauth/authorizations/new`,
    redirectUrl:
      ENV.environment === 'production'
        ? `https://${location.hostname}/zendesk_chat_oauth`
        : ENV.APP.zendeskTicketsImporter.devRedirectUri ||
          `http://localhost:3000/zendesk_chat_oauth`,
  },
};
