/* RESPONSIBLE TEAM: team-self-serve */
export const MARKETO_FORM_IDS = {
  trialSignupStart: 1037,
  trialSignupAppCreated: 1039,
  trialSignupEnd: 1038,
  earlyStage: 1041,
  escapeHatch: 1312,
  developerSignup: 1425,
  addOns: 1507,
};
export const MARKETO_ACCOUNT_ID = '258-CLW-344';

export const MARKETO_URL = '//app-ab27.marketo.com';
