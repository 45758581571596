/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { setOwner } from '@ember/application';
import ContentServiceSearchAPI, {
  type SyncIssuesFilterOptions,
  type ContentSearchParams,
  type ContentServiceSearchResponse,
  type FolderFilterOption,
} from 'embercom/lib/content-service/search-api';
import { tracked } from '@glimmer/tracking';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { inject as service } from '@ember/service';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import { EntityType } from 'embercom/models/data/entity-types';
import { type TagOperator, type SortDirection } from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type Predicate } from '@intercom/interblocks.ts';
import type Store from '@ember-data/store';
import type Folder from 'embercom/models/content-service/folder';
import ENV from 'embercom/config/environment';
import { type AiContentState } from '../ai-content-library/constants';
import { type ArticlePublishedStatuses } from 'embercom/lib/knowledge-hub/constants';

export const DEFAULT_PAGE_SIZE = 25;

export interface KnowledgeHubApiQueryParams {
  searchTerm?: string;
  linkedUrls?: string;
  selectedTypes?: string;
  locale?: string;
  status?: ArticlePublishedStatuses;
  createdBy?: string;
  lastUpdatedBy?: string;
  predicates?: string;
  selectedFolderFilterOption?: FolderFilterOption;
  selectedSyncIssueOption?: SyncIssuesFilterOptions;
  selectedHelpCenterIds?: string;
  sortDirectionParam?: SortDirection;
  sortByParam?: string;
  chatbotState?: AiContentState;
  copilotState?: AiContentState;
  tagIds?: string;
  tagOperator?: TagOperator;
  collectionIds?: string;
  writtenByIds?: string;
}

export const KNOWLEDGE_BLANK_QUERY_PARAMS = {
  activeFilter: undefined,
  filtersApplied: undefined,
  locale: undefined,
  status: undefined,
  createdBy: undefined,
  lastUpdatedBy: undefined,
  predicates: undefined,
  folder_filter_option: undefined,
  needs_review: undefined,
  chatbotState: undefined,
  copilotState: undefined,
  tagIds: undefined,
  tagOperator: undefined,
  collectionIds: undefined,
  writtenByIds: undefined,
};

export default class KnowledgeHubApi {
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare store: Store;

  constructor(
    owner: unknown,
    defaultSearchParams: ContentSearchParams,
    queryParams?: KnowledgeHubApiQueryParams,
  ) {
    setOwner(this, owner);

    this.searchApi = new ContentServiceSearchAPI(owner);

    if (queryParams) {
      this.queryParams = this.searchParamsFromQueryParams(queryParams);
    }

    this.defaultSearchParams = {
      ...defaultSearchParams,
      add_highlight: this.appService.app.canUseFeature('psg-knowledge-hub-search-highlighting'),
    };
    this.searchParams = { ...this.defaultSearchParams, ...this.queryParams };
  }

  searchApi: ContentServiceSearchAPI;

  readonly defaultSearchParams: ContentSearchParams;

  @tracked currentPage = 0;
  @tracked totalPages?: number;
  @tracked totalCount = 0;
  @tracked lastPageHit?: boolean;
  @tracked requiresShowTranslations = false;
  @tracked contentWrappers: Array<ContentWrapper> = [];

  searchParams?: ContentSearchParams;
  queryParams?: Partial<ContentSearchParams>;

  reset() {
    this.currentPage = 0;
    this.totalPages = undefined;
    this.totalCount = 0;
    this.lastPageHit = undefined;
    this.contentWrappers = [];
    this.searchParams = { ...this.defaultSearchParams };
    if (this.searchParams.additional_searchable_data) {
      this.searchParams.additional_searchable_data = {};
    }
    this.queryParams = {};
    this.requiresShowTranslations = false;
  }

  // method to convert queryParams to searchParams
  searchParamsFromQueryParams(queryParams: KnowledgeHubApiQueryParams) {
    let searchParams: Partial<ContentSearchParams> = {};

    if (queryParams.searchTerm) {
      searchParams.title = queryParams.searchTerm;
      searchParams.content_terms = queryParams.searchTerm;
    }

    if (queryParams.linkedUrls) {
      searchParams.linked_urls = queryParams.linkedUrls.split(',');
    }

    if (queryParams.sortByParam) {
      searchParams.sort_by = queryParams.sortByParam;
    }

    if (queryParams.sortDirectionParam) {
      searchParams.sort_direction = queryParams.sortDirectionParam;
    }

    if (queryParams.locale) {
      searchParams.locales = [queryParams.locale];
    }

    if (queryParams.selectedSyncIssueOption) {
      searchParams.additional_searchable_data = {
        needs_review: queryParams.selectedSyncIssueOption,
      };
    }

    if (queryParams.predicates && queryParams.predicates.length > 0) {
      searchParams.predicates = JSON.parse(queryParams.predicates) as Array<Predicate>;
    }

    if (queryParams.selectedTypes && queryParams.selectedTypes.length > 0) {
      searchParams.object_types = queryParams.selectedTypes
        .split(',')
        .map((type) => Number(type) as EntityType);
    }

    if (queryParams.selectedFolderFilterOption) {
      searchParams.folder_filter_option = queryParams.selectedFolderFilterOption;
    }

    if (queryParams.status) {
      searchParams.additional_searchable_data = {
        ...searchParams.additional_searchable_data,
        article_published_state: [Number(queryParams.status)],
      };
    }

    if (queryParams.selectedHelpCenterIds && queryParams.selectedHelpCenterIds.length > 0) {
      searchParams.additional_searchable_data = {
        ...searchParams.additional_searchable_data,
        help_center_ids: queryParams.selectedHelpCenterIds.split(',').map((id) => Number(id)),
      };
    }

    if (queryParams.createdBy) {
      searchParams.created_by_ids = [Number(queryParams.createdBy)];
    }

    if (queryParams.lastUpdatedBy && queryParams.lastUpdatedBy.length > 0) {
      searchParams.last_updated_by_ids = queryParams.lastUpdatedBy
        .split(',')
        .map((id) => Number(id));
    }

    if (queryParams.chatbotState) {
      searchParams.chatbot_state = Number(queryParams.chatbotState);
    }

    if (queryParams.copilotState) {
      searchParams.copilot_state = Number(queryParams.copilotState);
    }

    if (queryParams.tagIds && queryParams.tagIds.length > 0) {
      searchParams.tag_ids = queryParams.tagIds.split(',').map((id) => Number(id));
      searchParams.tag_operator = queryParams.tagOperator;
    }

    if (queryParams.collectionIds && queryParams.collectionIds.length > 0) {
      searchParams.additional_searchable_data = {
        ...searchParams.additional_searchable_data,
        collection_ids: queryParams.collectionIds.split(',').map((id) => Number(id)),
      };
    }

    if (queryParams.writtenByIds && queryParams.writtenByIds.length > 0) {
      searchParams.sender_ids = queryParams.writtenByIds.split(',').map((id) => Number(id));
    }

    return searchParams;
  }

  get pageSize(): number {
    return this.searchParams?.per_page ?? this.defaultSearchParams.per_page ?? DEFAULT_PAGE_SIZE;
  }

  private async doSearch(page_from = this.currentPage) {
    let searchParams = Object.assign(
      { per_page: this.pageSize },
      this.defaultSearchParams,
      this.queryParams ?? {},
      this.searchParams,
      { page_from },
    ) as ContentSearchParams;

    if (this.isFiltering) {
      // When filtering, we want to display content from subfolders as well
      if (this.defaultSearchParams.folder_filter_option) {
        searchParams.folder_filter_option = undefined;
      }
      if (searchParams.folder_ids && searchParams.folder_ids.length === 1) {
        let folder = this.store.peekRecord(
          'content-service/folder',
          searchParams.folder_ids[0],
        ) as Folder;
        searchParams.folder_ids = folder?.descendantIds.concat(searchParams.folder_ids);
      }
    }

    if (this.searchParams?.title) {
      searchParams.sort_by_content_type_first = undefined;
    } else {
      searchParams.sort_by_content_type_first = EntityType.ContentLibraryFolder;
    }

    // we always want to search for only articles if these filters are applied
    if (
      searchParams.additional_searchable_data?.article_published_state ||
      searchParams.sender_ids
    ) {
      searchParams.object_types = searchParams.object_types.filter(
        (type: EntityType) => type === EntityType.ArticleContent,
      );
    }

    let response: ContentServiceSearchResponse =
      await this.searchApi.contentSearch<KnowledgeHubContentWrapper>(
        searchParams,
        'content-service/knowledge-hub-content-wrapper',
      );

    return { searchParams, response };
  }

  @task({ restartable: true })
  private *loadPageTask(): TaskGenerator<void> {
    if (this.lastPageHit !== true) {
      let { searchParams, response } = yield this.doSearch();
      this.handleSearchResponse(searchParams, response);
    }
  }

  @task({ restartable: true })
  private *refreshPageTask(): TaskGenerator<void> {
    let { searchParams, response } = yield this.doSearch();
    this.contentWrappers = [];
    this.handleSearchResponse(searchParams, response);
  }

  private handleSearchResponse(
    searchParams: ContentSearchParams,
    response: ContentServiceSearchResponse,
  ) {
    response.contentWrappers.forEach((contentWrapper: KnowledgeHubContentWrapper) => {
      contentWrapper.defaultLocale = searchParams.locales?.firstObject;
    });

    this.totalPages = response.totalPages;
    this.totalCount = response.totalCount;

    if (!response.lastPageHit) {
      this.currentPage = response.pageFrom + 1;
    }

    this.lastPageHit = response.lastPageHit;
    this.contentWrappers = [...this.contentWrappers, ...response.contentWrappers];

    if (searchParams.content_terms || searchParams.title) {
      this.requiresShowTranslations = true;
    }
  }

  get isLoadingPage(): boolean {
    return taskFor(this.loadPageTask).isRunning;
  }

  // remove contentWrapper from the list and update the total count
  removeContentWrapper(contentWrapperId: string) {
    this.contentWrappers = this.contentWrappers.filter((contentWrapper) => {
      if (contentWrapper.id === contentWrapperId) {
        this.totalCount--;
        return false;
      }
      return true;
    });
  }

  private resetFiltersForSearch() {
    this.currentPage = 0;
    this.lastPageHit = false;
    this.contentWrappers = [];
    this.requiresShowTranslations = false;
  }

  async searchByParams(searchArgs: Partial<ContentSearchParams>) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams, ...searchArgs };
    return taskFor(this.loadPageTask).perform();
  }

  async search(searchTerm?: string, linkedUrls?: Array<string>) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    if (searchTerm) {
      this.searchParams.sort_by = undefined;
      this.searchParams.sort_direction = undefined;
    } else {
      this.searchParams.sort_by = this.defaultSearchParams.sort_by;
      this.searchParams.sort_direction = this.defaultSearchParams.sort_direction;
    }
    this.searchParams.content_terms = searchTerm;
    this.searchParams.title = searchTerm;
    this.searchParams.linked_urls = linkedUrls;
    return taskFor(this.loadPageTask).perform();
  }

  async sortUpdate(sortBy?: string, sortDirection?: SortDirection) {
    this.resetFiltersForSearch();
    this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
    this.searchParams.sort_by = sortBy;
    this.searchParams.sort_direction = sortDirection;
    return taskFor(this.loadPageTask).perform();
  }

  async loadPage() {
    return taskFor(this.loadPageTask).perform();
  }

  async refreshPageAfterDelay(delay = ENV.APP._500MS) {
    setTimeout(() => {
      // Allow a few milliseconds for the ES index to update
      this.currentPage = 0;
      taskFor(this.refreshPageTask).perform();
    }, delay);
  }

  get isFiltering() {
    // Changing the sort should not count as filtering
    let defaultSearchParams = {
      ...this.defaultSearchParams,
      sort_by: undefined,
      sort_direction: undefined,
    };
    let searchParams = { ...this.searchParams, sort_by: undefined, sort_direction: undefined };

    return JSON.stringify(searchParams) !== JSON.stringify(defaultSearchParams);
  }

  updateContentWrapper(contentWrapperId: string) {
    let contentWrapperIndex = this.contentWrappers.findIndex(
      (contentWrapper) => contentWrapper.id === contentWrapperId,
    );

    if (contentWrapperIndex === -1) {
      console.warn('No contentWrapper found for id: ', contentWrapperId);
      return;
    }

    // Find the page of the content, reload only that page
    let pageNumber = Math.floor(contentWrapperIndex / this.pageSize);
    this.doSearch(pageNumber);
  }
}
