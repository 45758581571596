/* RESPONSIBLE TEAM: team-ai-agent */
import type TimeInterval from 'embercom/models/matching-system/timetable/time-interval';
import { type Block } from 'embercom/models/common/blocks/block';

interface AudienceRulesParams {
  audience_rules: {
    predicate_group: any;
    client_predicate_group: any;
    role_predicate_group: any;
    target_channels: any;
  };
}

interface BotIntroLocalizationsParams {
  bot_intro_localizations: {
    locale: string;
    enabled: boolean;
    parts: any[];
  };
}

interface HandoverParams {
  generic_triage_localizations: any;
  pre_handover_settings: {
    enabled: boolean;
  };
}

interface AutoCloseParams {
  auto_close_settings: {
    user_inactivity_mode_when_answer: string;
    user_inactivity_mode_when_no_answer: string;
    end_user_inactivity_timer: number;
    user_inactivity_auto_close_messages: Block[];
  };
}

interface SchedulingParams {
  scheduling: {
    matching_timetable: {
      timezone_type: number;
      timetable_type: number;
      time_intervals: TimeInterval[];
    } | null;
    scheduled_activation: {
      change_at: string;
      timezone_type: number;
    } | null;
    scheduled_deactivation: {
      change_at: string;
      timezone_type: number;
    } | null;
  };
}

interface AskForRatingParams {
  csat_settings: {
    enabled: boolean;
    block_update_after: number | undefined;
  };
}

interface LetFinCollectAdditionalContextParams {
  pre_handover_settings: {
    enabled: boolean;
  };
}

interface FinPercentageRolloutParams {
  match_percentage: number | null;
}

interface WhatCanFinAnswerParams {
  theme_based_rollout: {
    enabled: boolean;
    permitted_themes: Array<{ name: string; description: string }> | null;
  };
}

export type FinSetupBehaviorSaveParams =
  | AudienceRulesParams
  | BotIntroLocalizationsParams
  | WhatCanFinAnswerParams
  | HandoverParams
  | AutoCloseParams
  | SchedulingParams
  | AskForRatingParams
  | LetFinCollectAdditionalContextParams
  | FinPercentageRolloutParams;

export enum AiAnswersState {
  inactive = 'inactive',
  trialNotStarted = 'trial_not_started',
  trialStarted = 'trial_started',
  trialExpired = 'trial_expired',
  active = 'active',
  canRequestTrial = 'can_request_trial',
}
