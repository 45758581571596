/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { post } from 'embercom/lib/ajax';
import { type EntityType } from 'embercom/models/data/entity-types';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';

export interface RelevantContent {
  entity_id: string;
  entity_type: EntityType;
  title: string;
  relevant_passages?: [string];
  updated_at?: string;
  article_id?: string;
}

export interface GetRelevantContentsArgs {
  part: RenderablePart;
  conversation: Conversation;
  appId: string;
}

export default class FinAnswersApi {
  static async getRelevantContents({
    part,
    conversation,
    appId,
  }: GetRelevantContentsArgs): Promise<[RelevantContent] | undefined> {
    if (!part || !conversation) {
      return;
    }

    let relevantContents: [RelevantContent] = post(
      `/ember/content_service/fin_answers/${part.entityId}/relevant_contents?app_id=${appId}`,
      {
        conversation_id: conversation.id,
        id: part.entityId,
        app_id: appId,
      },
    );

    return relevantContents;
  }
}
