/* RESPONSIBLE TEAM: team-knowledge-foundations */

export const PENDING = 'pending';
export const IN_PROGRESS = 'in_progress';
export const FAILED = 'failed';
export const COMPLETE = 'complete';

export const ERROR_CODES: { [key: string]: string } = {
  '000': 'unknown',
  '001': 'invalid-s3-key',
  '002': 'upload-not-found',
  '003': 'file-source-not-found',
  '004': 'validation-failed',
  '005': 'no-text-found',
  '006': 'duplicate-file',
  '007': 'file-source-content-with-same-name-already-exists',
  '008': 'encrypted-pdf-file',
  '009': 'incompatible-encoding',
  '010': 'malformed',
  '011': 'content-too-large',
};
