/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { helper as buildHelper } from '@ember/component/helper';

function openArticle([articleId]: [number]): () => void {
  return () => {
    (window as any).Intercom('showArticle', articleId);
  };
}

let helper = buildHelper<{
  Args: {
    Positional: [number];
  };
  Return: () => void;
}>(openArticle);
export default helper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'open-article': typeof helper;
  }
}
