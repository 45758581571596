/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { type BlockList } from '@intercom/interblocks.ts';
import { tracked } from '@glimmer/tracking';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import { type NexusEvent } from 'embercom/services/nexus';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';

export const QUESTION_GENERATION_UPDATE_EVENT_NAME = 'FinPlaygroundQuestionGenerationUpdate';
import { type AnswerLength, type Tone } from 'embercom/components/operator/fin/setup/personality';
import { guidelineWithoutTemplateVars } from 'embercom/helpers/ai-agent-2/playground-helper';

export enum AnswerState {
  Answered = 'answered',
  Unanswered = 'unanswered',
}

export enum ExtractionType {
  All = 'all',
  LowCsat = 'low_csat',
  HandedToTeammate = 'handed_to_teammate',
}

export const ANSWER_STATE_ANSWER_TYPE_MAP = {
  [AnswerState.Answered]: ['inline_answer', 'inline_answer_with_disambiguation'],
  [AnswerState.Unanswered]: ['clarification', 'bot_reply', 'out_of_domain'],
};

export type PlaygroundWireFormat = Omit<
  KeysToSnakeCase<Playground>,
  'questions' | 'question_generation'
> & {
  questions: PlaygroundQuestionWireFormat[];
  question_generation: PlaygroundQuestionGenerationWireFormat;
  settings: PlayGroundSettingsWireFormat;
  max_question_limit?: number;
  id?: string;
};

export interface QuestionGenerationUpdateEvent extends NexusEvent {
  status: QuestionGenerationStatus;
  requires_user_acknowledgement: boolean;
  questions?: PlaygroundQuestionWireFormat[];
  is_exceeding_question_limit?: boolean;
  fin_playground_group_id: string;
  is_job_running_for_app: boolean;
}

export type PlaygroundQuestionWireFormat = Omit<KeysToSnakeCase<PlaygroundQuestion>, 'sources'> & {
  sources: KeysToSnakeCase<PlaygroundQuestionSource>[];
  fin_playground_group_id: string;
  question_detected_locale?: string;
  detected_locale?: string;
};

export type PlaygroundQuestionGenerationJobWireFormat = {
  status: QuestionGenerationStatus;
  requires_user_acknowledgement: boolean;
  fin_playground_group_id: string;
  is_job_running_for_app: boolean;
};

export type PlaygroundQuestionGenerationJobCanGenerateQuestionsWireFormat = {
  all?: number;
  low_csat?: number;
  handed_to_teammate?: number;
};

export type PlaygroundQuestionGenerationWireFormat = {
  is_possible: boolean;
  is_job_running_for_app: boolean;
  job?: PlaygroundQuestionGenerationJobWireFormat;
  can_generate_questions?: PlaygroundQuestionGenerationJobCanGenerateQuestionsWireFormat;
};

export enum Status {
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
}

export enum CustomerAnswerRating {
  Positive = 'positive',
  Negative = 'negative',
}

export enum CustomerRatingReason {
  DidNotUseRightContentSources = 'did_not_use_right_content_sources',
  DidNotClarifyCustomerQuestion = 'did_not_clarify_customer_question',
  UsedContentIncorrectly = 'used_content_incorrectly',
  ToneWasNotRight = 'tone_was_not_right',
  AnswerTooLong = 'answer_too_long',
  DidNotSpeakInRightLanguage = 'did_not_speak_in_right_language',
  Other = 'other',
}

export enum QuestionGenerationStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  EmptyResult = 'empty_result',
  Failed = 'failed',
  Running = 'running',
}

export interface AppliedGuideline {
  id: string;
  text: string;
  title: string;
}

export type AvailableExtractions = {
  all?: number;
  lowCsat?: number;
  handedToTeammate?: number;
};

export type PlaygroundGroupSummaryWireFormat = {
  id: string;
  group_name: string;
  group_description: string;
};

export class PlaygroundGroupSummary {
  id: number;
  name: string;
  description: string;

  constructor(id: number, name: string, description: string) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  static deserialize(data: PlaygroundGroupSummaryWireFormat): PlaygroundGroupSummary {
    return new PlaygroundGroupSummary(Number(data.id), data.group_name, data.group_description);
  }
}

export class PlaygroundQuestionSource {
  constructor(
    public entityId: number,
    public entityType: number,
    public entityData: {
      title: string;
    },
    public deleted: boolean,
  ) {}

  get title() {
    return this.entityData.title;
  }

  get humanizedEntityTypeName() {
    return humanReadableObjectNames[this.entityType];
  }
}

export class PlaygroundQuestionGeneration {
  @tracked isPossible: boolean;
  @tracked latestJobStatus: QuestionGenerationStatus | undefined;
  @tracked latestJobRequiresUserAcknowledgement: boolean | undefined;
  @tracked availableExtractions: AvailableExtractions;
  @tracked isJobRunningForApp: boolean;

  constructor(
    isPossible: boolean,
    latestJobStatus: QuestionGenerationStatus | undefined,
    latestJobRequiresUserAcknowledgement: boolean | undefined,
    availableExtractions: AvailableExtractions,
    isJobRunningForApp: boolean,
  ) {
    this.isPossible = isPossible;
    this.latestJobStatus = latestJobStatus;
    this.latestJobRequiresUserAcknowledgement = latestJobRequiresUserAcknowledgement;
    this.availableExtractions = availableExtractions;
    this.isJobRunningForApp = isJobRunningForApp;
  }

  static deserialize(data: PlaygroundQuestionGenerationWireFormat): PlaygroundQuestionGeneration {
    return new PlaygroundQuestionGeneration(
      data.is_possible,
      data.job?.status,
      data.job?.requires_user_acknowledgement,
      {
        all: data.can_generate_questions?.all,
        lowCsat: data.can_generate_questions?.low_csat,
        handedToTeammate: data.can_generate_questions?.handed_to_teammate,
      },
      data.is_job_running_for_app,
    );
  }

  updateLatestJobData(data: PlaygroundQuestionGenerationJobWireFormat) {
    this.latestJobStatus = data.status;
    this.latestJobRequiresUserAcknowledgement = data.requires_user_acknowledgement;
    this.isJobRunningForApp = data.is_job_running_for_app;
  }

  resetLatestJobData() {
    this.latestJobStatus = undefined;
    this.latestJobRequiresUserAcknowledgement = undefined;
  }

  get isRunning(): boolean {
    return this.latestJobStatus === QuestionGenerationStatus.Running;
  }
}

export class PlaygroundQuestion {
  @tracked questionText: string;
  @tracked status: Status;
  customerAnswerRatingNote: string;

  id: number | undefined;
  responseText: BlockList = [];
  sources: PlaygroundQuestionSource[] = [];
  customerAnswerRating?: CustomerAnswerRating;
  customerAnswerRatingReason?: CustomerRatingReason;
  answerType?:
    | 'inline_answer'
    | 'clarification'
    | 'bot_reply'
    | 'inline_answer_with_disambiguation';
  answerLastGeneratedAt: Date | undefined;
  aiToneOfVoice?: Tone;
  aiAnswerLength?: AnswerLength;
  appliedGuidelines: AppliedGuideline[];
  fallbackSearchLocale: string | undefined;
  realtimeTranslationApplied: boolean;
  groupId?: number;
  questionDetectedLocale?: string;
  detectedLocale?: string;

  constructor(
    id: number | undefined,
    questionText: string,
    aiToneOfVoice?: Tone,
    aiAnswerLength?: AnswerLength,
    appliedGuidelines?: AppliedGuideline[],
    responseText?: BlockList,
    sources?: PlaygroundQuestionSource[],
    status?: Status,
    answerType?: PlaygroundQuestion['answerType'],
    customerAnswerRating?: CustomerAnswerRating,
    answerLastGeneratedAt?: Date,
    customerAnswerRatingReason?: CustomerRatingReason,
    customerAnswerRatingNote?: string,
    fallbackSearchLocale?: string,
    realtimeTranslationApplied?: boolean,
    groupId?: number,
    questionDetectedLocale?: string,
    detectedLocale?: string,
  ) {
    this.id = id;
    this.questionText = questionText;
    this.status = status ?? Status.Pending;
    this.responseText = responseText ?? [];
    this.sources = sources ?? [];
    this.answerType = answerType;
    this.customerAnswerRating = customerAnswerRating;
    this.answerLastGeneratedAt = answerLastGeneratedAt;
    this.customerAnswerRatingReason = customerAnswerRatingReason;
    this.aiToneOfVoice = aiToneOfVoice;
    this.aiAnswerLength = aiAnswerLength;
    this.appliedGuidelines = appliedGuidelines ?? [];
    this.customerAnswerRatingNote = customerAnswerRatingNote ?? '';
    this.fallbackSearchLocale = fallbackSearchLocale;
    this.realtimeTranslationApplied = realtimeTranslationApplied ?? false;
    this.groupId = groupId;
    this.questionDetectedLocale = questionDetectedLocale;
    this.detectedLocale = detectedLocale;
  }

  static deserialize(data: PlaygroundQuestionWireFormat): PlaygroundQuestion {
    let answerGeneratedAt: Date | undefined;

    if (data.answer_last_generated_at) {
      answerGeneratedAt = new Date(data.answer_last_generated_at);
    }

    return new PlaygroundQuestion(
      Number(data.id),
      data.question_text,
      data.ai_tone_of_voice,
      data.ai_answer_length,
      data.applied_guidelines,
      data.response_text,
      data.sources?.map(
        (s) => new PlaygroundQuestionSource(s.entity_id, s.entity_type, s.entity_data, s.deleted),
      ),
      data.status,
      data.answer_type,
      data.customer_answer_rating,
      answerGeneratedAt,
      data.customer_answer_rating_reason,
      data.customer_answer_rating_note,
      data.fallback_search_locale,
      data.realtime_translation_applied,
      Number(data.fin_playground_group_id),
      data.question_detected_locale,
      data.detected_locale,
    );
  }

  get hasSources() {
    return this.sources?.length > 0;
  }

  get hasNoAnswer() {
    return (
      !this.answerType ||
      ANSWER_STATE_ANSWER_TYPE_MAP[AnswerState.Unanswered].includes(this.answerType)
    );
  }

  get answerHasError() {
    return this.status === Status.Failed;
  }

  run() {
    this.status = Status.Running;
  }

  guidelinesWithoutTemplateVars(): AppliedGuideline[] {
    return this.appliedGuidelines.map((guideline) => {
      // wrap the whole thing in a try/catch just in case there's an edge
      // case I hadn't considered.
      try {
        return {
          ...guideline,
          text: guidelineWithoutTemplateVars(guideline.text),
          title: guideline.title,
        };
      } catch (error) {
        return guideline;
      }
    });
  }
}

export type PlayGroundSettingsWireFormat = KeysToSnakeCase<PlaygroundSettings> & {
  fin_playground_group_id: string;
};

export class PlaygroundSettings {
  @tracked selectedContentSegmentIds = new TrackedArray<string>([]);
  private initialSelectedContentSegmentIds: Set<string>;
  @tracked brandId?: string | null;
  private initialBrandId?: string | null;
  groupId?: number;
  @tracked userId?: string | null;
  private initialUserId?: string | null;
  constructor(
    selectedContentSegmentIds: string[],
    brandId?: string | null,
    groupId?: number,
    userId?: string | null,
  ) {
    this.selectedContentSegmentIds = new TrackedArray(selectedContentSegmentIds);
    this.initialSelectedContentSegmentIds = new Set(selectedContentSegmentIds);
    this.brandId = brandId;
    this.initialBrandId = brandId;
    this.groupId = groupId;
    this.userId = userId;
  }

  static deserialize(data: PlayGroundSettingsWireFormat): PlaygroundSettings {
    let selectedContentSegmentIds = data?.selected_content_segment_ids.map((id) => id.toString());
    let brandId = data?.brand_id?.toString();
    let groupID = Number(data?.fin_playground_group_id);
    let userId = data?.user_id?.toString();
    return new PlaygroundSettings(selectedContentSegmentIds ?? [], brandId, groupID, userId);
  }

  addOrRemoveClickedSegment(segmentId: string) {
    if (this.selectedContentSegmentIds.includes(segmentId)) {
      // Remove the segment if it is already in the list
      this.selectedContentSegmentIds = new TrackedArray(
        this.selectedContentSegmentIds.filter((id) => id !== segmentId),
      );
    } else {
      // Add the segment if it is not in the list
      this.selectedContentSegmentIds = new TrackedArray([
        ...this.selectedContentSegmentIds,
        segmentId,
      ]);
    }
  }

  hasChanged(): boolean {
    let currentSet = new Set(this.selectedContentSegmentIds);
    if (currentSet.size !== this.initialSelectedContentSegmentIds.size) {
      return true;
    }
    let currentBrandId = this.brandId;
    if (currentBrandId !== this.initialBrandId) {
      return true;
    }
    let currentUserId = this.userId;
    if (currentUserId !== this.initialUserId) {
      return true;
    }
    return this.selectedContentSegmentIds.some(
      (id) => !this.initialSelectedContentSegmentIds.has(id),
    );
  }

  rollbackChanges() {
    this.selectedContentSegmentIds = new TrackedArray([...this.initialSelectedContentSegmentIds]);
    this.brandId = this.initialBrandId;
    this.userId = this.initialUserId;
  }

  refreshTrackedState() {
    this.initialSelectedContentSegmentIds = new Set(this.selectedContentSegmentIds);
    this.initialBrandId = this.brandId;
    this.initialUserId = this.userId;
  }

  removeAllSegments() {
    this.selectedContentSegmentIds = new TrackedArray([]);
  }
}

export class Playground {
  @tracked questions = new TrackedArray<PlaygroundQuestion>([]);
  @tracked questionGeneration: PlaygroundQuestionGeneration;
  @tracked questionFilters = new TrackedObject<{
    ratingFilter: CustomerAnswerRating | null;
    answerTypeFilter: AnswerState | null;
  }>({
    ratingFilter: null,
    answerTypeFilter: null,
  });
  @tracked settings: PlaygroundSettings;
  groupName?: string;
  id?: number;

  questionLimit: number;

  constructor(
    questions: PlaygroundQuestion[],
    questionGeneration: PlaygroundQuestionGeneration,
    settings: PlaygroundSettings,
    questionLimit?: number,
    groupName?: string,
    id?: number,
  ) {
    this.questions = new TrackedArray(questions);
    this.questionLimit = questionLimit ?? 50;
    this.questionGeneration = questionGeneration;
    this.settings = settings;
    this.groupName = groupName;
    this.id = id;
  }

  get filteredQuestions() {
    if (!this.questionFilters.ratingFilter && !this.questionFilters.answerTypeFilter) {
      return this.questions;
    }

    let filteredQuestions = this.questions.filter((question) => {
      if (this.questionFilters.ratingFilter && this.questionFilters.answerTypeFilter) {
        // If both filters are set, return question if it matches both conditions
        return (
          question.customerAnswerRating === this.questionFilters.ratingFilter &&
          question.answerType &&
          ANSWER_STATE_ANSWER_TYPE_MAP[this.questionFilters.answerTypeFilter].includes(
            question.answerType,
          )
        );
      }

      if (this.questionFilters.ratingFilter) {
        // If rating filter is set, return question if ratings match
        return question.customerAnswerRating === this.questionFilters.ratingFilter;
      }

      if (this.questionFilters.answerTypeFilter) {
        // If answerState filter is set, return question if answerStates match
        return (
          question.answerType &&
          ANSWER_STATE_ANSWER_TYPE_MAP[this.questionFilters.answerTypeFilter].includes(
            question.answerType,
          )
        );
      }

      return true;
      // This return is just to appease typescript, it should never be reached
    });

    return filteredQuestions;
  }

  get isPending() {
    return this.questions.every((q) => q.status === Status.Pending);
  }

  get isRunning() {
    return this.questions.some((q) => q.status === Status.Running);
  }

  get hasQuestions() {
    return this.questions.length > 0;
  }

  get isActive(): boolean {
    return this.hasQuestions || this.questionGeneration.isRunning;
  }

  get pendingQuestions() {
    return this.questions.filter((q) => q.status === Status.Pending);
  }

  get hasReachedQuestionLimit(): boolean {
    return this.questions.length >= this.questionLimit;
  }

  get remainingQuestionQuota(): number {
    if (this.hasReachedQuestionLimit) {
      return 0;
    }

    return this.questionLimit - this.questions.length;
  }

  addQuestions(questions: PlaygroundQuestion[]) {
    this.questions.push(...questions);
  }

  removeQuestion(questionText: string) {
    this.questions = new TrackedArray(
      this.questions.filter((q) => q.questionText !== questionText),
    );
  }

  removeQuestionById(id: number) {
    this.questions = new TrackedArray(this.questions.filter((q) => q.id !== id));
  }

  replaceQuestionByText(newQuestion: PlaygroundQuestion) {
    let index = this.questions.findIndex((q) => q.questionText === newQuestion.questionText);
    if (index === -1) {
      return;
    }
    this.questions[index] = newQuestion;
  }

  replaceRunningQuestionsWithCompleted(questions: PlaygroundQuestion[]) {
    questions.forEach((question) => {
      let index = this.questions.findIndex((q) => q.questionText === question.questionText);
      let currentQuestion;
      if (index !== -1) {
        currentQuestion = this.questions[index];
        if (currentQuestion.status === Status.Running && question.status === Status.Completed) {
          this.questions[index] = question;
        }
      }
    });
  }

  setRunningStatus(questions?: PlaygroundQuestion[]) {
    questions?.forEach((q) => q.run());
  }

  hasQuestion(questionText: string) {
    return this.questions.some((q) => q.questionText === questionText);
  }

  setQuestionState(id: number, status: Status) {
    let question = this.questions.find((q) => q.id === id);

    if (question) {
      question.status = status;
    }
  }

  updateQuestionRatingFilter(rating: CustomerAnswerRating | null) {
    this.questionFilters.ratingFilter = rating;
  }

  updateAnswerTypeFilter(filter: AnswerState | null) {
    this.questionFilters.answerTypeFilter = filter;
  }

  static deserialize(data: PlaygroundWireFormat): Playground {
    return new Playground(
      data.questions.map((q) => PlaygroundQuestion.deserialize(q)),
      PlaygroundQuestionGeneration.deserialize(data.question_generation),
      PlaygroundSettings.deserialize(data.settings),
      data.max_question_limit,
      data.group_name,
      Number(data.id),
    );
  }

  countOfQuestionsByAnswerState(answerType: AnswerState): number {
    return this.questions.filter(
      (q) => q.answerType && ANSWER_STATE_ANSWER_TYPE_MAP[answerType].includes(q.answerType),
    ).length;
  }

  percentageOfQuestionsByAnswerState(answerType: AnswerState): number {
    return Math.round(
      (this.countOfQuestionsByAnswerState(answerType) / this.questions.length) * 100,
    );
  }

  countOfQuestionsByAnswerRating(rating: CustomerAnswerRating): number {
    return this.questions.filter((q) => q.customerAnswerRating === rating).length;
  }

  percentageOfQuestionsByAnswerRating(rating: CustomerAnswerRating): number {
    return Math.round((this.countOfQuestionsByAnswerRating(rating) / this.questions.length) * 100);
  }
}
