/* RESPONSIBLE TEAM: team-purchase */
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

interface CardBrandInfo {
  brandString: string;
  iconUrl?: string;
}

const CARD_BRANDS: { [key: string]: CardBrandInfo } = {
  visa: {
    brandString: 'Visa',
    iconUrl: '/svgs/credit-card/2024/visa.svg',
  },
  mastercard: {
    brandString: 'Mastercard',
    iconUrl: '/svgs/credit-card/2024/mastercard.svg',
  },
  amex: {
    brandString: 'American Express',
    iconUrl: '/svgs/credit-card/2024/american-express.svg',
  },
  discover: {
    brandString: 'Discover',
    iconUrl: '/svgs/credit-card/2024/discover.svg',
  },
  jcb: {
    brandString: 'JCB',
    iconUrl: '/svgs/credit-card/2024/jcb.svg',
  },
  diners: {
    brandString: 'Diners Club',
    iconUrl: '/svgs/credit-card/2024/diners-club.svg',
  },
  unionpay: {
    brandString: 'UnionPay',
    iconUrl: '/svgs/credit-card/2024/unionpay.svg',
  },
};

export const DEFAULT_CARD_ICON = assetUrl('/svgs/credit-card/default.svg');

export const CARD_BRAND_NAMES = Object.fromEntries(
  Object.entries(CARD_BRANDS).map(([key, value]) => [key, value.brandString]),
);

export const CARD_BRAND_ICONS = Object.fromEntries(
  Object.entries(CARD_BRANDS).map(([key, value]) => [
    key,
    value.iconUrl ? assetUrl(value.iconUrl) : DEFAULT_CARD_ICON,
  ]),
);
