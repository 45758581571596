/* RESPONSIBLE TEAM: team-knowledge-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import moment from 'moment-timezone';
import containerLookup from 'embercom/lib/container-lookup';

let conversions = {
  s: 1000,
  m: 60,
  h: 60,
  d: 24,
  w: 7,
};

let articleTimeAgo = function (dateString) {
  let intl = containerLookup('service:intl');

  let now = moment();
  let date = moment(dateString);
  let delta = Math.max(0, now - date);
  let unit = 's';
  for (let key in conversions) {
    if (conversions.hasOwnProperty(key)) {
      if (delta < conversions[key]) {
        break;
      }
      unit = key;
      delta /= conversions[key];
    }
  }
  delta = Math.floor(delta);
  switch (unit) {
    case 's':
      return intl.t('articles.shared.time_ago.updated_15_mins');
    case 'm':
      return delta <= 15
        ? intl.t('articles.shared.time_ago.updated_15_mins')
        : intl.t('articles.shared.time_ago.updated_last_hour');
    case 'h':
      return delta === 1
        ? intl.t('articles.shared.time_ago.updated_last_hour')
        : intl.t('articles.shared.time_ago.updated_today');
    case 'd':
      return delta === 1
        ? intl.t('articles.shared.time_ago.updated_today')
        : intl.t('articles.shared.time_ago.updated_this_week');
    default:
      return intl.t('articles.shared.time_ago.updated_over_a_week_ago');
  }
};

export default articleTimeAgo;
