/* RESPONSIBLE TEAM: team-purchase */
import flatMapBy from 'embercom/lib/flat-map-by';

const IntercomradesProductsOverride = {
  isEnabled(appId) {
    if (IntercomradesProductsOverride._onIntercomradesSubdomain()) {
      return false;
    }
    let enabledKey = `icp-${appId}-enabled`;
    return localStorage.getItem(enabledKey) === 'true';
  },

  _onIntercomradesSubdomain() {
    return document.domain.indexOf('intercomrades') === -1;
  },

  updateActiveProductsAndPlans(app, products) {
    let appId = app.get('id');
    if (!IntercomradesProductsOverride.isEnabled(appId)) {
      return products;
    }

    let planIdsKey = `icp-${appId}-plan-ids`;
    let planIds = JSON.parse(localStorage.getItem(planIdsKey));
    if (!planIds) {
      return;
    }

    let plans = flatMapBy(products, 'plans');

    let activePlans = plans.filter((plan) => planIds.includes(plan.get('id')));
    let inactivePlans = plans.reject((plan) => planIds.includes(plan.get('id')));

    activePlans.forEach((plan) => {
      plan.set('active', true);
      plan.set('activeTrial', false);
      plan.set('billableCustomerPlan', true);
    });
    inactivePlans.forEach((plan) => {
      plan.set('active', false);
      plan.set('activeTrial', false);
      plan.set('billableCustomerPlan', false);
    });
  },

  features(app) {
    let appId = app.get('id');
    if (!IntercomradesProductsOverride.isEnabled(appId)) {
      return [];
    }

    let featureKeysAndFeatureFlagKeys = app.get('features').mapBy('id');

    let products = app.get('products');
    let plans = flatMapBy(products, 'plans');
    let features = flatMapBy(plans, 'features');
    let allPossibleFeatureKeys = features.mapBy('key');

    let featureFlagKeys = featureKeysAndFeatureFlagKeys.reject((featureKeyOrFeatureFlagKey) => {
      return allPossibleFeatureKeys.includes(featureKeyOrFeatureFlagKey);
    });

    let featureKeys = products.reduce((featureKeys, product) => {
      let activePlan = product.get('plans').findBy('active', true);

      if (activePlan) {
        product
          .get('plans')
          .filter((plan) => plan.tierId <= activePlan.tierId)
          .forEach((plan) => {
            featureKeys.push(...plan.get('features').mapBy('key'));
          });
      }

      return featureKeys;
    }, []);

    return [...featureFlagKeys, ...featureKeys];
  },
};

export default IntercomradesProductsOverride;
