/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';

export interface GetArrayElementSignature {
  Args: {
    Positional: [array: any[], index: number];
  };
  Return: any;
}

export default class GetArrayElement extends Helper<GetArrayElementSignature> {
  compute([array, index]: [array: any[], index: number]): any {
    return array.length >= index ? array[index] : undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'get-array-element': typeof GetArrayElement;
  }
}
