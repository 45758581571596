/* RESPONSIBLE TEAM: team-reporting */
import { formatRangeRoundedTime } from 'embercom/lib/duration-formatter';

export default class RangeFormatter {
  formatData(range: string) {
    // e.g *-1000, 1000-2000, 2000-*
    let [start, end] = range.split('-');
    //The order here is important and intentional
    if (end === '*') {
      return `> ${formatRangeRoundedTime(start)}`;
    } else if (start === '*' || start === '0.0') {
      return `< ${formatRangeRoundedTime(end)}`;
    }
    return `${formatRangeRoundedTime(start)} - ${formatRangeRoundedTime(end)}`;
  }
}
