/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Helper from '@ember/component/helper';
import { getArticleFromDistribution } from 'embercom/lib/articles/article-distribution';

// Helper method gets back the default article from the distribution system and gives its articleNumber.
export { getArticleFromDistribution };

export default class GetArticleNumberFromDistribution extends Helper<{
  Args: {
    Positional: [string, $TSFixMe?, $TSFixMe?];
  };
  Return: number;
}> {
  compute([...params]: [string, $TSFixMe?, $TSFixMe?]) {
    return getArticleFromDistribution(...params)?.articleNumber ?? 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'get-article-number-from-distribution': typeof GetArticleNumberFromDistribution;
  }
}
