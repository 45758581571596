/* RESPONSIBLE TEAM: team-messenger */
import { getOwner } from '@ember/application';

export async function loadMessengerSettings(context) {
  let owner = getOwner(context);
  let appService = owner.lookup('service:app-service');
  let store = owner.lookup('service:store');

  let settings = await store.findRecord('messenger-settings/all', appService.app.id);

  return settings;
}
