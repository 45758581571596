/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import safeWindowOpen from 'embercom/lib/safe-window-open';
import containerLookup from 'embercom/lib/container-lookup';

export const ALLOWED_IMPERSONATION_APP_IDS = [
  'tx2p130c', // US
  'olpw3290', // EU
  'pnroknvu', // AU
  'fin4all', // Fin Standalone Support App
];

export const impersonateInRegion = (
  region,
  impersonatedAdminId,
  impersonatedAdminEmail,
  workspace,
) => {
  let searchParams = new URLSearchParams({
    impersonatedAdminId,
    impersonatedAdminEmail,
  });
  let regionService = containerLookup('service:regionService');

  let currentAppId = workspace?.id;

  if (!currentAppId || !ALLOWED_IMPERSONATION_APP_IDS.includes(currentAppId)) {
    let notificationsService = containerLookup('service:notificationsService');
    notificationsService.notifyErrorWithButton(
      // These are Intercomrade facing errors
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      'Impersonation is only available for specific Intercom apps',
      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      { label: 'Ok', action: () => {} },
      0,
    );
    return;
  }

  safeWindowOpen(
    `${regionService.impersonationUrl(region, currentAppId)}?${searchParams.toString()}`,
    '_blank',
  );
};
