/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { EntityType } from 'embercom/models/data/entity-types';
import { TriggerSources } from 'embercom/objects/content-service/content-imports/constants/trigger-sources';

export default class EnqueueApifyImportApi {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare contentImportService: any;

  constructor(owner: unknown) {
    setOwner(this, owner);
  }

  async enqueueApifyImport(
    url: string,
    fromOnboarding: boolean,
  ): Promise<{
    submitted: boolean;
    submissionError: boolean;
    submissionErrorTranslationKey: string;
  }> {
    let params = {
      app_id: this.appService.app.id,
      url,
      trigger_source: fromOnboarding
        ? TriggerSources.OnboardingHome
        : TriggerSources.AdminInitiated,
      content_type: EntityType.ExternalContent,
      admin_id: this.appService.app.currentAdmin.id,
    };

    let submissionError = false;
    let submitted = false;
    let submissionErrorTranslationKey = 'modal.import-external-content.url-form.errors.default';

    try {
      await post(`/ember/content_service/content_import_runs`, params);
      this.contentImportService.subscribeToContentImportRunStatusUpdates();
      await this.contentImportService.fetchContentImportSources();

      submitted = true;
    } catch (e) {
      submissionErrorTranslationKey = this.contentImportService.submissionErrorTranslationKey(e);
      submissionError = true;
    }

    return { submitted, submissionError, submissionErrorTranslationKey };
  }
}
