/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { EntityType } from 'embercom/models/data/entity-types';
import { State, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { type TableColumn } from 'embercom/components/content-service/ai-content-library';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';

import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import type ArticleContent from 'embercom/models/articles/article-content';
import { type KnowledgeHubContent } from 'embercom/objects/knowledge-hub/knowledge-hub-content';

export type SortDirection = 'asc' | 'desc';

export const ALL_CONTENT_COLUMN_DATA: Record<string, TableColumn> = {
  draggableIcon: {
    valuePath: 'draggableIcon',
    isVisible: true,
  },
  checkBox: {
    valuePath: 'checkBox',
    type: 'checkbox',
  },
  title: {
    valuePath: 'title',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.title',
    isSortable: true,
  },
  contentType: {
    valuePath: 'contentType',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.content-type',
  },
  chatbotState: {
    valuePath: 'chatbotState',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.chatbot-state',
  },
  copilotState: {
    valuePath: 'copilotState',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.copilot-state',
  },
  // Help Center Status
  status: {
    valuePath: 'status',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.status',
    customHeaderComponent:
      'knowledge-hub/filterable-list/table/custom-column-headers/help-center-status',
  },
  collectionNames: {
    valuePath: 'collectionNames',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.collection-names.title',
  },
  articlePublishedState: {
    valuePath: 'articlePublishedState',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.article-state.title',
  },
  writtenBy: {
    valuePath: 'writtenBy',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.written-by',
  },
  segment: {
    valuePath: 'segment',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.segment',
    isVisible: true,
  },
  locale: {
    valuePath: 'locale',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.locale',
    isVisible: false,
  },
  updatedAt: {
    valuePath: 'updatedAt',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.updated-at',
    isSortable: true,
  },
  updatedBy: {
    valuePath: 'updatedBy',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.updated-by',
  },
  createdBy: {
    valuePath: 'createdBy',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.created-by',
  },
  tags: {
    valuePath: 'tags',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.tags',
  },
  folder: {
    valuePath: 'folder',
    labelIntlKey: 'knowledge-hub.filterable-list.list.columns.folder',
    isVisible: true,
  },
};

export const KNOWLEDGE_HUB_REQUIRED_COLUMNS = [
  ALL_CONTENT_COLUMN_DATA.draggableIcon,
  ALL_CONTENT_COLUMN_DATA.checkBox,
  ALL_CONTENT_COLUMN_DATA.title,
];

export const KNOWLEDGE_HUB_ALL_CONTENT_DEFAULT_COLUMNS = [
  ...KNOWLEDGE_HUB_REQUIRED_COLUMNS,
  ALL_CONTENT_COLUMN_DATA.contentType,
  ALL_CONTENT_COLUMN_DATA.chatbotState,
  ALL_CONTENT_COLUMN_DATA.copilotState,
  ALL_CONTENT_COLUMN_DATA.status,
  ALL_CONTENT_COLUMN_DATA.collectionNames,
  ALL_CONTENT_COLUMN_DATA.articlePublishedState,
  ALL_CONTENT_COLUMN_DATA.updatedAt,
];

// Mainly for testing and keeping track of column order
export const KNOWLEDGE_HUB_EVERY_CONTENT_COLUMN = [
  ALL_CONTENT_COLUMN_DATA.draggableIcon,
  ALL_CONTENT_COLUMN_DATA.title,
  ALL_CONTENT_COLUMN_DATA.contentType,
  ALL_CONTENT_COLUMN_DATA.chatbotState,
  ALL_CONTENT_COLUMN_DATA.copilotState,
  ALL_CONTENT_COLUMN_DATA.status,
  ALL_CONTENT_COLUMN_DATA.collectionNames,
  ALL_CONTENT_COLUMN_DATA.articlePublishedState,
  ALL_CONTENT_COLUMN_DATA.writtenBy,
  ALL_CONTENT_COLUMN_DATA.segment,
  ALL_CONTENT_COLUMN_DATA.locale,
  ALL_CONTENT_COLUMN_DATA.updatedAt,
  ALL_CONTENT_COLUMN_DATA.updatedBy,
  ALL_CONTENT_COLUMN_DATA.createdBy,
  ALL_CONTENT_COLUMN_DATA.tags,
  ALL_CONTENT_COLUMN_DATA.folder,
];

export const KNOWLEDGE_HUB_EVERY_CONTENT_COLUMN_ORDER = KNOWLEDGE_HUB_EVERY_CONTENT_COLUMN.map(
  (col) => col.valuePath,
);

export const FOLDER_MULTILINGUAL_DEFAULT_COLUMNS = [
  ...KNOWLEDGE_HUB_REQUIRED_COLUMNS,
  ALL_CONTENT_COLUMN_DATA.contentType,
  ALL_CONTENT_COLUMN_DATA.chatbotState,
  ALL_CONTENT_COLUMN_DATA.copilotState,
  ALL_CONTENT_COLUMN_DATA.locale,
  ALL_CONTENT_COLUMN_DATA.status,
];

export const KNOWLEDGE_HUB_ENTITIES: ReadonlyArray<EntityType> = [
  EntityType.ArticleContent,
  EntityType.InternalArticle,
  EntityType.ExternalContent,
  EntityType.ContentSnippet,
  EntityType.FileSourceContent,
  EntityType.ContentLibraryFolder,
] as const;

export const KNOWLEDGE_HUB_ENTITIES_NAMES = {
  [EntityType.ArticleContent]: 'article',
  [EntityType.InternalArticle]: 'internal-article',
  [EntityType.ExternalContent]: 'external_content',
  [EntityType.FileSourceContent]: 'file',
  [EntityType.ContentSnippet]: objectNames[EntityType.ContentSnippet],
  [EntityType.Answer]: objectNames[EntityType.Answer],
} as const;

export const KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES: ReadonlyArray<string> = Object.values(
  KNOWLEDGE_HUB_ENTITIES_NAMES,
);

export const KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES: { [key: string]: string } = {
  [EntityType.ArticleContent]: 'articles/article-content',
  [EntityType.InternalArticle]: 'content-service/internal-article',
  [EntityType.ExternalContent]: 'external-content',
  [EntityType.FileSourceContent]: 'file-source-content',
  [EntityType.ContentSnippet]: 'content-service/content-snippet',
  [EntityType.ContentLibraryFolder]: 'content-service/folder',
  [EntityType.Answer]: 'custom-answers/custom-answer',
  [EntityType.SavedReply]: 'saved-reply',
} as const;

export const KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES: { [key: string]: string } = {
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ArticleContent]]:
    KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[EntityType.ArticleContent],
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.InternalArticle]]:
    KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[EntityType.InternalArticle],
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ExternalContent]]:
    KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[EntityType.ExternalContent],
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.FileSourceContent]]:
    KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[EntityType.FileSourceContent],
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ContentSnippet]]:
    KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[EntityType.ContentSnippet],
} as const;

export const KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES_FOR_CREATION: { [key: string]: string } = {
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ArticleContent]]: 'article-multilingual',
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.InternalArticle]]: 'content-service/internal-article',
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.FileSourceContent]]: 'file-source-content',
  [KNOWLEDGE_HUB_ENTITIES_NAMES[EntityType.ContentSnippet]]: 'content-service/content-snippet',
} as const;

export const KNOWLEDGE_HUB_DEFAULT_THEME_COLOR = '222222';
export const DRAWER_EDITOR_SHOW_SIDE_PANEL = 'knowledge_hub_drawer_editor_show_side_panel';

export interface Predicate {
  attribute: string;
  type: string;
  comparison: string;
  value: string;
}

export enum KnowledgeHubContentStatus {
  LIVE = State.Live,
  DRAFT = State.Draft,
}

export enum ArticlePublishedStatuses {
  DRAFT = 0,
  PUBLISHED = 1,
  PUBLISHED_WITH_DRAFT = 2,
  MIXED = 3,
}

export const IMPORT_SOURCE_STATUSES = {
  gather: 'gather',
  transform: 'transform',
  load: 'load_items',
  failed: 'failed',
  success: 'success',
  import: 'import_items',
};

export const IMPORT_SOURCE_IN_PROGRESS_STATUSES = [
  IMPORT_SOURCE_STATUSES.gather,
  IMPORT_SOURCE_STATUSES.transform,
  IMPORT_SOURCE_STATUSES.load,
  IMPORT_SOURCE_STATUSES.import,
];

export interface ColumnData {
  value: string;
  labelIntlKey?: string;
  text?: string;
  isSelected?: boolean;
}

type KnowledgeHubColumnKey = keyof typeof ALL_CONTENT_COLUMN_DATA;

function getKnowledgeHubColumnKeys() {
  let keys: Record<KnowledgeHubColumnKey, string> = {};
  let columnKeys = Object.keys(ALL_CONTENT_COLUMN_DATA);
  columnKeys.forEach((key) => {
    keys[key] = ALL_CONTENT_COLUMN_DATA[key]['valuePath'];
  });
  return keys;
}

export const KNOWLEDGE_HUB_COLUMN_KEYS = getKnowledgeHubColumnKeys();

export const DEFAULT_SELECTABLE_COLUMNS: ColumnData[] = Object.keys(ALL_CONTENT_COLUMN_DATA)
  .filter((key) => {
    if (
      [
        KNOWLEDGE_HUB_COLUMN_KEYS.draggableIcon,
        KNOWLEDGE_HUB_COLUMN_KEYS.title,
        KNOWLEDGE_HUB_COLUMN_KEYS.checkBox,
      ].includes(key)
    ) {
      return false;
    }

    return true;
  })
  .map((key) => {
    return {
      value: ALL_CONTENT_COLUMN_DATA[key]['valuePath'],
      labelIntlKey: ALL_CONTENT_COLUMN_DATA[key]['labelIntlKey'],
    };
  });
export interface TemplateCardType {
  id: number;
  title: string;
  summary: string;
  icon: string;
  color: string;
}

export const CAN_MANAGE_KNOWLEDGE_BASE_CONTENT = 'can_manage_knowledge_base_content';

export const MAX_LOCALES_SHOWN_IN_TABLE_CELL = 4;
export const MAX_ADMINS_SHOWN_IN_TABLE_CELL = 4;

export enum FinAIType {
  AGENT = 'ai-agent',
  COPILOT = 'ai-copilot',
}

export type TagOperator = 'IN' | 'NIN';

export const AI_AGENT_ENTITY_TYPES = [
  EntityType.ArticleContent,
  EntityType.ExternalContent,
  EntityType.FileSourceContent,
  EntityType.ContentSnippet,
  EntityType.Answer,
];

export function isAIAgentEntityType(entityType: EntityType) {
  return AI_AGENT_ENTITY_TYPES.includes(entityType);
}

export const AI_COPILOT_ENTITY_TYPES = [
  EntityType.ConversationExcerpt,
  EntityType.InternalArticle,
  EntityType.SavedReply,
  ...AI_AGENT_ENTITY_TYPES,
];
export function isAICopilotEntityType(entityType: EntityType) {
  return AI_COPILOT_ENTITY_TYPES.includes(entityType);
}

export const UPDATE_TAGGING_URL = '/ember/content_service/contents/update_tags';

export function isValidCssSelector(cssSelector: string) {
  try {
    document.createDocumentFragment().querySelector(cssSelector);
  } catch (e) {
    return false;
  }
  return true;
}

export const STATS_BODY_COMPONENTS: Partial<{ [key in StatisticKeyName]: string }> = {
  articleContentView: 'stats-system/list/article-views-list',
  replies: 'stats-system/list/reply-list',
  reactions: 'stats-system/list/reaction-list',
};

// TODO: This is a temporary solution due to article content aren't compatible with Model
// More info: https://github.com/intercom/embercom/pull/88509#discussion_r1773249713
export type ActiveContent = (LocalizedKnowledgeContent & Model) | ArticleContent;

export function parseContentIdentifier(content: ActiveContent): [EntityType, string] {
  if (content.entityType === EntityType.ArticleContent) {
    let articleContent = content as ArticleContent;
    let article = articleContent.article;
    return [article.entityType, article.id];
  } else {
    let parentContent = content.parentContent as KnowledgeHubContent & Model;
    return [parentContent.entityType, parentContent.id];
  }
}

export function getSupportContentId(content: ActiveContent): string {
  let [entityType, entityId] = parseContentIdentifier(content);
  return `${entityType}_${entityId}`;
}
