/* RESPONSIBLE TEAM: team-purchase */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  PROACTIVE_MESSAGING_ID,
  ADVANCED_WORKSPACE_MANAGEMENT_ID,
  ANSWER_BOT_ID,
  SALES_AUTOMATION_ID,
  OPERATOR_ID,
  PROACTIVE_SUPPORT_ID,
  PRODUCT_TOURS_ID,
  SMS_ID,
  SUPPORT_AUTOMATION_ID,
  SURVEYS_ID,
  SWITCH_ID,
  WHATSAPP_ID,
  ANSWER_BOT_ESSENTIAL_ID,
  SUPPORT_AUTOMATION_BASE_ID,
  PREMIER_ONBOARDING_ID,
  PREMIER_SUCCESS_ID,
  PREMIER_SUPPORT_ID,
  CUSTOM_API_RATE_LIMIT_ID,
  FIN_AI_COPILOT_ID,
} from 'embercom/lib/billing';

import { type IntershopAddOnData } from 'embercom/models/data/intershop/add-on-types';
import { ADDON_CARD_DATA, ADD_ON_DATA } from 'embercom/lib/intershop/intershop-addons-data';

export const INCLUDED_ADD_ON_PRODUCTS = [
  OPERATOR_ID,
  ANSWER_BOT_ID,
  FIN_AI_COPILOT_ID,
  PRODUCT_TOURS_ID,
  SALES_AUTOMATION_ID,
  SUPPORT_AUTOMATION_ID,
  PROACTIVE_MESSAGING_ID,
  PROACTIVE_SUPPORT_ID,
  WHATSAPP_ID,
  SURVEYS_ID,
  SWITCH_ID,
  SMS_ID,
];

type PlanId = string;

export function includedAddOnProductIds(): Array<string> {
  return [...INCLUDED_ADD_ON_PRODUCTS];
}

export const addonCardData = (planId: string) => ADDON_CARD_DATA[planId];

export function addonData(planId: PlanId): IntershopAddOnData {
  // Advanced Support Automation content is the same as resolution bot
  if (planId === SUPPORT_AUTOMATION_BASE_ID) {
    return ADD_ON_DATA[ANSWER_BOT_ESSENTIAL_ID];
  }
  return ADD_ON_DATA[planId];
}

export const addonCategories = () => {
  return {
    'intershop.addon_category.expansions': [
      SALES_AUTOMATION_ID,
      SUPPORT_AUTOMATION_ID,
      PROACTIVE_MESSAGING_ID,
    ],
    'intershop.addon_category.outbound': [PRODUCT_TOURS_ID, SURVEYS_ID, PROACTIVE_SUPPORT_ID],
    'intershop.addon_category.channels': [WHATSAPP_ID, SWITCH_ID, SMS_ID],
    'intershop.addon_category.bots': [OPERATOR_ID, ANSWER_BOT_ID, FIN_AI_COPILOT_ID],
    'intershop.addon_category.services': [
      PREMIER_ONBOARDING_ID,
      PREMIER_SUCCESS_ID,
      PREMIER_SUPPORT_ID,
    ],
    'intershop.addon_category.enterprise': [
      ADVANCED_WORKSPACE_MANAGEMENT_ID,
      CUSTOM_API_RATE_LIMIT_ID,
    ],
  };
};

export function categorizeAddons(addonAvailable: any): any {
  let applicableCategories = [];

  for (let [categoryKey, productIds] of Object.entries(addonCategories())) {
    let categoryEntry: any;
    productIds.forEach((productId) => {
      let matchingAddon = addonAvailable.findBy('id', productId);
      if (matchingAddon) {
        let addonsForCategory = categoryEntry?.addons
          ? [...categoryEntry.addons, matchingAddon]
          : [matchingAddon];
        categoryEntry = {
          title: categoryKey,
          addons: addonsForCategory,
        };
      }
    });
    applicableCategories.push(categoryEntry);
  }
  return applicableCategories.compact();
}
