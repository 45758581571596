/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { Metric } from 'embercom/models/data/pricing/metric-types';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import type IntlService from 'embercom/services/intl';

export function mapUsageReminderType(selectedMetric: Metric): Metric {
  switch (selectedMetric) {
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_au:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_au;
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_ca;
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_uk;
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_us:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_us;
    case Metric.resolutions_with_custom_answers:
      return Metric.mapped_resolutions_with_custom_answers;
    default:
      return selectedMetric;
  }
}

export const SMS_ALL_REGIONS = 'sms_all_regions';
export const METRIC_CHART_TYPE_MAP = {
  [Metric.core_seat_count]: VISUALIZATION_TYPES.BAR,
  [Metric.copilot_seat_count]: VISUALIZATION_TYPES.BAR,
  [Metric.emails_sent]: VISUALIZATION_TYPES.LINE,
  [Metric.messages_sent]: VISUALIZATION_TYPES.LINE,
  [SMS_ALL_REGIONS]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_au]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_us]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk]: VISUALIZATION_TYPES.LINE,
  [Metric.whatsapp_inbound]: VISUALIZATION_TYPES.LINE,
  [Metric.whatsapp_outbound]: VISUALIZATION_TYPES.LINE,
  [Metric.resolutions_with_custom_answers]: VISUALIZATION_TYPES.LINE,
  [Metric.calling]: VISUALIZATION_TYPES.LINE,
};

export const SMS_METRICS = [
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
];

export const METRICS_SUPPORTING_USAGE_ALERTS = [
  Metric.emails_sent,
  Metric.messages_sent,
  Metric.whatsapp_inbound,
  Metric.whatsapp_outbound,
  Metric.resolutions_with_custom_answers,
  Metric.calling,
  ...SMS_METRICS,
];

export const METRICS_SUPPORTING_USAGE_LIMITS: [{ metric: Metric; tableName: string }] = [
  { metric: Metric.resolutions_with_custom_answers, tableName: 'fin-usage-limit' },
];

export const METRIC_TYPES = {
  FIXED_PRICE: 'fixed-price-metrics',
  SEAT: 'seat-metrics',
  TIERED: 'tiered-metrics',
  BUCKETED: 'bucketed-metrics',
  BUCKETED_MULTI_WORKSPACE: 'bucketed-multi-workspace-metrics',
};

export function getMetricTooltipMapping(metric: Metric, intl: IntlService) {
  let metricMappings: Partial<
    Record<
      Metric,
      { charge_key: string; title: string; tooltipUsageRowTitle: string; metricType: string }
    >
  > = {
    [Metric.core_seat_count]: {
      charge_key: 'full_seats',
      title: intl.t('billing.usage.pricing-metric.full-seats.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.seat_metric_title'),
      metricType: METRIC_TYPES.SEAT,
    },
    [Metric.calling]: {
      charge_key: 'calling',
      title: intl.t('billing.usage.pricing-metric.phone.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.FIXED_PRICE,
    },
    [Metric.emails_sent]: {
      charge_key: 'bulk_emails_sent_metered_monthly',
      title: intl.t('billing.usage.pricing-metric.emails-sent.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
    [Metric.resolutions]: {
      charge_key: 'resolutions',
      title: intl.t('billing.usage.pricing-metric.resolutions.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.FIXED_PRICE,
    },
    [Metric.resolutions_with_custom_answers]: {
      charge_key: 'resolutions',
      title: intl.t('billing.usage.pricing-metric.resolutions.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.FIXED_PRICE,
    },
    [Metric.whatsapp_inbound]: {
      charge_key: 'whats_app_inbound_conversations_metered_monthly',
      title: intl.t('billing.usage.pricing-metric.whatsapp.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
    [Metric.whatsapp_outbound]: {
      charge_key: 'bulk_whats_app_sent_metered_monthly',
      title: intl.t('billing.usage.pricing-metric.whatsapp_outbound_only.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
    [Metric.sms_segments_sent_received_in_shifted_billing_cycle_au]: {
      charge_key: 'sms_sent_received_au_metered_monthly',
      title: intl.t(
        'billing.usage.pricing-metric-selector.sms_segments_sent_received_in_shifted_billing_cycle_au',
      ),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
    [Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca]: {
      charge_key: 'sms_sent_received_ca_metered_monthly',
      title: intl.t(
        'billing.usage.pricing-metric-selector.sms_segments_sent_received_in_shifted_billing_cycle_ca',
      ),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
    [Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk]: {
      charge_key: 'sms_sent_received_uk_metered_monthly',
      title: intl.t(
        'billing.usage.pricing-metric-selector.sms_segments_sent_received_in_shifted_billing_cycle_uk',
      ),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
    [Metric.sms_segments_sent_received_in_shifted_billing_cycle_us]: {
      charge_key: 'sms_sent_received_us_metered_monthly',
      title: intl.t(
        'billing.usage.pricing-metric-selector.sms_segments_sent_received_in_shifted_billing_cycle_us',
      ),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
    [Metric.copilot_seat_count]: {
      charge_key: 'copilot_seats',
      title: intl.t('billing.usage.pricing-metric.copilot-seats.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.seat_metric_title'),
      metricType: METRIC_TYPES.SEAT,
    },
    [Metric.messages_sent]: {
      charge_key: 'messages_sent',
      title: intl.t('billing.usage.pricing-metric.messages-sent.title'),
      tooltipUsageRowTitle: intl.t('billing.summary.usage-tooltip.non_seat_metric_title'),
      metricType: METRIC_TYPES.TIERED,
    },
  };

  return metricMappings[metric];
}

export const CONTRACT_USAGE_STATUS = {
  CONTRACTED_OVER: 'contracted-over-limit',
  CONTRACTED_UNDER: 'contracted-under-limit',
  NON_CONTRACTED_OVER: 'non-contracted-over-limit',
  NON_CONTRACTED_UNDER: 'non-contracted-under-limit',
};

export function getContractUsageStatus(app: $TSFixMe, currentMetricCharge: $TSFixMe): string {
  // currentMetricCharge = overages
  if (!currentMetricCharge || currentMetricCharge.quantity === 0) {
    return app.isSalesforceContracted
      ? CONTRACT_USAGE_STATUS.CONTRACTED_UNDER
      : CONTRACT_USAGE_STATUS.NON_CONTRACTED_UNDER;
  }

  if (app.isSalesforceContracted) {
    return CONTRACT_USAGE_STATUS.CONTRACTED_OVER;
  }

  return CONTRACT_USAGE_STATUS.NON_CONTRACTED_OVER;
}

export function getCurrentMetricCharge(
  charge_key: string | undefined,
  currentBillingPeriodCharges: $TSFixMe,
) {
  // First, check for the charge in the direct subcharges
  let charge = currentBillingPeriodCharges.charges[0].subcharges.find(
    (subcharge: any) => subcharge.id === charge_key,
  );

  // If not found, check within line_item_groups (for nested items like copilot)
  if (!charge) {
    let lineItemGroup = currentBillingPeriodCharges.charges[0].subcharges.find(
      (subcharge: any) => subcharge.type === 'line_item_group',
    );

    // If line_item_group exists, search within the items for the charge
    if (lineItemGroup) {
      charge = lineItemGroup.items.find((item: any) => item.id === charge_key);
    }
  }
  return charge;
}
