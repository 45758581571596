/* RESPONSIBLE TEAM: team-ai-agent-2 */
import { tracked } from '@glimmer/tracking';
import { type NexusEvent } from 'embercom/services/nexus';
import { type PlaygroundQuestionWireFormat } from './question';

export const QUESTION_GENERATION_UPDATE_EVENT_NAME = 'FinPlaygroundQuestionGenerationUpdate';

export interface QuestionGenerationUpdateEvent extends NexusEvent {
  status: QuestionGenerationStatus;
  requires_user_acknowledgement: boolean;
  questions?: PlaygroundQuestionWireFormat[];
  is_exceeding_question_limit?: boolean;
  fin_playground_group_id: string;
  is_job_running_for_app: boolean;
}

export enum ExtractionType {
  All = 'all',
  LowCsat = 'low_csat',
  HandedToTeammate = 'handed_to_teammate',
}

export type AvailableExtractions = {
  all?: number;
  lowCsat?: number;
  handedToTeammate?: number;
};

export enum QuestionGenerationStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  EmptyResult = 'empty_result',
  Failed = 'failed',
  Running = 'running',
}

export type PlaygroundQuestionGenerationJobWireFormat = {
  status: QuestionGenerationStatus;
  requires_user_acknowledgement: boolean;
  fin_playground_group_id: string;
  is_job_running_for_app: boolean;
};

type PlaygroundQuestionGenerationJobCanGenerateQuestionsWireFormat = {
  all?: number;
  low_csat?: number;
  handed_to_teammate?: number;
};

export type PlaygroundQuestionGenerationWireFormat = {
  is_possible: boolean;
  is_job_running_for_app: boolean;
  job?: PlaygroundQuestionGenerationJobWireFormat;
  can_generate_questions?: PlaygroundQuestionGenerationJobCanGenerateQuestionsWireFormat;
};

export class PlaygroundQuestionGeneration {
  @tracked isPossible: boolean;
  @tracked latestJobStatus: QuestionGenerationStatus | undefined;
  @tracked latestJobRequiresUserAcknowledgement: boolean | undefined;
  @tracked availableExtractions: AvailableExtractions;
  @tracked isJobRunningForApp: boolean;

  constructor(
    isPossible: boolean,
    latestJobStatus: QuestionGenerationStatus | undefined,
    latestJobRequiresUserAcknowledgement: boolean | undefined,
    availableExtractions: AvailableExtractions,
    isJobRunningForApp: boolean,
  ) {
    this.isPossible = isPossible;
    this.latestJobStatus = latestJobStatus;
    this.latestJobRequiresUserAcknowledgement = latestJobRequiresUserAcknowledgement;
    this.availableExtractions = availableExtractions;
    this.isJobRunningForApp = isJobRunningForApp;
  }

  static deserialize(data: PlaygroundQuestionGenerationWireFormat): PlaygroundQuestionGeneration {
    return new PlaygroundQuestionGeneration(
      data.is_possible,
      data.job?.status,
      data.job?.requires_user_acknowledgement,
      {
        all: data.can_generate_questions?.all,
        lowCsat: data.can_generate_questions?.low_csat,
        handedToTeammate: data.can_generate_questions?.handed_to_teammate,
      },
      data.is_job_running_for_app,
    );
  }

  updateLatestJobData(data: PlaygroundQuestionGenerationJobWireFormat) {
    this.latestJobStatus = data.status;
    this.latestJobRequiresUserAcknowledgement = data.requires_user_acknowledgement;
    this.isJobRunningForApp = data.is_job_running_for_app;
  }

  resetLatestJobData() {
    this.latestJobStatus = undefined;
    this.latestJobRequiresUserAcknowledgement = undefined;
  }

  get isRunning(): boolean {
    return this.latestJobStatus === QuestionGenerationStatus.Running;
  }
}
