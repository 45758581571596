/* RESPONSIBLE TEAM: team-help-desk-experience */
import type OnboardingState from 'embercom/lib/inbox2/guide-library/state';
import GLInboxOnboardingStep, { type ClickHandler, GLInboxOnboardingSelectors } from './step';
import type ApplicationInstance from '@ember/application/instance';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

export enum StepId {
  Start = 'start',
  OpenCommandK = 'open-cmd-k',
  OpenMacro = 'open-macro',
  UseMacro = 'use-macro',
  CloseConversation = 'close-conversation',
  OpenAIAssist = 'open-ai-assist',
  UseAIAssist = 'use-ai-assist',
  ShowAIAssistResults = 'show-ai-assist-results',
}

function getHotKeysMap(ref: any) {
  return ref.lookup('service:inbox-hotkeys').hotkeysMap;
}

export class Start extends GLInboxOnboardingStep {
  click: ClickHandler = {
    selector: GLInboxOnboardingSelectors.unknown,
    handler(state: OnboardingState) {
      state.clearComposer();
    },
  };
  id = StepId.Start;
  hasTransitionState = false;
}

export class OpenCommandK extends GLInboxOnboardingStep {
  click: ClickHandler;
  id = StepId.OpenCommandK;
  hasTransitionState = false;

  constructor(owner: ApplicationInstance) {
    super();
    this.click = {
      keyCode: getHotKeysMap(owner)[HotkeyID.CommandK].keys,
      selector: GLInboxOnboardingSelectors.CommandK,
      handler(state: OnboardingState) {
        state.showCommandK();
        state.triggerAnalytics('clicked', 'highlighted_common_actions_btn', StepId.OpenCommandK);
      },
    };
  }

  enter(state: OnboardingState) {
    state.highlightAction('cmd-k-trigger');
    state.focusSections('conversation-composer');
  }
}

export class OpenMacro extends GLInboxOnboardingStep {
  click: ClickHandler;
  id = StepId.OpenMacro;
  hasTransitionState = true;

  constructor(_owner: ApplicationInstance) {
    super();
    this.click = {
      keyCode: '#',
      selector: GLInboxOnboardingSelectors.UseMacro,
      handler(state: OnboardingState) {
        state.showUseMacro();
        state.triggerAnalytics('clicked', 'highlighted_open_macro_btn', StepId.OpenMacro);
      },
    };
  }

  enter(state: OnboardingState) {
    state.focusSections('cmd-k');
    state.commandKFocusedActionIdx = 1;
  }
}

export class UseMacro extends GLInboxOnboardingStep {
  click: ClickHandler;
  id = StepId.UseMacro;
  hasTransitionState = true;

  constructor(_owner: ApplicationInstance) {
    super();
    this.click = {
      selector: GLInboxOnboardingSelectors.InsertMacro,
      handler(state: OnboardingState) {
        state.hideUseMacro();
        state.triggerAnalytics('clicked', 'highlighted_use_macro_btn', StepId.UseMacro);
      },
    };
  }

  enter(state: OnboardingState) {
    state.hideCommandK();
    state.highlightAction('InsertMacro');
  }
}

export class OpenAIAssist extends GLInboxOnboardingStep {
  click: ClickHandler;
  id = StepId.OpenAIAssist;
  hasTransitionState = false;

  constructor(owner: ApplicationInstance) {
    super();
    this.click = {
      keyCode: getHotKeysMap(owner)[HotkeyID.AiAssist].keys,
      selector: GLInboxOnboardingSelectors.AIAssist,
      handler(state: OnboardingState) {
        state.showAIAssist();
        state.triggerAnalytics('clicked', 'highlighted_open_ai_assist_btn', StepId.OpenAIAssist);
      },
    };
  }

  enter(state: OnboardingState) {
    state.focusSections('conversation-composer');
    state.highlightAction('ai-assist-trigger');
    state.insertAIReply();
    state.isComposerFocused = false;
    state.showMacroActions();
  }
}

export class UseAIAssist extends GLInboxOnboardingStep {
  click: ClickHandler;
  id = StepId.UseAIAssist;
  hasTransitionState = true;

  constructor(_owner: ApplicationInstance) {
    super();
    this.click = {
      selector: GLInboxOnboardingSelectors.makeFriendlyAIassist,
      handler(state: OnboardingState) {
        state.hideAIAssist();
        state.triggerAnalytics('clicked', 'highlighted_use_ai_assist_btn', StepId.UseAIAssist);
      },
    };
  }

  enter(state: OnboardingState) {
    state.showAIAssist();
    state.aiAssistFocusedActionIdx = 4;
    state.focusSections('command-k');
  }
}

export class ShowAIAssistResults extends GLInboxOnboardingStep {
  click: ClickHandler;
  id = StepId.ShowAIAssistResults;
  hasTransitionState = false;

  constructor(_owner: ApplicationInstance) {
    super();
    this.click = {
      selector: GLInboxOnboardingSelectors.SendReply,
      handler(state: OnboardingState) {
        state.addAIReply();
        state.softCloseConversation(state.activeConversation);
        state.triggerAnalytics('clicked', 'highlighted_ai_results_btn', StepId.ShowAIAssistResults);
      },
    };
  }

  enter(state: OnboardingState) {
    state.hideAIAssist();
    state.focusSections('conversation-composer');
    state.highlightAction('reply-send-trigger');
    state.insertAIModifiedReply();
    state.isComposerFocused = true;
  }
}

export class CloseConversation extends GLInboxOnboardingStep {
  id = StepId.CloseConversation;
  hasTransitionState = false;

  readonly click: ClickHandler;

  constructor(_owner: ApplicationInstance) {
    super();
    this.click = {
      selector: GLInboxOnboardingSelectors.SendReply,
      handler(state: OnboardingState) {
        state.addAIReply();
        state.softCloseConversation(state.activeConversation);
        state.triggerAnalytics('clicked', 'highlighted_close_convo_btn', StepId.CloseConversation);
      },
    };
  }

  enter(state: OnboardingState) {
    state.isComposerFocused = false;
    state.clearComposer();
    state.clearFocus();
    state.hideMacroActions();
  }
}

export const DefaultSteps = [
  Start,
  OpenCommandK,
  OpenMacro,
  UseMacro,
  OpenAIAssist,
  UseAIAssist,
  ShowAIAssistResults,
  CloseConversation,
];

export function getActiveStepKey(appId: string) {
  return `onboarding-step:default:${appId}`;
}
