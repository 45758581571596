/* RESPONSIBLE TEAM: team-standalone */
/* LIB FILE TAKEN FROM THE fin-zendesk-copilot repo for testing purposes */

// You will need to keep this list in sync with the standalone/copilot template in Rails
export const KNOWN_EVENTS = new Set([
  'IC_FRAME_ADD_COMPOSER_PUBLIC_CONTENT',
  'IC_FRAME_ADD_COMPOSER_PRIVATE_CONTENT',
  'IC_FRAME_CLEAR_COMPOSER',
  'IC_FRAME_GET_CURRENT_COMPOSER_CONTENT',
  'IC_FRAME_CONVERSATION_CHANGED',
  'IC_FRAME_CURRENT_COMPOSER_CONTENT',
]);

export default class IntercomAdapter {
  frameUrl = 'https://frame.intercom.com';

  constructor({ listenWindow, sendWindow, developmentFrameUrl }) {
    this.listenWindow = listenWindow;
    this.sendWindow = sendWindow;
    if (developmentFrameUrl) {
      this.frameUrl = developmentFrameUrl;
    }
    this.handlers = new Map();

    this.handleMessage = this.handleMessage.bind(this);
    this.listenWindow.addEventListener('message', this.handleMessage);
  }

  on(eventType, handler) {
    if (!KNOWN_EVENTS.has(eventType)) {
      console.warn(`Event type ${eventType} is not allowed`);
      return;
    }

    if (!this.handlers.has(eventType)) {
      this.handlers.set(eventType, []);
    }
    this.handlers.get(eventType).push(handler);
  }

  off(eventType, handler) {
    if (!KNOWN_EVENTS.has(eventType)) {
      console.warn(`Event type ${eventType} is not allowed`);
      return;
    }

    if (!this.handlers.has(eventType)) {
      console.warn(`No handlers registered for event type: ${eventType}`);
      return;
    }

    let handlers = this.handlers.get(eventType);
    let index = handlers.indexOf(handler);
    if (index !== -1) {
      handlers.splice(index, 1);
    }
  }

  handleMessage(event) {
    if (event.source !== this.sendWindow) {
      return;
    }

    let { type, payload } = event.data;

    if (this.handlers.has(type)) {
      this.handlers.get(type).forEach((handler) => handler(payload));
    }
  }

  destroy() {
    this.listenWindow.removeEventListener('message', this.handleMessage);
    this.handlers.clear();
  }

  async send(eventType, payload, awaitEvent) {
    if (!KNOWN_EVENTS.has(eventType)) {
      console.warn(`Event type ${eventType} is not allowed`);
      return;
    }

    if (awaitEvent) {
      let promise = new Promise((resolve) => {
        this.on(awaitEvent, resolve);
      });

      this.sendWindow.postMessage({ type: eventType, payload }, this.frameUrl);

      let result = await promise;

      this.off(awaitEvent);
      return result;
    } else {
      this.sendWindow.postMessage({ type: eventType, payload }, this.frameUrl);
    }
  }
}
