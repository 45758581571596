/* RESPONSIBLE TEAM: team-channels */
import UserSummary from 'embercom/objects/inbox/user-summary';
import { uniqBy } from 'embercom/helpers/uniq-by';
import { isPresent } from '@ember/utils';

export type RecipientsWireFormat = {
  to: {
    emails: string[] | [];
    ids: string[] | [];
  };
  cc: {
    emails: string[] | [];
    ids: string[] | [];
  };
  bcc: {
    emails: string[] | [];
    ids: string[] | [];
  };
};

export class Recipients {
  to: UserSummary[];
  cc: UserSummary[];
  bcc: UserSummary[];

  constructor(
    toRecipients?: UserSummary[],
    ccRecipients?: UserSummary[],
    bccRecipients?: UserSummary[],
  ) {
    this.to = toRecipients || [];
    this.cc = ccRecipients || [];
    this.bcc = bccRecipients || [];
  }

  get all(): UserSummary[] {
    return uniqBy([...this.to, ...this.cc, ...this.bcc], (recipient: UserSummary) =>
      isPresent(recipient.email) ? recipient.email : recipient.id,
    );
  }

  serialize(): RecipientsWireFormat {
    let { ids: toIds, emails: toEmails } = this.getIdsAndEmails(this.to);
    let { ids: ccIds, emails: ccEmails } = this.getIdsAndEmails(this.cc);
    let { ids: bccIds, emails: bccEmails } = this.getIdsAndEmails(this.bcc);
    return {
      to: {
        emails: toEmails,
        ids: toIds,
      },
      cc: {
        emails: ccEmails,
        ids: ccIds,
      },
      bcc: {
        emails: bccEmails,
        ids: bccIds,
      },
    };
  }

  static deserialize(recipients: RecipientsWireFormat): Recipients {
    let toRecipientIds = recipients.to.ids.map((id) => new UserSummary(id));
    let ccRecipientIds = recipients.cc.ids.map((id) => new UserSummary(id));
    let bccRecipientIds = recipients.bcc.ids.map((id) => new UserSummary(id));
    let toRecipientEmails = recipients.to.emails.map((email) => new UserSummary('', email));
    let ccRecipientEmails = recipients.cc.emails.map((email) => new UserSummary('', email));
    let bccRecipientEmails = recipients.bcc.emails.map((email) => new UserSummary('', email));

    let toRecipients = [...toRecipientIds, ...toRecipientEmails];
    let ccRecipients = [...ccRecipientIds, ...ccRecipientEmails];
    let bccRecipients = [...bccRecipientIds, ...bccRecipientEmails];

    return new Recipients(toRecipients, ccRecipients, bccRecipients);
  }

  private getIdsAndEmails(recipients: UserSummary[]): { ids: string[]; emails: string[] } {
    let ids = recipients.map((recipient) => recipient.id).filter(Boolean);
    let recipientsWithoutIds = recipients.filter((recipient) => !ids.includes(recipient.id));

    let emails = recipientsWithoutIds
      .map((recipient) => recipient.email)
      .filter((email): email is string => Boolean(email));
    return { ids, emails };
  }
}
