/* RESPONSIBLE TEAM: team-reporting */
import { isNone, isEmpty, isPresent } from '@ember/utils';
import { zip } from 'underscore';
import { requestNameFor } from '../chart-data-resource-compatible-helper';

export default class TableDataResponseMapper {
  static mapToTableInput(responses, metrics, renderableChart) {
    let isTimeComparison = renderableChart?.showTimeComparison;
    let cells = [];

    let combinedResponses = isTimeComparison
      ? this.combinePreviousAndCurrentPeriodResponses(responses)
      : responses;

    let requestNamesToMetrics =
      metrics?.reduce((acc, metric, index) => {
        acc[requestNameFor(index, metric, true)] = metric;
        return acc;
      }, {}) || {};

    cells = combinedResponses.flatMap((r) => {
      // we get the metric associated with the response (multi-metric use case)
      let processingMetric = requestNamesToMetrics[r.name];
      if (r.aggregations.length > 0) {
        // if aggregations array is not empty - this is a single cell
        return this.getAggregationCell(r, processingMetric);
      } else if (r.groups[0].values[0]?.groups || renderableChart?.segmentBy) {
        // if we have nested groups
        return this.getNestedGroupData(r, processingMetric);
      } else {
        // otherwise it's multiple cells that belong to a single column
        return this.getViewByCells(r, processingMetric, renderableChart);
      }
    });

    let nonSummaryCols = new Set(cells.filter((c) => c.subRow !== 'row-summary').map((c) => c.col));

    let result = cells.reduce((acc, cell) => {
      let rowName = isNone(cell.subRow) ? cell.row : cell.subRow;

      if (!acc[rowName]) {
        acc[rowName] = {};
      }

      // Columns support multiple requests per column. We rely on the special structure
      // of column's name for this. If column's name is 'column-group-N[_propName_]', where N is a number,
      // we know that returned value of the request is a value `_propName_` object property.
      if (TableDataResponseMapper.multiColumnCheck(cell.col)) {
        let [colGroupName, groupProperty] = TableDataResponseMapper.extractMultiColumnValue(
          cell.col,
        );

        if (!acc[rowName][colGroupName]) {
          acc[rowName][colGroupName] = {};
        }

        acc[rowName][colGroupName][groupProperty] = cell.value;
      } else if (this.isRatioOrPercentageMetric(cell.metricType) && cell.col !== cell.metricName) {
        // segmentBy
        // get name from cells, group into same cell
        let colGroupName = cell.col;
        let groupProperty = cell.metricName;

        // for the summary row, skip any columns that don't exist in the main rows
        if (rowName === 'row-summary' && !nonSummaryCols.has(colGroupName)) {
          return acc;
        }

        if (!acc[rowName][colGroupName]) {
          acc[rowName][colGroupName] = {};
        }

        acc[rowName][colGroupName][groupProperty] = cell.value;
        acc[rowName][colGroupName][`${groupProperty}_numerator`] = cell.numeratorValue;
        acc[rowName][colGroupName][`${groupProperty}_denominator`] = cell.denominatorValue;
      } else if (
        this.isRatioOrPercentageMetric(cell.metricType) ||
        renderableChart?.isBrokenDownByBucket
      ) {
        // viewBy
        let colGroupName = cell.col;
        acc[rowName][colGroupName] = cell.value;
        acc[rowName][`${colGroupName}_numerator`] = cell.numeratorValue;
        acc[rowName][`${colGroupName}_denominator`] = cell.denominatorValue;

        if (isTimeComparison) {
          acc[rowName][`${colGroupName}_previous`] = cell.previousValue;
        }
      } else {
        let colGroupName = cell.col;
        acc[rowName][colGroupName] = cell.value;

        if (isTimeComparison) {
          acc[rowName][`${colGroupName}_previous`] = cell.previousValue;
        }
      }

      acc[rowName]['groupName'] = rowName;
      return acc;
    }, {});

    return Object.values(result);
  }

  static getAggregationCell(response, metric) {
    return {
      row: response.aggregations[0].name,
      col: response.name,
      value: response.aggregations[0].values[0],
      previousValue: response.previousPeriod?.aggregations[0].values[0],
      metricName: response.name,
      metricType: metric?.type,
      ...(this.isRatioOrPercentageMetric(metric?.type) &&
        response.aggregations[0].processedValues?.length > 0 &&
        isPresent(response.aggregations[0].processedValues[0]) && {
          numeratorValue: response.aggregations[0].processedValues[0].numeratorValue,
          denominatorValue: response.aggregations[0].processedValues[0].denominatorValue,
        }),
    };
  }

  static getViewByCells(response, metric, renderableChart) {
    let isRatioMetricOrViewByBucket =
      this.isRatioOrPercentageMetric(metric?.type) || renderableChart?.isBrokenDownByBucket;

    let shouldGetProcessedValues =
      isRatioMetricOrViewByBucket &&
      response.groups[0].aggregations?.length > 0 &&
      !isEmpty(response.groups[0].aggregations[0].processedValues);
    let processedValues = shouldGetProcessedValues
      ? response.groups[0].aggregations[0].processedValues
      : response.groups[0].aggregations[0].values;
    let rowValues = zip(processedValues, response.groups[0].values);

    let previousRowValues;
    if (response.previousPeriod) {
      let previousPeriodResponse = response.previousPeriod;
      let processedPreviousValues = shouldGetProcessedValues
        ? previousPeriodResponse.groups[0].aggregations[0].processedValues
        : previousPeriodResponse.groups[0].aggregations[0].values;
      previousRowValues = zip(processedPreviousValues, previousPeriodResponse.groups[0].values);
    }

    return rowValues.map((rv, index) => ({
      row: response.groups[0].name,
      subRow: rv[1],
      col: response.name,
      value: shouldGetProcessedValues ? rv[0]?.value : rv[0],
      previousValue: shouldGetProcessedValues
        ? previousRowValues?.[index]?.[0]?.value
        : previousRowValues?.[index]?.[0],
      metricName: response.name,
      metricType: metric?.type,
      ...(shouldGetProcessedValues && {
        numeratorValue: rv[0].numeratorValue,
        denominatorValue: rv[0].denominatorValue,
      }),
    }));
  }

  static getNestedGroupData(r, metric, numeratorData = {}, isDenominator = false) {
    let xGroups =
      isPresent(numeratorData) && !isEmpty(numeratorData.values) ? numeratorData : r.groups[0];
    let emptyPoints = new Map(this.getAllXValues(xGroups).map((x) => [x, null]));

    let isSummaryRow = r.groups[0].aggregations?.length > 0;

    let allPoints = isSummaryRow
      ? this.buildAllPointsForSummaryRow(r.groups[0], metric)
      : this.buildAllPoints(r.groups[0], emptyPoints, numeratorData, isDenominator, metric);

    return this.buildAllCells(allPoints, r, metric, isSummaryRow);
  }

  static buildAllPointsForSummaryRow(group, metric) {
    let allPoints = {};
    let seriesNames = group.values;
    let shouldGetProcessedValues =
      this.isRatioOrPercentageMetric(metric?.type) &&
      !isEmpty(group.aggregations[0].processedValues);
    let seriesYValues = shouldGetProcessedValues
      ? group.aggregations[0].processedValues
      : group.aggregations[0].values;
    for (let [index, x] of seriesNames.entries()) {
      let y = seriesYValues[index];
      if (!shouldGetProcessedValues || isPresent(y?.denominatorValue)) {
        // e.g. allPoints['Chat'] = new Map([['row-summary', 10]]);
        // where Chat is the column name, row-summary is the row name and 10 is the value
        // Map is the format expected by buildAllCells when building the table
        allPoints[x] = new Map([['row-summary', y]]);
      }
    }
    return allPoints;
  }

  static buildAllPoints(outerGroup, emptyPoints, numeratorData, isDenominator, metric) {
    let allPoints = {};
    // when building points for denominator responses we need to filter out using the numerator keys to make sure we only display
    // the keys involved in the operation
    let outerGroupValues =
      isPresent(numeratorData) && !isEmpty(numeratorData.values)
        ? numeratorData.values.map((data) => data.value)
        : [];
    for (let column of outerGroup.values) {
      let x = column.value;
      // we made sure the denominator value (x) is present in the numerator's keys
      if (isEmpty(outerGroupValues) || outerGroupValues.includes(x)) {
        let numeratorInnerKeys =
          isPresent(numeratorData) && !isEmpty(numeratorData.values)
            ? numeratorData.values.find((v) => v.value === x).groups[0]?.values
            : [];
        let data = column.groups.flatMap((innerGroup) =>
          this.getValuesFromInnerGroup(innerGroup, isDenominator, numeratorInnerKeys, metric),
        );
        for (let [seriesName, y] of data) {
          if (!allPoints[seriesName]) {
            allPoints[seriesName] = new Map(emptyPoints);
          }
          let points = allPoints[seriesName];
          points.set(x, y);
        }
      }
    }
    return allPoints;
  }

  static getAllXValues(group) {
    return group.values.map((groupItem) => groupItem.value);
  }

  static getValuesFromInnerGroup(innerGroup, isDenominator, numeratorInnerKeys, metric) {
    // we do the same for innerGroups in case of segmented data
    // we gather all the keys in the numerator response for innerGroups and then we validate these are present in the denominator
    // it'll only be included if it is present in both numerator and denominator response
    if (isDenominator) {
      let names = [];
      let values = [];
      if (!isEmpty(numeratorInnerKeys)) {
        for (let numeratorValue of numeratorInnerKeys) {
          let index = innerGroup.values.indexOf(numeratorValue);
          if (index >= 0) {
            names.push(numeratorValue);
            values.push(innerGroup.aggregations[0].values.get(index));
          }
        }
      }
      return _.zip(names, values);
    }
    // handle other metric types
    let seriesNames = innerGroup.values;
    let seriesYValues =
      this.isRatioOrPercentageMetric(metric?.type) &&
      !isEmpty(innerGroup.aggregations[0].processedValues)
        ? innerGroup.aggregations[0].processedValues
        : innerGroup.aggregations[0].values;
    return _.zip(seriesNames, seriesYValues);
  }

  static buildAllCells(allPoints, r, metric) {
    let result = [];
    let shouldGetProcessedValues = this.isRatioOrPercentageMetric(metric?.type);
    for (let seriesName of Object.keys(allPoints)) {
      let rowValues = allPoints[seriesName];
      if (!isEmpty(rowValues)) {
        for (let [key, data] of rowValues.entries()) {
          result.push({
            row: r.groups[0].name,
            subRow: key,
            col: seriesName,
            value:
              shouldGetProcessedValues && isPresent(data) && 'value' in data ? data.value : data,
            metricName: r.name,
            metricType: metric?.type,
            ...(shouldGetProcessedValues &&
              isPresent(data) &&
              'numeratorValue' in data &&
              'denominatorValue' in data && {
                numeratorValue: data.numeratorValue,
                denominatorValue: data.denominatorValue,
              }),
          });
        }
      }
    }
    return result;
  }

  static multiColumnCheck(colName) {
    return colName.includes('col-group');
  }

  static isRatioOrPercentageMetric(metricType) {
    return ['ratio', 'percentage'].includes(metricType);
  }

  static extractMultiColumnValue(colName) {
    let regexp = /([a-zA-Z-\d]+)_\[([a-zA-Z]+)\]/;
    let [, name, property] = colName.match(regexp);
    return [name, property];
  }

  static combinePreviousAndCurrentPeriodResponses(responses) {
    return responses
      .map((r) => {
        // percentage and ratio metrics pre-process the data to get the numerator and denominator values
        // so if the response already contains a previous period response, we return it as is
        if (r.previousPeriod) {
          return r;
        }

        let isPreviousPeriod = r.name.includes('-previous');

        if (!isPreviousPeriod) {
          let isSingleAggregation = r.aggregations?.length > 0;
          let previousPeriodResponse = responses.find(
            (prevResponse) =>
              prevResponse.name === `${r.name}-previous` &&
              (isSingleAggregation
                ? prevResponse.aggregations?.length > 0
                : prevResponse.groups?.length > 0),
          );
          return {
            ...r,
            previousPeriod: previousPeriodResponse,
          };
        }
      })
      .compact();
  }
}
