/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { tracked } from '@glimmer/tracking';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import {
  type PlaygroundQuestionWireFormat,
  PlaygroundQuestion,
  ANSWER_STATE_ANSWER_TYPE_MAP,
  type AnswerState,
  type CustomerAnswerRating,
  Status,
} from './question';
import {
  PlaygroundQuestionGeneration,
  type PlaygroundQuestionGenerationWireFormat,
} from './question-generation';
import { type PlayGroundSettingsWireFormat, PlaygroundSettings } from './settings';

export type PlaygroundWireFormat = Omit<
  KeysToSnakeCase<Playground>,
  'questions' | 'question_generation'
> & {
  questions: PlaygroundQuestionWireFormat[];
  question_generation: PlaygroundQuestionGenerationWireFormat;
  settings: PlayGroundSettingsWireFormat;
  max_question_limit?: number;
  id?: string;
};

export class Playground {
  @tracked questions = new TrackedArray<PlaygroundQuestion>([]);
  @tracked questionGeneration: PlaygroundQuestionGeneration;
  @tracked questionFilters = new TrackedObject<{
    ratingFilter: CustomerAnswerRating | null;
    answerTypeFilter: AnswerState | null;
  }>({
    ratingFilter: null,
    answerTypeFilter: null,
  });
  @tracked settings: PlaygroundSettings;
  groupName?: string;
  id?: number;

  questionLimit: number;

  constructor(
    questions: PlaygroundQuestion[],
    questionGeneration: PlaygroundQuestionGeneration,
    settings: PlaygroundSettings,
    questionLimit?: number,
    groupName?: string,
    id?: number,
  ) {
    this.questions = new TrackedArray(questions);
    this.questionLimit = questionLimit ?? 50;
    this.questionGeneration = questionGeneration;
    this.settings = settings;
    this.groupName = groupName;
    this.id = id;
  }

  get filteredQuestions() {
    if (!this.questionFilters.ratingFilter && !this.questionFilters.answerTypeFilter) {
      return this.questions;
    }

    let filteredQuestions = this.questions.filter((question) => {
      if (this.questionFilters.ratingFilter && this.questionFilters.answerTypeFilter) {
        // If both filters are set, return question if it matches both conditions
        return (
          question.customerAnswerRating === this.questionFilters.ratingFilter &&
          question.answerType &&
          ANSWER_STATE_ANSWER_TYPE_MAP[this.questionFilters.answerTypeFilter].includes(
            question.answerType,
          )
        );
      }

      if (this.questionFilters.ratingFilter) {
        // If rating filter is set, return question if ratings match
        return question.customerAnswerRating === this.questionFilters.ratingFilter;
      }

      if (this.questionFilters.answerTypeFilter) {
        // If answerState filter is set, return question if answerStates match
        return (
          question.answerType &&
          ANSWER_STATE_ANSWER_TYPE_MAP[this.questionFilters.answerTypeFilter].includes(
            question.answerType,
          )
        );
      }

      return true;
      // This return is just to appease typescript, it should never be reached
    });

    return filteredQuestions;
  }

  get isPending() {
    return this.questions.every((q) => q.status === Status.Pending);
  }

  get isRunning() {
    return this.questions.some((q) => q.status === Status.Running);
  }

  get hasQuestions() {
    return this.questions.length > 0;
  }

  get isActive(): boolean {
    return this.hasQuestions || this.questionGeneration.isRunning;
  }

  get pendingQuestions() {
    return this.questions.filter((q) => q.status === Status.Pending);
  }

  get hasReachedQuestionLimit(): boolean {
    return this.questions.length >= this.questionLimit;
  }

  get remainingQuestionQuota(): number {
    if (this.hasReachedQuestionLimit) {
      return 0;
    }

    return this.questionLimit - this.questions.length;
  }

  get countOfFilteredQuestions(): number {
    return this.filteredQuestions.length;
  }

  get countOfBulkSelectedQuestions(): number {
    return this.bulkSelectedQuestions.length;
  }

  get countOfFilteredQuestionsWithoutErrors(): number {
    return this.filteredQuestions.filter((question) => !question.answerHasError).length;
  }

  get bulkSelectedQuestions() {
    return this.filteredQuestions.filter((question) => question.isBulkSelected);
  }

  get areQuestionsBulkSelected() {
    return this.bulkSelectedQuestions.length > 0;
  }

  addQuestions(questions: PlaygroundQuestion[]) {
    this.questions.push(...questions);
  }

  removeQuestion(questionText: string) {
    this.questions = new TrackedArray(
      this.questions.filter((q) => q.questionText !== questionText),
    );
  }

  removeQuestionById(id: number) {
    this.questions = new TrackedArray(this.questions.filter((q) => q.id !== id));
  }

  replaceQuestionByText(newQuestion: PlaygroundQuestion) {
    let index = this.questions.findIndex((q) => q.questionText === newQuestion.questionText);
    if (index === -1) {
      return;
    }
    this.questions[index] = newQuestion;
  }

  replaceRunningQuestionsWithCompleted(questions: PlaygroundQuestion[]) {
    questions.forEach((question) => {
      let index = this.questions.findIndex((q) => q.questionText === question.questionText);
      let currentQuestion;
      if (index !== -1) {
        currentQuestion = this.questions[index];
        if (currentQuestion.status === Status.Running && question.status === Status.Completed) {
          this.questions[index] = question;
        }
      }
    });
  }

  setRunningStatus(questions?: PlaygroundQuestion[]) {
    questions?.forEach((q) => q.run());
  }

  hasQuestion(questionText: string) {
    return this.questions.some((q) => q.questionText === questionText);
  }

  setQuestionState(id: number, status: Status) {
    let question = this.questions.find((q) => q.id === id);

    if (question) {
      question.status = status;
    }
  }

  updateQuestionRatingFilter(rating: CustomerAnswerRating | null) {
    this.questionFilters.ratingFilter = rating;
  }

  updateAnswerTypeFilter(filter: AnswerState | null) {
    this.questionFilters.answerTypeFilter = filter;
  }

  static deserialize(data: PlaygroundWireFormat): Playground {
    return new Playground(
      data.questions.map((q) => PlaygroundQuestion.deserialize(q)),
      PlaygroundQuestionGeneration.deserialize(data.question_generation),
      PlaygroundSettings.deserialize(data.settings),
      data.max_question_limit,
      data.group_name,
      Number(data.id),
    );
  }

  countOfQuestionsByAnswerState(answerType: AnswerState): number {
    return this.questions.filter(
      (q) => q.answerType && ANSWER_STATE_ANSWER_TYPE_MAP[answerType].includes(q.answerType),
    ).length;
  }

  percentageOfQuestionsByAnswerState(answerType: AnswerState): number {
    return Math.round(
      (this.countOfQuestionsByAnswerState(answerType) / this.questions.length) * 100,
    );
  }

  countOfQuestionsByAnswerRating(rating: CustomerAnswerRating): number {
    return this.questions.filter((q) => q.customerAnswerRating === rating).length;
  }

  percentageOfQuestionsByAnswerRating(rating: CustomerAnswerRating): number {
    return Math.round((this.countOfQuestionsByAnswerRating(rating) / this.questions.length) * 100);
  }
}
