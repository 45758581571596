/* RESPONSIBLE TEAM: team-reporting */
import { get } from '@ember/object';
import type Model from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import FragmentArray from 'ember-data-model-fragments/array/fragment';

export function unloadRelatedModelFragments(model: Model) {
  let modelClass = model.constructor as typeof Model;

  if ('attributes' in modelClass) {
    let attributes = get(modelClass, 'attributes') as Map<string, { isFragment: boolean }>;

    for (let [name, meta] of attributes) {
      if (meta.isFragment) {
        let obj = get(model, name) as Fragment | FragmentArray;
        if (obj instanceof FragmentArray) {
          obj.forEach(unloadRelatedModelFragments);
        } else if (obj instanceof Fragment) {
          unloadRelatedModelFragments(obj);
        }
      }
    }
  }

  if (model instanceof Fragment) {
    model.unloadRecord();
  }
}
