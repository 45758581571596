/* RESPONSIBLE TEAM: team-knowledge-foundations */

import {
  BaseConfig,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_ORDERED_NESTED_LIST,
  INPUT_RULE_UNORDERED_NESTED_LIST,
  INPUT_RULE_INLINE_CODE,
} from '@intercom/embercom-prosemirror-composer';
import { type TextDirection } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { type UploadConfig } from '@intercom/embercom-prosemirror-composer/addon/lib/config/composer-config';
import EmbercomFileUploader from '../articles/embercom-file-uploader';
import { EntityType } from 'embercom/models/data/entity-types';
export default class InternalNotesComposerConfig extends BaseConfig {
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    uploader: EmbercomFileUploader,
  } as UploadConfig;
  typeaheads = [EMOJI_TYPEAHEAD];
  placeholder = 'Start writing…';
  showAllTextAlignment = true;

  constructor(app: any, textDirection: TextDirection = 'ltr') {
    super();
    this.upload.attrs = { policyUrl: `/apps/${app.id}/uploads` };
    this.upload.allowedAttachmentTypes = ['*'];

    this.experimental.inlineResizeImages = true;

    this.textDirection = textDirection;

    let tools: any[] = [
      { name: 'media-toolbar', options: { showListToolbarIcon: true } },
      { name: 'text-formatter' },
      {
        name: 'content-anchor-editor',
        options: {
          useTeammateUrls: true,
          entityTypes: [EntityType.InternalArticle, EntityType.ArticleContent],
        },
      },
      { name: 'video-editor' },
      { name: 'button-editor' },
      { name: 'image-editor', options: { supportAltAttributeEditing: true } },
      { name: 'table-editor' },
      { name: 'color-popover-editor' },
      { name: 'collapsible-toggle' },
    ];

    let blocks = [
      'paragraph',
      'image',
      'orderedNestedList',
      'unorderedNestedList',
      'heading',
      'subheading',
      'subheading3',
      'subheading4',
      'codeBlock',
      'video',
      'button',
      'horizontalRule',
      'table',
      'callout',
      'collapsibleSection',
      'unsupportedElement',
    ];

    this.tables = {
      resizeColumns: true,
      responsive: false,
      containers: false,
    };

    this.inputRules = [
      INPUT_RULE_CODE_BLOCK,
      INPUT_RULE_EMOJI,
      INPUT_RULE_ORDERED_NESTED_LIST,
      INPUT_RULE_UNORDERED_NESTED_LIST,
      INPUT_RULE_INLINE_CODE,
    ];

    this.tools = tools;
    this.allowedBlocks = blocks;
    this.isInteractivityDisabled = false;
  }
}
