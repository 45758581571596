/* RESPONSIBLE TEAM: team-channels */
import {
  latestUpdatedRecipientsListStorageKey,
  participantObjectToUserSummary,
  getReplyToData,
} from 'embercom/objects/inbox/user-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import Conversation, { type NewConversation } from 'embercom/objects/inbox/conversation';
import type UserComment from 'embercom/objects/inbox/renderable/user-comment';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { Channel } from 'embercom/models/data/inbox/channels';
import { isPresent } from '@ember/utils';
import { type EmailHeaderAddress } from 'embercom/objects/inbox/renderable/email-metadata';
import type UserEmailComment from 'embercom/objects/inbox/renderable/user-email-comment';
import { Recipients } from 'embercom/lib/composer/recipients';
import storage from 'embercom/vendor/intercom/storage';
import type Session from 'embercom/services/session';

function buildDefaultRecipients(conversation: Conversation | NewConversation) {
  return new Recipients(conversation.participantSummaries, []);
}

function buildEmailReplyRecipients(conversation: Conversation | NewConversation) {
  let toRecipients = conversation.participantSummaries.slice(0, 1);
  let ccRecipients = conversation.participantSummaries.slice(1);
  return new Recipients(toRecipients, ccRecipients);
}

function buildUserCommentReplyRecipients(conversation: Conversation, lastPart: UserComment) {
  let toRecipients = [lastPart.userSummary];
  let ccRecipients = conversation.participantSummaries.filter(
    (participant) => participant.email !== lastPart.userSummary.email,
  );

  return new Recipients(toRecipients, ccRecipients);
}

function matchOrder(
  unorderedRecipients: UserSummary[],
  orderedEmails: (string | undefined)[] | undefined,
) {
  if (!orderedEmails || orderedEmails?.find((email) => isPresent(email))?.length === 0) {
    return unorderedRecipients;
  }

  return unorderedRecipients.sort((a, b) => {
    let indexA = orderedEmails.indexOf(a.email) ?? -1;
    let indexB = orderedEmails.indexOf(b.email) ?? -1;
    return indexA - indexB;
  });
}

function getRecipientsFromHeaderAddress(
  conversationUsers: UserSummary[],
  emailHeaderAddresses?: EmailHeaderAddress[],
) {
  let orderedRecipientEmails = emailHeaderAddresses?.map((address) => address.email);

  let recipients =
    orderedRecipientEmails
      ?.map((email) => conversationUsers.find((userSummary) => userSummary.email === email))
      .filter((recipient): recipient is UserSummary => recipient !== undefined) ?? [];
  return matchOrder(recipients, orderedRecipientEmails);
}

function buildAdminCommentReplyRecipients(conversation: Conversation, lastPart: AdminComment) {
  let replyToSummaries = getReplyToData(conversation);
  let participantAndReplyToSummaries = conversation.participantSummaries.concat(replyToSummaries);

  let toRecipients = getRecipientsFromHeaderAddress(
    participantAndReplyToSummaries,
    lastPart.emailMetadata?.headerAddresses?.to,
  );

  let ccRecipients = getRecipientsFromHeaderAddress(
    participantAndReplyToSummaries,
    lastPart.emailMetadata?.headerAddresses?.cc,
  );

  let nonHeaderParticipants = conversation.participantSummaries.filter(
    (participant) =>
      !toRecipients.map((recipient) => recipient.id).includes(participant.id) &&
      !ccRecipients.map((recipient) => recipient.id).includes(participant.id),
  );

  ccRecipients = ccRecipients.concat(nonHeaderParticipants);

  return new Recipients(toRecipients, ccRecipients);
}

function partIsUserComment(part: RenderablePart | undefined) {
  return (
    part?.renderableType === RenderableType.UserComment ||
    part?.renderableType === RenderableType.UserEmailComment
  );
}

function isUserCommentFromAParticipant(part: RenderablePart, conversation: Conversation) {
  let userComment = part.renderableData as UserComment;
  let participantEmails = conversation.participantSummaries.map((participant) => participant.email);

  return partIsUserComment(part) && participantEmails.includes(userComment.userSummary.email);
}

function isAdminCommentWithEmailMetadata(part: RenderablePart) {
  return part.renderableType === RenderableType.AdminComment && part.renderableData.emailMetadata;
}

function lastValidPartForReply(conversation: Conversation) {
  let allHumanPartsOrderedLastToFirst = conversation.allHumanCommentParts.slice().reverse();

  return allHumanPartsOrderedLastToFirst.find((part) => {
    return (
      isAdminCommentWithEmailMetadata(part) || isUserCommentFromAParticipant(part, conversation)
    );
  });
}

function partIsAdminComment(part: RenderablePart | undefined) {
  return part?.renderableType === RenderableType.AdminComment;
}

function createEmailReplyRecipients(conversation: Conversation) {
  let lastPart = lastValidPartForReply(conversation);

  return getRecipientsForPart(lastPart, conversation);
}

function createReplyRecipients(conversation: Conversation | NewConversation) {
  let isEmailCurrentChannel =
    conversation instanceof Conversation && conversation?.channel.current === Channel.Email;

  if (isEmailCurrentChannel) {
    return createEmailReplyRecipients(conversation as Conversation);
  } else {
    return buildDefaultRecipients(conversation);
  }
}

function recipientsInStorage(conversation: Conversation | NewConversation, workspaceId: string) {
  let conversationId = conversation?.id;
  return storage.get(latestUpdatedRecipientsListStorageKey(workspaceId, conversationId));
}

function createFromDraft(recipientsInStorage: { to: UserSummary[]; cc: UserSummary[] }) {
  let toRecipients = recipientsInStorage.to.map((recipient: UserSummary) =>
    participantObjectToUserSummary(recipient),
  );
  let ccRecipients = recipientsInStorage.cc.map((recipient: UserSummary) =>
    participantObjectToUserSummary(recipient),
  );

  return new Recipients(toRecipients, ccRecipients);
}

function noQueryParamRecipients(conversation: Conversation | NewConversation) {
  return (
    conversation instanceof Conversation ||
    conversation.participantSummaries?.length === 0 ||
    !conversation.isRecipientLoadedFromQueryParams
  );
}

function getRecipientsForPart(part: RenderablePart | undefined, conversation: Conversation) {
  if (partIsUserComment(part)) {
    return buildUserCommentReplyRecipients(conversation, part?.renderableData as UserComment);
  } else if (partIsAdminComment(part)) {
    return buildAdminCommentReplyRecipients(conversation, part?.renderableData as AdminComment);
  } else {
    return buildEmailReplyRecipients(conversation);
  }
}

function getRecipientsListFromMetadata(part: RenderablePart, emailUsers: UserSummary[]) {
  let toRecipients = getRecipientsFromHeaderAddress(
    emailUsers,
    part.renderableData?.emailMetadata?.headerAddresses?.to,
  );

  let ccRecipients = getRecipientsFromHeaderAddress(
    emailUsers,
    part.renderableData?.emailMetadata?.headerAddresses?.cc,
  );

  if (part.renderableType === RenderableType.UserEmailComment) {
    toRecipients = [...toRecipients, (part.renderableData as UserEmailComment).userSummary];
  }

  return new Recipients(toRecipients, ccRecipients);
}

export function createRecipientsWithoutDraft(conversation: Conversation | NewConversation) {
  return createReplyRecipients(conversation);
}

export function createRecipients(conversation: Conversation | NewConversation, session: Session) {
  let storedRecipients = recipientsInStorage(conversation, session.workspace.id);
  if (storedRecipients && noQueryParamRecipients(conversation)) {
    return createFromDraft(storedRecipients);
  } else {
    return createReplyRecipients(conversation);
  }
}

export function createRecipientsForEmailPart(
  part: RenderablePart,
  emailUsers: UserSummary[],
  conversation: Conversation,
) {
  return part.renderableData?.emailMetadata
    ? getRecipientsListFromMetadata(part, emailUsers)
    : getRecipientsForPart(part, conversation);
}
