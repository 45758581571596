/* RESPONSIBLE TEAM: team-reporting */

import { resource } from 'ember-resources/util/function-resource';
import { type SerializedChart } from 'embercom/components/reporting/in-context/static-report';
import { type ReportState } from 'embercom/components/reporting/custom/report/chart-card';
import { destroy } from '@ember/destroyable';
// @ts-expect-error No types declaration for ember-copy yet.
import { copy } from 'ember-copy';
import ChartDataResource from 'embercom/lib/reporting/chart-data-resource';
import type Store from '@ember-data/store';
import { getOwner } from '@ember/application';
import type Owner from '@ember/owner';
import RenderableChart from 'embercom/models/reporting/custom/renderable-chart';
import { registerDestructor } from '@ember/destroyable';
import generateUUID from 'embercom/lib/uuid-generator';

function buildRenderableChart(
  owner: Owner,
  serializedChart: SerializedChart,
  reportState: ReportState,
) {
  let store = owner.lookup('service:store') as Store;
  serializedChart = copy(serializedChart, true);
  serializedChart.id = `-static-chart-data-resource-${serializedChart.id || generateUUID()}`;
  store.pushPayload({ 'reporting/custom/chart': serializedChart });
  let chart = store.peekRecord('reporting/custom/chart', serializedChart.id);
  let renderableChart = new RenderableChart(chart, reportState, owner);
  registerDestructor(renderableChart, () => chart.unloadRecord());
  return renderableChart;
}

function staticDataResource(
  owner: object,
  {
    serializedChart,
    reportState,
  }: { serializedChart: () => SerializedChart; reportState: () => ReportState },
) {
  let emberApp = getOwner(owner) as Owner;
  return resource(({ on }) => {
    let renderableChart = buildRenderableChart(emberApp, serializedChart(), reportState());
    let chartDataResource = new ChartDataResource(emberApp, { renderableChart });
    on.cleanup(() => {
      destroy(renderableChart);
      destroy(chartDataResource);
    });
    return chartDataResource;
  });
}

export { staticDataResource };
