/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import ArticleMapper from '@intercom/billing-core/article-distribution-mapper';

import {
  PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_0_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
} from 'embercom/lib/billing';

export function getArticleFromDistribution(
  articleName: string,
  app: $TSFixMe = null,
  customerService: $TSFixMe = null,
) {
  let articleMapper = new ArticleMapper();
  if (!app) {
    return articleMapper.getArticle(articleName, {}); // Empty context, will return default article
  }

  let context = {
    isAppPPP: app.canUsePerProductPricingFlow,
    isAppVBP1: app.hasValueBasedPricing1,
    isAppVBP2: app.hasValueBasedPricing2,
    isCustomerOnTPR: customerService.customer?.subscription?.isOnTprPricingModel,
    usesResolutionsWithCustomAnswers: app.usesResolutionsWithCustomAnswers,
    isPricing5_0_EarlyStagePricingModel:
      customerService.currentPricingModelIdentifier === PRICING_5_0_EARLY_STAGE_PRICING_MODEL,
    isPricing5_0_EarlyStageFreePricingModel:
      customerService.currentPricingModelIdentifier === PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL,
    isPricing5_1_EarlyStagePricingModel:
      customerService.currentPricingModelIdentifier === PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
    isPricing5_1_EarlyStageFreePricingModel:
      customerService.currentPricingModelIdentifier === PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
    isPricing5_1_CopilotEarlyStagePricingModel:
      customerService.currentPricingModelIdentifier ===
      PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
    isPricing5_1_CopilotEarlyStageFreePricingModel:
      customerService.currentPricingModelIdentifier ===
      PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
  };

  return articleMapper.getArticle(articleName, context);
}

export function getArticleResponsibleTeam(articleName: string) {
  return new ArticleMapper().getResponsibleTeam(articleName);
}
