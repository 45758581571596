/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// @ts-nocheck
import { type AttributeInfoResolver } from '@intercom/embercom-prosemirror-composer';

export const AUTHOR_NAME_ATTRIBUTE = {
  name: 'Author name',
  identifier: 'message.author.first_name',
  templatable_identifier: 'message.author.first_name',
  noFallback: true,
  isApp: true,
};

export const AUTHOR_FULL_NAME_ATTRIBUTE = {
  name: 'Author full name',
  identifier: 'message.author.name',
  templatable_identifier: 'message.author.name',
  noFallback: true,
  isApp: true,
};

export const APP_NAME_ATTRIBUTE = {
  name: 'App name',
  identifier: 'app.name',
  templatable_identifier: 'app.name',
  noFallback: true,
  isApp: true,
};

export const AUTHOR_JOB_TITLE_ATTRIBUTE = {
  name: 'Author job title',
  identifier: 'message.author.job_title',
  templatable_identifier: 'message.author.job_title',
  noFallback: true,
  isApp: true,
};

export const AUTHOR_PHONE_NUMBER_ATTRIBUTE = {
  name: 'Author phone number',
  identifier: 'message.author.phone_number',
  templatable_identifier: 'message.author.phone_number',
  noFallback: true,
  isApp: true,
};

export const AUTHOR_DEPARTMENT_ATTRIBUTE = {
  name: 'Author department',
  identifier: 'message.author.department',
  templatable_identifier: 'message.author.department',
  noFallback: true,
  isApp: true,
};

export const AUTHOR_EMAIL_ATTRIBUTE = {
  name: 'Author email',
  identifier: 'message.author.email',
  templatable_identifier: 'message.author.email',
  noFallback: true,
  isApp: true,
};

const UNSUBSCRIBE_LINK_ATTRIBUTE = {
  name: 'Unsubscribe link',
  identifier: 'unsubscribe_link',
  templatable_identifier: 'unsubscribe_link',
  noFallback: true,
  isApp: true,
};

const UNSUBSCRIBE_URL_ATTRIBUTE = {
  name: 'Unsubscribe URL',
  identifier: 'unsubscribe_url',
  templatable_identifier: 'unsubscribe_url',
  noFallback: true,
  isApp: true,
};

export const APP_ATTRIBUTES = [
  AUTHOR_NAME_ATTRIBUTE,
  AUTHOR_FULL_NAME_ATTRIBUTE,
  APP_NAME_ATTRIBUTE,
];
export const EMAIL_TEMPLATE_ATTRIBUTES = [
  APP_NAME_ATTRIBUTE,
  UNSUBSCRIBE_LINK_ATTRIBUTE,
  UNSUBSCRIBE_URL_ATTRIBUTE,
  AUTHOR_FULL_NAME_ATTRIBUTE,
];

const DEFAULT_CONFIG = {
  attributes: [],
  includeAppAttributes: true,
  includeCustomObjectAttributes: false,
  includeCustomActionAttributes: false,
  includeFileAttributes: false,
  manualAppAttributes: APP_ATTRIBUTES,
  customObjectAttributes: [],
  selectedEvents: [],
  transientAttributes: [],
  hideAppAllowedAttributes: false,
  webhookReceivedAttributes: [],
  attributeAllowlist: null,
  localAttributes: [],
  customActions: [],
  allLocalAttributes: [],
};

export default class TemplateAttributeResolver implements AttributeInfoResolver {
  attributeAllowlist;
  attributes;
  manualAppAttributes;
  selectedEvents;
  transientAttributes;
  includeCustomObjectAttributes;
  includeCustomActionAttributes;
  includeFileAttributes;
  conversationAttributes;
  customObjectAttributes;
  hideAppAllowedAttributes;
  webhookReceivedAttributes;
  localAttributes;
  customActions;
  allLocalAttributes;

  constructor(options) {
    let {
      attributes,
      conversationAttributes,
      customObjectAttributes,
      ticketTypeAttributes,
      includeAppAttributes,
      includeCustomObjectAttributes,
      includeCustomActionAttributes,
      includeFileAttributes,
      manualAppAttributes,
      selectedEvents,
      transientAttributes,
      hideAppAllowedAttributes,
      webhookReceivedAttributes,
      attributeAllowlist,
      localAttributes,
      customActions,
      allLocalAttributes,
    } = Object.assign({}, DEFAULT_CONFIG, options);
    this.attributes = attributes;
    this.manualAppAttributes = includeAppAttributes ? manualAppAttributes : [];
    this.customObjectAttributes = customObjectAttributes;
    this.conversationAttributes = conversationAttributes;
    this.includeCustomObjectAttributes = includeCustomObjectAttributes;
    this.includeCustomActionAttributes = includeCustomActionAttributes;
    this.includeFileAttributes = includeFileAttributes;
    this.ticketTypeAttributes = ticketTypeAttributes;
    this.selectedEvents = selectedEvents;
    this.transientAttributes = transientAttributes;
    this.hideAppAllowedAttributes = hideAppAllowedAttributes;
    this.webhookReceivedAttributes = webhookReceivedAttributes;
    this.attributeAllowlist = attributeAllowlist;
    this.localAttributes = localAttributes;
    this.customActions = customActions;
    this.allLocalAttributes = allLocalAttributes;
  }

  private findAttribute(path: string) {
    return (
      this.attributes?.findBy('templatable_identifier', path) ||
      this.eventAttributes?.findBy('templatable_identifier', path) ||
      this.customObjectAttributes?.findBy('templatable_identifier', path) ||
      this.conversationAttributes?.findBy('templatable_identifier', path) ||
      this.ticketTypeAttributes?.findBy('templatable_identifier', path) ||
      this.webhookReceivedAttributes?.findBy('templatable_identifier', path) ||
      // eslint-disable-next-line eqeqeq
      this.manualAppAttributes.find((element) => element.templatable_identifier == path) ||
      this.allLocalAttributes
        ?.map((attributeGroup) => attributeGroup.attributes)
        .flat()
        .findBy('templatable_identifier', path) ||
      this.customActions
        ?.map((attributeGroup) => attributeGroup.attributes)
        .flat()
        .findBy('templatable_identifier', path)
    );
  }

  private duplicateEventAttribute(itemData, type) {
    let itemToDuplicate = Object.create(itemData);

    itemToDuplicate.templatable_identifier = `${itemToDuplicate.templatable_identifier}.${type}`;
    itemToDuplicate.name = `${itemToDuplicate.name.replace(/_|\-/g, ' ')} ${type}`;
    itemToDuplicate.isUser = true;
    itemToDuplicate.isUserEvent = true;

    return itemToDuplicate;
  }

  get eventAttributes() {
    let eventAttributes = [];
    this.attributes?.filterBy('isUserEvent').forEach((attribute) => {
      let itemData = attribute.toJSON();
      eventAttributes.push(this.duplicateEventAttribute(itemData, 'count'));
      eventAttributes.push(this.duplicateEventAttribute(itemData, 'first'));
      eventAttributes.push(this.duplicateEventAttribute(itemData, 'last'));
    });

    return eventAttributes;
  }

  public humanReadable(path: string) {
    return this.findAttribute(path)?.composer_name || this.findAttribute(path)?.name || path;
  }

  public isValid(path: string) {
    return !!this.findAttribute(path);
  }

  public requiresFallback(path: string, fallback: string) {
    if (fallback) {
      return false;
    }

    let attribute = this.findAttribute(path);
    if (!attribute) {
      return false;
    }

    if (attribute.noFallback) {
      return false;
    }

    return true;
  }

  public fallbackInfoText(path: string) {
    let attribute = this.findAttribute(path);
    let name = this.humanReadable(path);

    if (attribute.isUser) {
      name = `Recipient's ${name.toLowerCase()}`;
    }

    return `${name} will be inserted here`;
  }

  public isFallbackAllowed(path: string): boolean {
    return this.isValid(path) && !this.findAttribute(path)?.noFallback;
  }
}
