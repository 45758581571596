/* RESPONSIBLE TEAM: team-standalone */

import { isBlank } from '@ember/utils';
import {
  ACCESSIBLE_STANDALONE_SETTING_ROUTES_VIA_DIRECT_LINK,
  accessibleSettingNavItemRoutes,
  STANDALONE_FALLBACK_SETTING_ROUTE,
} from 'embercom/components/new-settings/navigation/submenu-items';

const allowedStandaloneEntryRoutes = [
  'apps.app.index',
  'apps.app.billing',
  'apps.app.standalone',
  'apps.app.knowledge-hub',
  'apps.app.getting-started',
  'inbox.workspace',
];

export function isStandaloneRoute(route: string) {
  let standaloneRoutes = [...allowedStandaloneEntryRoutes, 'apps.app.settings'];

  return standaloneRoutes.some((standaloneRoute) => route.startsWith(standaloneRoute));
}

const mainPlatformRoutesWithStandaloneFallback = [
  'apps.app.automation.fin-ai-agent.guidance',
  'apps.app.fin-ai-agent',
  'apps.app.automation.fin',
  ...Object.keys(STANDALONE_FALLBACK_SETTING_ROUTE),
];

function routeHasStandaloneFallback(route: string) {
  return mainPlatformRoutesWithStandaloneFallback.some((standaloneRoute) =>
    route.startsWith(standaloneRoute),
  );
}

export const expiredSubscriptionRoute = 'apps.app.expired-subscription';

function isStandaloneTrustedListRoute(route: string) {
  return [
    'apps.app.loading',
    'apps.app.copilot-frame',
    'apps.app.teams-checkout',
    'apps.app.teams-checkout_loading',
    'apps.app.teams-checkout.index',
    'apps.app.teams-checkout.verify',
    'apps.app.checkout.index',
    'apps.app.checkout.success',
    'apps.app.checkout.invite-teammates',
    expiredSubscriptionRoute,
  ].includes(route);
}

function isStandaloneEntryRoute(route: string) {
  return allowedStandaloneEntryRoutes.some((standaloneRoute) => route.startsWith(standaloneRoute));
}

export function standaloneSettingRedirectRoute(app: any, customer: any, targetRoute: any) {
  if (
    !accessibleSettingNavItemRoutes(app, customer).includes(targetRoute) &&
    !targetRoute.includes('apps.app.settings.index')
  ) {
    if (STANDALONE_FALLBACK_SETTING_ROUTE[targetRoute]) {
      // we fallback to standalone version for some settings versions
      // i.e. settings/data/companies vs settings/standalone/data/companies
      return STANDALONE_FALLBACK_SETTING_ROUTE[targetRoute];
    } else if (!ACCESSIBLE_STANDALONE_SETTING_ROUTES_VIA_DIRECT_LINK.includes(targetRoute)) {
      // there are some routes that are accessible under a setting nav item
      // i.e. channels.messenger.general is accessible in the main messenger settings page
      return 'apps.app.settings.index';
    }
  }

  return null;
}

function isAllowedStandaloneSettingsRoute(
  app: any,
  customer: any,
  currentRoute: string,
  targetRoute: string,
) {
  if (currentRoute.startsWith('apps.app.settings') && !targetRoute.endsWith('loading')) {
    return isBlank(standaloneSettingRedirectRoute(app, customer, targetRoute));
  }

  // settings route already have a redirect logic for standalone apps
  return targetRoute.startsWith('apps.app.settings');
}

export function isStandaloneAllowedRoute(
  app: any,
  customer: any,
  currentRoute: string,
  targetRoute: string,
) {
  return (
    isStandaloneEntryRoute(targetRoute) ||
    isAllowedStandaloneSettingsRoute(app, customer, currentRoute, targetRoute) ||
    isStandaloneTrustedListRoute(targetRoute) ||
    routeHasStandaloneFallback(targetRoute)
  );
}
