/* RESPONSIBLE TEAM: team-frontend-tech */
import ConversationLoader from 'embercom/lib/inbox/conversation-loader';

export default {
  name: 'inbox',
  initialize(application) {
    application.register('customClass:inbox/conversationLoader', ConversationLoader, {
      singleton: false,
    });
  },
};
