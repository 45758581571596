/* RESPONSIBLE TEAM: team-frontend-tech */
import moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

export const SECONDS_IN = {
  hour: 3600,
  day: 3600 * 24,
  week: 3600 * 24 * 7,
  month: 3600 * 24 * 31,
  semester: 3600 * 24 * 180,
  year: 3600 * 24 * 365,
};

function getFormats(duration) {
  return {
    default: {
      options: {
        trim: 'both',
      },
      template() {
        switch (true) {
          case duration < SECONDS_IN.hour:
            return `m[m] s[s]`;
          case duration < SECONDS_IN.day:
            return `h[h] m[m]`;
          case duration < SECONDS_IN.month:
            return `d[d] h[h]`;
          case duration < SECONDS_IN.semester:
            return `M[mo] d[d]`;
          default:
            return 'Y[y] M[mo]';
        }
      },
    },

    'single-unit-hours': {
      options: {
        trim: 'both',
        precision: duration < SECONDS_IN.day && duration > SECONDS_IN.hour ? 1 : 0, // show decimals for hours below a day
      },
      template() {
        switch (true) {
          case duration < SECONDS_IN.hour:
            return `m [minutes]`;
          default:
            return `h [hours]`;
        }
      },
    },

    'unitless-hours': {
      options: {
        trim: 'both',
        precision: duration < SECONDS_IN.hour ? 1 : 0, // show decimals for minutes below an hour
      },
      template() {
        return `h`;
      },
    },

    'rounded-default': {
      options: {
        trim: 'both',
      },
      template() {
        switch (true) {
          case duration < 60:
            return `s[s]`;
          case duration < SECONDS_IN.hour:
            return `m[m]`;
          case duration < SECONDS_IN.day:
            return `h[h]`;
          case duration < SECONDS_IN.month:
            return `d[d]`;
          case duration < SECONDS_IN.year:
            return `M[mo]`;
          default:
            return 'Y[y]';
        }
      },
    },

    'range-rounded-default': {
      options: {
        trim: 'both',
      },
      template() {
        switch (true) {
          case duration < 60:
            return `s[s]`;
          case duration < SECONDS_IN.hour:
            return `m[m]`;
          case duration < SECONDS_IN.day:
            return `h[h]`;
          default:
            return `d[d]`;
        }
      },
    },
  };
}

export function formatSeconds(seconds, opts = {}) {
  let { format = 'default', ...customOptions } = opts;
  let { template, options } = getFormats(seconds)[format];

  return moment
    .duration(seconds, 'seconds')
    .format(template, Object.assign({}, options, customOptions));
}

export function formatMinutes(minutes) {
  return formatSeconds(minutes * 60, { format: 'default' });
}

export function formatRoundedTime(seconds) {
  return formatSeconds(seconds, { format: 'rounded-default' });
}

export function formatRangeRoundedTime(seconds) {
  return formatSeconds(seconds, { format: 'range-rounded-default' });
}
