/* RESPONSIBLE TEAM: team-phone */

// From: https://github.com/keithwhor/wavtools/blob/main/lib/wav_recorder.js

import { getFrequenciesFromAnalyser } from './get-frequencies-from-analyser';
import { WavPacker, type WavPackerAudioType } from './wav-packer';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

/**
 * Decodes audio into a wav file
 */
export type DecodedAudioType = {
  blob: Blob;
  url: string;
  values: Float32Array;
  audioBuffer: AudioBuffer;
};

/**
 * Records live stream of user audio as PCM16 "audio/wav" data
 */
export class WavRecorder {
  scriptSrc: string;
  sampleRate: number;
  audioDeviceSettings?: MediaTrackConstraints;
  outputToSpeakers: boolean;
  debug: boolean;
  private _deviceChangeCallback: (() => Promise<void>) | null;
  stream: MediaStream | null;
  processor: AudioWorkletNode | null;
  source: MediaStreamAudioSourceNode | null;
  node: AudioNode | null;
  recording: boolean;
  private _lastEventId: number;
  private eventReceipts: { [key: string]: any };
  eventTimeout: number;
  private _chunkProcessor: (data: { mono: Int16Array; raw: Int16Array }) => void;
  private _chunkProcessorSize: number | undefined;
  private _chunkProcessorBuffer: {
    raw: Int16Array;
    mono: Int16Array;
  };
  analyser: AnalyserNode | null;

  constructor({
    sampleRate = 44100,
    audioDeviceSettings,
    outputToSpeakers = false,
    debug = false,
  }: {
    sampleRate?: number;
    audioDeviceSettings?: MediaTrackConstraints;
    outputToSpeakers?: boolean;
    debug?: boolean;
  } = {}) {
    this.scriptSrc = assetUrl('/assets/worklets/audio_processor.js');
    this.sampleRate = sampleRate;
    this.audioDeviceSettings = audioDeviceSettings;
    this.outputToSpeakers = outputToSpeakers;
    this.debug = !!debug;
    this._deviceChangeCallback = null;
    this.stream = null;
    this.processor = null;
    this.source = null;
    this.node = null;
    this.recording = false;
    this._lastEventId = 0;
    this.eventReceipts = {};
    this.eventTimeout = 5000;
    this._chunkProcessor = () => {};
    this._chunkProcessorSize = undefined;
    this._chunkProcessorBuffer = {
      raw: new Int16Array(0),
      mono: new Int16Array(0),
    };
    this.analyser = null;
  }

  static async decode(
    audioData: Blob | Float32Array | Int16Array | ArrayBuffer | number[],
    sampleRate = 44100,
    fromSampleRate = -1,
  ): Promise<DecodedAudioType> {
    let context = new AudioContext({ sampleRate });
    let arrayBuffer: ArrayBuffer;
    let blob: Blob;
    let data: Int16Array | ArrayBuffer | undefined;

    if (audioData instanceof Blob) {
      if (fromSampleRate !== -1) {
        throw new Error(`Can not specify "fromSampleRate" when reading from Blob`);
      }
      blob = audioData;
      arrayBuffer = await blob.arrayBuffer();
    } else if (audioData instanceof ArrayBuffer) {
      if (fromSampleRate !== -1) {
        throw new Error(`Can not specify "fromSampleRate" when reading from ArrayBuffer`);
      }
      arrayBuffer = audioData;
      blob = new Blob([arrayBuffer], { type: 'audio/wav' });
    } else {
      let float32Array: Float32Array;

      if (audioData instanceof Int16Array) {
        data = audioData;
        float32Array = new Float32Array(audioData.length);
        for (let i = 0; i < audioData.length; i++) {
          float32Array[i] = audioData[i] / 0x8000;
        }
      } else if (audioData instanceof Float32Array) {
        float32Array = audioData;
      } else if (Array.isArray(audioData)) {
        float32Array = new Float32Array(audioData);
      } else {
        throw new Error(
          `"audioData" must be one of: Blob, Float32Array, Int16Array, ArrayBuffer, Array<number>`,
        );
      }

      if (fromSampleRate === -1) {
        throw new Error(
          `Must specify "fromSampleRate" when reading from Float32Array, Int16Array or Array`,
        );
      } else if (fromSampleRate < 3000) {
        throw new Error(`Minimum "fromSampleRate" is 3000 (3kHz)`);
      }

      if (!data) {
        data = WavPacker.floatTo16BitPCM(float32Array);
      }

      let audio = {
        bitsPerSample: 16,
        channels: [float32Array],
        data: data as Int16Array,
      };

      let packer = new WavPacker();
      let result = packer.pack(fromSampleRate, audio);
      blob = result.blob;
      arrayBuffer = await blob.arrayBuffer();
    }

    let audioBuffer = await context.decodeAudioData(arrayBuffer);
    let values = audioBuffer.getChannelData(0);
    let url = URL.createObjectURL(blob);

    return {
      blob,
      url,
      values,
      audioBuffer,
    };
  }

  log(...args: any[]): true {
    if (this.debug) {
      console.debug(...args);
    }
    return true;
  }

  getSampleRate(): number {
    return this.sampleRate;
  }

  getFrequencies(minDecibels = -100, maxDecibels = -30) {
    if (!this.processor || !this.analyser) {
      throw new Error('Session ended: please call .begin() first');
    }
    return getFrequenciesFromAnalyser(
      this.analyser,
      this.sampleRate,
      null,
      minDecibels,
      maxDecibels,
    );
  }

  getStatus(): 'ended' | 'paused' | 'recording' {
    if (!this.processor) {
      return 'ended';
    } else if (!this.recording) {
      return 'paused';
    } else {
      return 'recording';
    }
  }

  private async _event(
    name: string,
    data: { [key: string]: any } = {},
    _processor: AudioWorkletNode | null = null,
  ): Promise<{ [key: string]: any }> {
    _processor = _processor || this.processor;
    if (!_processor) {
      throw new Error('Can not send events without recording first');
    }

    let message = {
      event: name,
      id: this._lastEventId++,
      data,
    };

    _processor.port.postMessage(message);
    let t0 = new Date().valueOf();

    while (!this.eventReceipts[message.id]) {
      if (new Date().valueOf() - t0 > this.eventTimeout) {
        throw new Error(`Timeout waiting for "${name}" event`);
      }
      await new Promise((res) => setTimeout(() => res(true), 1));
    }

    let payload = this.eventReceipts[message.id];
    delete this.eventReceipts[message.id];
    return payload;
  }

  listenForDeviceChange(
    callback: ((devices: Array<MediaDeviceInfo & { default: boolean }>) => void) | null,
  ): true {
    if (callback === null && this._deviceChangeCallback) {
      navigator.mediaDevices.removeEventListener('devicechange', this._deviceChangeCallback);
      this._deviceChangeCallback = null;
    } else if (callback !== null) {
      let lastId = 0;
      let lastDevices: Array<MediaDeviceInfo & { default: boolean }> = [];

      let serializeDevices = (devices: Array<MediaDeviceInfo & { default: boolean }>) =>
        devices
          .map((d) => d.deviceId)
          .sort()
          .join(',');

      let cb = async () => {
        let id = ++lastId;
        let devices = await this.listDevices();
        if (id === lastId) {
          if (serializeDevices(lastDevices) !== serializeDevices(devices)) {
            lastDevices = devices;
            callback(devices.slice());
          }
        }
      };

      navigator.mediaDevices.addEventListener('devicechange', cb);
      cb();
      this._deviceChangeCallback = cb;
    }
    return true;
  }

  async requestPermission(): Promise<true> {
    let permissionStatus = await navigator.permissions.query({
      name: 'microphone' as PermissionName,
    });

    if (permissionStatus.state === 'denied') {
      console.error('You must grant microphone access to use this feature.');
    } else if (permissionStatus.state === 'prompt') {
      try {
        let mediaStreamConstraints: MediaStreamConstraints = {
          audio: true,
        };

        if (this.audioDeviceSettings) {
          mediaStreamConstraints = {
            audio: this.audioDeviceSettings,
          };
        }

        let stream = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints);
        let tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      } catch (e) {
        console.error('You must grant microphone access to use this feature.');
      }
    }
    return true;
  }

  async listDevices(): Promise<Array<MediaDeviceInfo & { default: boolean }>> {
    if (!navigator.mediaDevices || !('enumerateDevices' in navigator.mediaDevices)) {
      throw new Error('Could not request user devices');
    }

    await this.requestPermission();
    let devices = await navigator.mediaDevices.enumerateDevices();
    let audioDevices = devices.filter((device) => device.kind === 'audioinput');
    let defaultDeviceIndex = audioDevices.findIndex((device) => device.deviceId === 'default');
    let deviceList: Array<MediaDeviceInfo & { default: boolean }> = [];

    if (defaultDeviceIndex !== -1) {
      let defaultDevice = audioDevices.splice(defaultDeviceIndex, 1)[0] as MediaDeviceInfo & {
        default: boolean;
      };
      let existingIndex = audioDevices.findIndex(
        (device) => device.groupId === defaultDevice.groupId,
      );

      if (existingIndex !== -1) {
        defaultDevice = audioDevices.splice(existingIndex, 1)[0] as MediaDeviceInfo & {
          default: boolean;
        };
      }

      defaultDevice.default = true;
      deviceList.push(defaultDevice);
    }

    return deviceList.concat(audioDevices.map((d) => ({ ...d, default: false })));
  }

  async begin(deviceId?: string): Promise<true> {
    if (this.processor) {
      throw new Error(`Already connected: please call .end() to start a new session`);
    }

    if (!navigator.mediaDevices || !('getUserMedia' in navigator.mediaDevices)) {
      throw new Error('Could not request user media');
    }

    try {
      let config: MediaStreamConstraints = {
        audio: deviceId ? { deviceId: { exact: deviceId } } : true,
      };
      this.stream = await navigator.mediaDevices.getUserMedia(config);
    } catch (err) {
      throw new Error('Could not start media stream');
    }

    let context = new AudioContext({ sampleRate: this.sampleRate });
    let source = context.createMediaStreamSource(this.stream);

    try {
      await context.audioWorklet.addModule(this.scriptSrc);
    } catch (e) {
      console.error(e);
      throw new Error(`Could not add audioWorklet module: ${this.scriptSrc}`);
    }

    let processor = new AudioWorkletNode(context, 'audio_processor');
    processor.port.onmessage = (e: MessageEvent) => {
      let { event, id, data } = e.data;
      if (event === 'receipt') {
        this.eventReceipts[id] = data;
      } else if (event === 'chunk') {
        if (this._chunkProcessorSize) {
          let buffer = this._chunkProcessorBuffer;
          this._chunkProcessorBuffer = {
            raw: WavPacker.mergeBuffers(buffer.raw, data.raw) as Int16Array,
            mono: WavPacker.mergeBuffers(buffer.mono, data.mono) as Int16Array,
          };
          if (this._chunkProcessorBuffer.mono.byteLength >= this._chunkProcessorSize) {
            this._chunkProcessor(this._chunkProcessorBuffer);
            this._chunkProcessorBuffer = {
              raw: new Int16Array(0),
              mono: new Int16Array(0),
            };
          }
        } else {
          this._chunkProcessor(data);
        }
      }
    };

    let node = source.connect(processor);
    let analyser = context.createAnalyser();
    analyser.fftSize = 8192;
    analyser.smoothingTimeConstant = 0.1;
    node.connect(analyser);

    if (this.outputToSpeakers) {
      console.warn(
        'Warning: Output to speakers may affect sound quality,\n' +
          'especially due to system audio feedback preventative measures.\n' +
          'use only for debugging',
      );
      analyser.connect(context.destination);
    }

    this.source = source;
    this.node = node;
    this.analyser = analyser;
    this.processor = processor;
    return true;
  }

  async pause(): Promise<true> {
    if (!this.processor) {
      throw new Error('Session ended: please call .begin() first');
    } else if (!this.recording) {
      throw new Error('Already paused: please call .record() first');
    }

    if (this._chunkProcessorBuffer.raw.byteLength) {
      this._chunkProcessor(this._chunkProcessorBuffer);
    }

    this.log('Pausing ...');
    await this._event('stop');
    this.recording = false;
    return true;
  }

  async record(
    chunkProcessor: (data: { mono: Int16Array; raw: Int16Array }) => any = () => {},
    chunkSize = 8192,
  ): Promise<true> {
    if (!this.processor) {
      throw new Error('Session ended: please call .begin() first');
    } else if (this.recording) {
      throw new Error('Already recording: please call .pause() first');
    } else if (typeof chunkProcessor !== 'function') {
      throw new Error(`chunkProcessor must be a function`);
    }

    this._chunkProcessor = chunkProcessor;
    this._chunkProcessorSize = chunkSize;
    this._chunkProcessorBuffer = {
      raw: new Int16Array(0),
      mono: new Int16Array(0),
    };

    this.log('Recording ...');
    await this._event('start');
    this.recording = true;
    return true;
  }

  async clear(): Promise<true> {
    if (!this.processor) {
      throw new Error('Session ended: please call .begin() first');
    }
    await this._event('clear');
    return true;
  }

  async read(): Promise<{ meanValues: Float32Array; channels: Array<Float32Array> }> {
    if (!this.processor) {
      throw new Error('Session ended: please call .begin() first');
    }
    this.log('Reading ...');
    return (await this._event('read')) as {
      meanValues: Float32Array;
      channels: Array<Float32Array>;
    };
  }

  async save(force = false): Promise<WavPackerAudioType> {
    if (!this.processor) {
      throw new Error('Session ended: please call .begin() first');
    }
    if (!force && this.recording) {
      throw new Error(
        'Currently recording: please call .pause() first, or call .save(true) to force',
      );
    }

    this.log('Exporting ...');
    let exportData = await this._event('export');
    let packer = new WavPacker();
    return packer.pack(this.sampleRate, exportData.audio);
  }

  async end(): Promise<WavPackerAudioType> {
    if (!this.processor) {
      throw new Error('Session ended: please call .begin() first');
    }

    let _processor = this.processor;

    this.log('Stopping ...');
    await this._event('stop');
    this.recording = false;

    let tracks = this.stream!.getTracks();
    tracks.forEach((track) => track.stop());

    this.log('Exporting ...');
    let exportData = await this._event('export', {}, _processor);

    this.processor?.disconnect();
    this.source?.disconnect();
    this.node?.disconnect();
    this.analyser?.disconnect();

    this.stream = null;
    this.processor = null;
    this.source = null;
    this.node = null;
    this.analyser = null;

    let packer = new WavPacker();
    return packer.pack(this.sampleRate, exportData.audio);
  }

  async quit(): Promise<true> {
    this.listenForDeviceChange(null);
    if (this.processor) {
      await this.end();
    }
    return true;
  }
}
