/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { TrackedArray } from 'tracked-built-ins';
import { tracked } from '@glimmer/tracking';

export type PlayGroundSettingsWireFormat = KeysToSnakeCase<PlaygroundSettings> & {
  fin_playground_group_id: string;
};

export class PlaygroundSettings {
  @tracked selectedContentSegmentIds = new TrackedArray<string>([]);
  private initialSelectedContentSegmentIds: Set<string>;
  @tracked brandId?: string | null;
  private initialBrandId?: string | null;
  groupId?: number;
  @tracked userId?: string | null;
  private initialUserId?: string | null;
  constructor(
    selectedContentSegmentIds: string[],
    brandId?: string | null,
    groupId?: number,
    userId?: string | null,
  ) {
    this.selectedContentSegmentIds = new TrackedArray(selectedContentSegmentIds);
    this.initialSelectedContentSegmentIds = new Set(selectedContentSegmentIds);
    this.brandId = brandId;
    this.initialBrandId = brandId;
    this.groupId = groupId;
    this.userId = userId;
  }

  static deserialize(data: PlayGroundSettingsWireFormat): PlaygroundSettings {
    let selectedContentSegmentIds = data?.selected_content_segment_ids.map((id) => id.toString());
    let brandId = data?.brand_id?.toString();
    let groupID = Number(data?.fin_playground_group_id);
    let userId = data?.user_id?.toString();
    return new PlaygroundSettings(selectedContentSegmentIds ?? [], brandId, groupID, userId);
  }

  addOrRemoveClickedSegment(segmentId: string) {
    if (this.selectedContentSegmentIds.includes(segmentId)) {
      // Remove the segment if it is already in the list
      this.selectedContentSegmentIds = new TrackedArray(
        this.selectedContentSegmentIds.filter((id) => id !== segmentId),
      );
    } else {
      // Add the segment if it is not in the list
      this.selectedContentSegmentIds = new TrackedArray([
        ...this.selectedContentSegmentIds,
        segmentId,
      ]);
    }
  }

  hasChanged(): boolean {
    let currentSet = new Set(this.selectedContentSegmentIds);
    if (currentSet.size !== this.initialSelectedContentSegmentIds.size) {
      return true;
    }
    let currentBrandId = this.brandId;
    if (currentBrandId !== this.initialBrandId) {
      return true;
    }
    let currentUserId = this.userId;
    if (currentUserId !== this.initialUserId) {
      return true;
    }
    return this.selectedContentSegmentIds.some(
      (id) => !this.initialSelectedContentSegmentIds.has(id),
    );
  }

  rollbackChanges() {
    this.selectedContentSegmentIds = new TrackedArray([...this.initialSelectedContentSegmentIds]);
    this.brandId = this.initialBrandId;
    this.userId = this.initialUserId;
  }

  refreshTrackedState() {
    this.initialSelectedContentSegmentIds = new Set(this.selectedContentSegmentIds);
    this.initialBrandId = this.brandId;
    this.initialUserId = this.userId;
  }

  removeAllSegments() {
    this.selectedContentSegmentIds = new TrackedArray([]);
  }
}
