/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { bind } from '@ember/runloop';
import { shutdownIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import storage from 'embercom/vendor/intercom/storage';

export function initialize(application) {
  $('body').on(
    'click.sign-out',
    'a[href*="/admins/sign_out"]',
    bind(this, () => {
      shutdownIntercomWidget();

      let modelDataCacheService = application.lookup('service:modelDataCacheService');
      modelDataCacheService.clear();

      let inbox2CoreDataService = application.lookup('service:inbox2CoreData');
      inbox2CoreDataService.clear();

      clearSensitiveLocalStorage();
    }),
  );

  application.reopen({
    willDestroy() {
      $('body').off('click.sign-out');
      return this._super(...arguments);
    },
  });
}

function clearSensitiveLocalStorage() {
  let regex =
    /^conversation-pane-draft|^new-conversation-draft|^new-conversation-latest-channel|^latest-updated-recipients/;
  for (let item in window.localStorage) {
    if (item.match(regex)) {
      storage.remove(item);
    }
  }
}

export default {
  name: 'logout-link-handler',
  initialize,
};
