/* RESPONSIBLE TEAM: team-frontend-tech */

import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import emoji from 'embercom/lib/emoji';
import { V1View } from '@intercom/interblocks.ts';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { appendResizeParamToImageUrl } from './append-resize-param-to-image-url';
import { type BlockList } from '@intercom/interblocks.ts';
import type Workspace from 'embercom/objects/workspace';
import type ExperimentsApi from 'embercom/services/experiments-api';
import type Session from 'embercom/services/session';
import type RegionService from 'embercom/services/region-service';
import { addExternalLinkShield } from './render-html';
import type Inbox2TrustedDomainsForInboundEmailLinks from 'embercom/services/inbox2-trusted-domains-for-inbound-email-links';
import { EntityType } from 'embercom/models/data/entity-types';
import ENV from 'embercom/config/environment';
import { translateBlocks } from 'embercom/components/inbox2/conversation-stream/helpers/translations';
// 516px is the max width of the convo part set in css
const MAX_IMAGE_WIDTH_CONVERSATION_STREAM = 516;
export const INBOX_MODAL_CLASSNAME = 'js-inbox2-modal-opener';

function emojifyBlocks(blocks: BlockList) {
  return (
    blocks &&
    blocks.map((block) => {
      let blockCopy = Object.assign({}, block);
      if (blockCopy.type !== 'button' && 'text' in blockCopy) {
        blockCopy.text = emoji.emojify(blockCopy.text);
      }
      return blockCopy;
    })
  );
}

export function updateImageSourceAndDimensions(image: HTMLImageElement) {
  let resizeWidth = MAX_IMAGE_WIDTH_CONVERSATION_STREAM;

  if (window.devicePixelRatio > 1) {
    resizeWidth = Math.round(resizeWidth * window.devicePixelRatio);
  }

  if (Number(image.width) > resizeWidth) {
    let updatedSrc = appendResizeParamToImageUrl(image.src, `${resizeWidth}x`);
    if (updatedSrc) {
      image.setAttribute('src', updatedSrc);
    }

    let originalImageWidth = image.width;
    let originalImageHeight = image.height;

    if (!Number.isNaN(originalImageWidth) && !Number.isNaN(originalImageHeight)) {
      let aspectRatio = originalImageHeight / originalImageWidth;
      let newHeight = aspectRatio * resizeWidth;
      image.setAttribute('height', Math.round(newHeight).toString());
      image.setAttribute('width', resizeWidth.toString());
    }
  }

  return image;
}

export function highlightText(node: HTMLElement, wordsToHighlight: string[]) {
  if (wordsToHighlight.length === 0) {
    return;
  }

  let wordsToHighlightSet = new Set(wordsToHighlight);
  let walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null);
  let n: Text | null;

  while ((n = walker.nextNode() as Text)) {
    if (n.textContent) {
      let tokens = n.textContent?.split(/(_|\b)/);
      let newNode = document.createDocumentFragment();
      let text = '';
      let hasHighlights = false;

      tokens.forEach((token) => {
        if (wordsToHighlightSet.has(token)) {
          hasHighlights = true;
          if (text.length > 0) {
            newNode.appendChild(document.createTextNode(text));
            text = '';
          }

          let mark = document.createElement('mark');
          mark.textContent = token;
          newNode.appendChild(mark);
        } else {
          text += token;
        }
      });

      if (text.length > 0) {
        newNode.appendChild(document.createTextNode(text));
      }

      if (hasHighlights) {
        n.replaceWith(newNode);
      }
    }
  }
}

export function applyInbox2BlocksAdjustments(
  node: HTMLElement,
  workspace: Workspace,
  options: {
    resizeImage?: boolean;
    highlight?: string[];
    mentionStyling?: boolean;
    showImageInPreviewBasedOnLinkType?: boolean;
  } = {},
) {
  let images = node.getElementsByTagName('img');

  Array.from(images).forEach((image) => {
    image.setAttribute('loading', 'lazy');

    // Images uploaded by admins are hosted on intercomcdn.com. We want to show the modal only for images that are either hosted on intercomcdn.com or if the image has no links
    if (options.showImageInPreviewBasedOnLinkType) {
      let addImagePreviewClass = imageHasNoLinkOrHostedOnIntercom(image);
      if (addImagePreviewClass) {
        image.classList.add('inbox2__modal-opener', INBOX_MODAL_CLASSNAME);
      }
    } else {
      image.classList.add('inbox2__modal-opener', INBOX_MODAL_CLASSNAME);
    }

    if (options.resizeImage) {
      updateImageSourceAndDimensions(image);
    }
  });

  let mentions = node.getElementsByClassName('entity_mention');
  Array.from(mentions).forEach((mention) => {
    let href = mention.getAttribute('href');
    if (href) {
      let isTeam = href.includes('team');
      let mentionedId = href.split('/').pop();
      if (isTeam) {
        mention.setAttribute(
          'href',
          `/a/inbox/${workspace.id}/inbox/team/${mentionedId}?view=List`,
        );
      } else {
        mention.setAttribute('href', `/a/apps/${workspace.id}/admins/${mentionedId}`);
      }
    }
    if (options.mentionStyling) {
      mention.textContent = `@${mention.textContent}`;
    }
  });

  if (options.highlight?.length) {
    highlightText(node, options.highlight);
  }
}

export function isIntercomCdnLink(url: string) {
  let regex = /^(.*\.)?intercomcdn\.com$/;
  if (ENV.environment === 'development' || ENV.environment === 'test') {
    regex = /^uploads-development\.s3\.test$/;
  }
  let isIntercomCdn = regex.test(url);
  return isIntercomCdn;
}

// Checks if image has no link or is hosted on intercomcdn.com
export function imageHasNoLinkOrHostedOnIntercom(node: HTMLImageElement) {
  let link = node.closest('a');
  if (!link) {
    return true;
  }

  try {
    let url = new URL(link.href);
    // Check if the domain name is a subdomain of intercomcdn.com or intercomcdn.com itself
    let isIntercomCdn = isIntercomCdnLink(url.hostname);
    return isIntercomCdn;
  } catch (e) {
    return false;
  }
}

export function renderBlocksV1(
  blocks: BlockList,
  templated = false,
  attributeInfoResolver: AttributeInfoResolver,
  renderAnchors = true,
  renderDividers = false,
  renderVideo = false,
) {
  let node = document.createElement('div');
  node.classList.add('intercom-interblocks');
  let view = new V1View(
    node,
    templated,
    attributeInfoResolver,
    renderAnchors,
    renderDividers,
    renderVideo,
  );
  try {
    view.render(emojifyBlocks(blocks), { skipStyleValidation: true });
  } catch (e) {
    console.error(e);
  }

  return node;
}

interface TeammateLinkDomainPolicy {
  id: number;
  policy_type: 'domain' | 'url';
  policy_action: 'trusted' | 'blocked';
  item: string;
}

export default class RenderBlocksHelper extends Helper {
  @service declare appService: any;
  @service declare session: Session;
  @service declare experimentsApi: ExperimentsApi;
  @service declare regionService: RegionService;
  @service
  declare inbox2TrustedDomainsForInboundEmailLinks: Inbox2TrustedDomainsForInboundEmailLinks;

  compute(
    [blocks]: [BlockList],
    {
      templated = false,
      inbox2 = false,
      resolver,
      renderAnchors = true,
      renderDivider = false,
      highlight = [],
      mentionStyling = false,
      renderVideo = false,
      externalLinkShield = false,
      externalDomains = [],
      blockedDomainsAndUrls = [],
      blockedDomainsAndUrlsEnabled = false,
      maliciousLinks = [],
      maliciousLinkBlockingEnabled = false,
      trustedDomainsAndUrls = [],
      partCreatedBy,
      appId,
      toTranslate,
    }: {
      templated?: boolean;
      inbox2?: boolean;
      resolver?: any;
      renderAnchors?: boolean;
      renderDivider?: boolean;
      highlight?: string[];
      mentionStyling?: boolean;
      renderVideo?: boolean;
      externalLinkShield?: boolean;
      externalDomains?: string[];
      blockedDomainsAndUrls?: TeammateLinkDomainPolicy[];
      blockedDomainsAndUrlsEnabled?: boolean;
      maliciousLinks?: string[];
      maliciousLinkBlockingEnabled?: boolean;
      trustedDomainsAndUrls?: TeammateLinkDomainPolicy[];
      partCreatedBy?: number;
      appId?: string;
      toTranslate?: boolean;
    },
  ) {
    if (templated) {
      let app = this.appService.app;
      resolver ||= new AttributeInfoResolver({ attributes: app?.allowedAttributes });
    }

    if (inbox2) {
      blocks = translateBlocks(blocks, !!toTranslate);
    }

    let node = renderBlocksV1(
      blocks,
      templated,
      resolver,
      renderAnchors,
      renderDivider,
      renderVideo,
    );
    let addTooltipToLinks = false;

    if (partCreatedBy) {
      if (
        this.session.workspace.isFeatureEnabled('enable-trusted-domains-for-all-user-channels') &&
        partCreatedBy === EntityType.User
      ) {
        addTooltipToLinks = true;
      }

      if (
        this.session.workspace.isFeatureEnabled('enable-trusted-domains-for-admin-sent-messages') &&
        partCreatedBy === EntityType.Admin
      ) {
        addTooltipToLinks = true;
      }

      if (
        this.session.workspace.isFeatureEnabled(
          'enable-trusted-domains-for-bot-and-workflow-sent-messages',
        ) &&
        (partCreatedBy === EntityType.Bot || partCreatedBy === EntityType.Workflow)
      ) {
        addTooltipToLinks = true;
      }
    }

    if (inbox2) {
      applyInbox2BlocksAdjustments(node, this.session.workspace, {
        resizeImage: true,
        highlight,
        mentionStyling: !!mentionStyling,
        showImageInPreviewBasedOnLinkType: addTooltipToLinks,
      });
    }

    if (addTooltipToLinks) {
      let trustedDomains = this.inbox2TrustedDomainsForInboundEmailLinks.trustedDomains
        .concat(externalDomains)
        .map((url: string) => url.replace('https://', ''));
      addExternalLinkShield(
        node,
        externalLinkShield,
        maliciousLinks,
        maliciousLinkBlockingEnabled,
        trustedDomains,
        trustedDomainsAndUrls,
        blockedDomainsAndUrls,
        blockedDomainsAndUrlsEnabled,
        appId,
      );
    }
    return node;
  }
}
