/* RESPONSIBLE TEAM: team-channels */
import Helper from '@ember/component/helper';

export function firstName(fullName: string): string {
  if (!fullName) {
    return '';
  }
  return fullName.trim().split(/\s+/)[0];
}

export class FirstNameHelper extends Helper {
  compute([fullName]: [string]): string {
    return firstName(fullName);
  }
}

export default FirstNameHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'first-name': typeof FirstNameHelper;
  }
}
