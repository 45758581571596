/* RESPONSIBLE TEAM: team-phone */

// From: https://github.com/keithwhor/wavtools/blob/main/lib/analysis/constants.js

/**
 * Constants for help with visualization
 * Helps map frequency ranges from Fast Fourier Transform
 * to human-interpretable ranges, notably music ranges and
 * human vocal ranges.
 */

// Eighth octave frequencies
const octave8Frequencies = [
  4186.01, 4434.92, 4698.63, 4978.03, 5274.04, 5587.65, 5919.91, 6271.93, 6644.88, 7040.0, 7458.62,
  7902.13,
];

/**
 * Voice frequency range (C1 to B6)
 * Frequencies between 32 and 2000 Hz
 */
const voiceFrequencyRange = [32.0, 2000.0];

const voiceFrequencies: number[] = [];
for (let i = 1; i <= 8; i++) {
  for (let f = 0; f < octave8Frequencies.length; f++) {
    let freq = octave8Frequencies[f] / Math.pow(2, 8 - i);
    if (freq > voiceFrequencyRange[0] && freq < voiceFrequencyRange[1]) {
      voiceFrequencies.push(freq);
    }
  }
}

// From: https://github.com/keithwhor/wavtools/blob/main/lib/analysis/audio_analysis.js

export function getFrequenciesFromAnalyser(
  analyser: AnalyserNode,
  sampleRate: number,
  fftResult: Float32Array | null,
  minDecibels = -100,
  maxDecibels = -30,
) {
  if (!fftResult) {
    fftResult = new Float32Array(analyser.frequencyBinCount);
    analyser.getFloatFrequencyData(fftResult);
  }

  let frequencyStep = (1 / fftResult.length) * (sampleRate / 2);
  let frequencies = voiceFrequencies;
  let outputValues = Array(frequencies.length).fill(minDecibels);

  for (let i = 0; i < fftResult.length; i++) {
    let frequency = i * frequencyStep;
    let amplitude = fftResult[i];
    for (let n = frequencies.length - 1; n >= 0; n--) {
      if (frequency > frequencies[n]) {
        outputValues[n] = Math.max(outputValues[n], amplitude);
        break;
      }
    }
  }

  // We normalize to {0, 1}
  let normalizedOutput = outputValues.map((v) => {
    return Math.max(0, Math.min((v - minDecibels) / (maxDecibels - minDecibels), 1));
  });
  let values = new Float32Array(normalizedOutput);
  return {
    values,
    frequencies,
  };
}
