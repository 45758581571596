/* RESPONSIBLE TEAM: team-knowledge-foundations */
export const RUN_STATUSES = {
  pending: 'pending',
  started: 'started',
  completed: 'completed',
  failed: 'failed',
  ingested: 'ingested',
};

export const CONTENT_CREATION_RUN_STATUSES = [
  RUN_STATUSES.pending,
  RUN_STATUSES.started,
  RUN_STATUSES.completed,
];

export const CONTENT_TYPE = {
  articles: 'articles',
  external_content: 'external_content',
};
