/* RESPONSIBLE TEAM: team-phone */

// From: https://github.com/keithwhor/wavtools/blob/main/lib/wav_stream_player.js

import { assetUrl } from '@intercom/pulse/helpers/asset-url';

type TrackSampleOffset = {
  trackId: string | null;
  offset: number;
  currentTime: number;
};

/**
 * Plays audio streams received in raw PCM16 chunks from the browser
 */
export class WavStreamPlayer {
  scriptSrc: string;
  sampleRate: number;
  context: AudioContext | null;
  stream: AudioWorkletNode | null;
  analyser: AnalyserNode | null;
  trackSampleOffsets: Record<string, TrackSampleOffset>;
  interruptedTrackIds: Record<string, boolean>;
  trackCallbacks: Record<string, (trackId: string) => void> | null;

  /**
   * Creates a new WavStreamPlayer instance
   */
  constructor({ sampleRate = 44100 } = {}) {
    this.scriptSrc = assetUrl('/assets/worklets/stream_processor.js');
    this.sampleRate = sampleRate;
    this.context = null;
    this.stream = null;
    this.analyser = null;
    this.trackSampleOffsets = {};
    this.interruptedTrackIds = {};
    this.trackCallbacks = null;
  }

  /**
   * Connects the audio context and enables output to speakers
   */
  async connect(): Promise<true> {
    this.context = new AudioContext({ sampleRate: this.sampleRate });
    if (this.context.state === 'suspended') {
      await this.context.resume();
    }

    // Load from public assets
    await this.context.audioWorklet.addModule(this.scriptSrc);

    let analyser = this.context.createAnalyser();
    analyser.fftSize = 8192;
    analyser.smoothingTimeConstant = 0.1;
    this.analyser = analyser;
    return true;
  }

  /**
   * Starts audio streaming
   * @private
   */
  private _start(): true {
    if (!this.context) {
      throw new Error('Not connected, please call .connect() first');
    }

    let streamNode = new AudioWorkletNode(this.context, 'stream_processor');
    streamNode.connect(this.context.destination);
    streamNode.port.onmessage = (e: MessageEvent) => {
      let { event } = e.data;
      if (event === 'stop') {
        streamNode?.disconnect();
        this.stream = null;
      } else if (event === 'offset') {
        let { requestId, trackId, offset } = e.data;
        let currentTime = offset / this.sampleRate;
        this.trackSampleOffsets[requestId] = { trackId, offset, currentTime };
      } else if (event === 'complete') {
        let { trackId } = e.data;
        if (this.trackCallbacks?.[trackId]) {
          this.trackCallbacks[trackId](trackId);
          delete this.trackCallbacks[trackId];
        }
      }
    };

    if (this.analyser) {
      this.analyser.disconnect();
      streamNode.connect(this.analyser);
    }

    this.stream = streamNode;
    return true;
  }

  /**
   * Adds 16BitPCM data to the currently playing audio stream
   * You can add chunks beyond the current play point and they will be queued for play
   */
  add16BitPCM(
    arrayBuffer: ArrayBuffer | Int16Array,
    trackId = 'default',
    onTrackComplete?: (trackId: string) => void,
  ): Int16Array | void {
    if (typeof trackId !== 'string') {
      throw new Error('trackId must be a string');
    } else if (this.interruptedTrackIds[trackId]) {
      return;
    }

    if (!this.stream) {
      this._start();
    }

    let buffer: Int16Array;
    if (arrayBuffer instanceof Int16Array) {
      buffer = arrayBuffer;
    } else if (arrayBuffer instanceof ArrayBuffer) {
      buffer = new Int16Array(arrayBuffer);
    } else {
      throw new Error('argument must be Int16Array or ArrayBuffer');
    }

    this.stream!.port.postMessage({
      event: 'write',
      buffer,
      trackId,
      hasCallback: !!onTrackComplete,
    });

    if (onTrackComplete) {
      this.trackCallbacks = this.trackCallbacks || {};
      this.trackCallbacks[trackId] = onTrackComplete;
    }

    return buffer;
  }

  /**
   * Gets the offset (sample count) of the currently playing stream
   */
  async getTrackSampleOffset(interrupt = false): Promise<TrackSampleOffset | null> {
    if (!this.stream) {
      return null;
    }

    let requestId = crypto.randomUUID();
    this.stream.port.postMessage({
      event: interrupt ? 'interrupt' : 'offset',
      requestId,
    });

    let trackSampleOffset: TrackSampleOffset | undefined;
    while (!trackSampleOffset) {
      trackSampleOffset = this.trackSampleOffsets[requestId];
      await new Promise((r) => setTimeout(r, 1));
    }

    let { trackId } = trackSampleOffset;
    if (interrupt && trackId) {
      this.interruptedTrackIds[trackId] = true;
      if (this.trackCallbacks?.[trackId]) {
        delete this.trackCallbacks[trackId];
      }
    }

    return trackSampleOffset;
  }

  async end(): Promise<void> {
    if (this.stream) {
      this.stream.disconnect();
      this.stream.port.onmessage = null;
      this.stream = null;
    }

    if (this.analyser) {
      this.analyser.disconnect();
      this.analyser = null;
    }

    if (this.context) {
      await this.context.close();
      this.context = null;
    }

    this.trackSampleOffsets = {};
    this.interruptedTrackIds = {};
    this.trackCallbacks = null;
  }

  /**
   * Strips the current stream and returns the sample offset of the audio
   */
  async interrupt(): Promise<TrackSampleOffset | null> {
    return this.getTrackSampleOffset(true);
  }
}
