/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Helper from '@ember/component/helper';
import { getArticleFromDistribution } from 'embercom/lib/articles/article-distribution';

// Helper method gets back the default article from the distribution system and gives its articleLink.
export { getArticleFromDistribution };

export default class GetArticleLinkFromDistribution extends Helper<{
  Args: {
    Positional: [string, $TSFixMe?, $TSFixMe?];
  };
  Return: string;
}> {
  compute([...params]: [string, $TSFixMe?, $TSFixMe?]) {
    return getArticleFromDistribution(...params)?.articleLink ?? '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'get-article-link-from-distribution': typeof GetArticleLinkFromDistribution;
  }
}
