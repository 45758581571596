/* RESPONSIBLE TEAM: team-reporting */
import Time from './time';
import Seconds from './seconds';
import Value from './value';
import ValueSingle from './value-single';
import Percent from './percent';
import Range from './range';

export default class Formatters {
  static minute = Time;
  static seconds = Seconds;
  static value = Value;
  static valueSingle = ValueSingle;
  static percent = Percent;
  static valuePerHour = Value;
  static range = Range;
}

export const units = {
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  seconds: 'seconds',
  value: 'value',
  valueSingle: 'valueSingle',
  percent: 'percent',
  valuePerHour: 'valuePerHour',
};
