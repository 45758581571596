/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import numberFormatter from 'embercom/lib/number-formatter';
import numericFormatter from 'embercom/lib/numeric-formatter';
import { isPresent } from '@ember/utils';
import { type Tier, TierPriceFormat, type Block } from 'embercom/models/data/pricing/tier-types';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type IntlService from 'embercom/services/intl';

// For VBP 2.0 (except Starter) we want to split out the base fee from the
// plan_starting_price (which includes mandatory minimum usage price)
const PRICING_METRICS_WITH_POTENTIAL_ZERO_USAGE_BASE_FEE = [
  Metric.support_seat_count,
  Metric.engage_seat_count,
  Metric.marketing_seat_count,
  Metric.proactive_support_seat_count,
  Metric.contracted_solution_seat_count,
  Metric.inbox_seats,
];

export enum ChargeModel {
  PerUnit = 'PerUnit',
  Tiered = 'Tiered', // eslint-disable-line @intercom/intercom/no-bare-strings
}

export default class PricingMetric {
  metric: Metric;
  usage;
  tiers: Tier[];
  currentTier;
  blockSize;
  price;
  perUnitPrice;
  planLimit;
  chargeModel: ChargeModel;
  blocks?: Array<Block>;
  chargeBasePrice;
  intl?: IntlService;

  constructor(
    {
      pricing_metric,
      actual_usage,
      tiers,
      current_tier,
      block_size,
      price,
      per_unit_price,
      plan_limit,
      charge_model,
      base_price,
    }: any,
    intl?: IntlService,
  ) {
    this.metric = pricing_metric;
    this.usage = actual_usage;
    this.blockSize = block_size;
    this.price = price;
    this.perUnitPrice = per_unit_price;
    this.planLimit = plan_limit;
    this.chargeModel = charge_model;
    this.tiers = tiers;
    this.currentTier = current_tier;
    this.chargeBasePrice = base_price;
    this.intl = intl;
  }

  get additionalPrice() {
    return this.price - this.basePrice;
  }

  get usageAboveMinimumPrice() {
    return this.price - this.minimumPrice;
  }

  get usesBillingCycleMetric() {
    return [
      Metric.thirty_day_messaged_contacts,
      Metric.thirty_day_messaged_contacts_product_tours,
      Metric.monthly_surveys_sent,
      Metric.monthly_calls_deflected,
      Metric.sms_segments_sent_received_monthly_us,
      Metric.sms_segments_sent_received_monthly_uk,
      Metric.sms_segments_sent_received_monthly_ca,
      Metric.sms_segments_sent_received_monthly_au,
      Metric.resolutions,
      Metric.resolutions_with_custom_answers,
      Metric.whatsapp_inbound,
      Metric.whatsapp_outbound,
      Metric.emails_sent,
      Metric.calling,
    ].includes(this.metric);
  }

  get formattedUsageAboveMinimumPrice() {
    if (['latest_daily_admin_count', 'inbox_seats'].includes(this.metric)) {
      return this.intl?.t('billing.pricing-metric.usage-per-month', {
        usageAboveMinimum: this.usageAboveMinimum,
        perUnitPrice: numberFormatter(this.perUnitPrice / 100),
      });
    }

    return `$${numericFormatter(this.usageAboveMinimumPrice / 100, 2, true)}`;
  }

  get formattedTotalPrice() {
    return `$${numericFormatter(this.price / 100, 2, true)}`;
  }

  // for VBP 1, basePrice is the plan starting price including mandatory minimum usage price (if present)
  // for VBP 2 (except VBP 2.0 Starter), basePrice is the plan base fee excluding any mandatory minimum
  // usage price
  get basePrice() {
    if (this.metric === 'fixed') {
      // fixed metrics don't have a base usage
      return this.price;
    }
    if (this.tiers?.length >= 2) {
      if (
        this.tiers[0].price_format === TierPriceFormat.FlatFee &&
        (this.tiers[1].price_format === TierPriceFormat.PerUnit ||
          this.metric === Metric.monthly_surveys_sent ||
          this.metric === Metric.monthly_calls_deflected)
      ) {
        let baseTierPrice = this.tiers.firstObject!.price * 100;
        if (this.potentialZeroBase) {
          // Since the base price is based on the first tier price, we should minus the base ending unit * the per unit price
          // This will cater for any new pricing strategies in billing that have minimum plan limits above Zuora's base maximum
          baseTierPrice -= this.perUnitPrice * this.tiers.firstObject!.ending_unit;
        }
        return baseTierPrice;
      } else {
        return this.chargeBasePrice || 0;
      }
    }
    if (this.chargeModel === 'PerUnit' && !this.potentialZeroBase) {
      return this.perUnitPrice * this.minimumUsage;
    }
    return 0;
  }

  get potentialZeroBase() {
    return PRICING_METRICS_WITH_POTENTIAL_ZERO_USAGE_BASE_FEE.includes(this.metric);
  }

  get minimumPrice() {
    if (this.tiers?.firstObject?.price_format === TierPriceFormat.FlatFee) {
      return this.tiers.firstObject.price * 100;
    }

    return this.minimumUsage * this.perUnitPrice;
  }

  get minimumUsage(): number {
    if (this.planLimit?.minimum) {
      return this.planLimit.minimum;
    }

    if (
      this.tiers?.firstObject?.price_format === TierPriceFormat.FlatFee &&
      this.tiers?.firstObject?.ending_unit
    ) {
      return this.tiers.firstObject?.ending_unit;
    }

    return 0;
  }

  // baseUsage is the usage that is included for free in the plan starting price
  // or the minimum mandatory usage that needs to be purchased with the plan
  get baseUsage() {
    // for VBP 2.0 and VBP 2 Early Stage (except VBP 2.0 Starter) we specifically define a zero usage base fee for
    // some plans and want to exclude any mandatory usage price from the plan starting price
    if (this.potentialZeroBase) {
      return 0;
    }
    return this.minimumUsage;
  }

  get usageAboveMinimum() {
    return Math.max(this.usage - this.minimumUsage, 0);
  }

  get formattedUsage() {
    let usage = this.usage || 0;
    return this.intl?.formatNumber(usage);
  }

  get formattedMinimumUsage() {
    return numberFormatter(this.minimumUsage);
  }

  get formattedBaseUsage() {
    return numberFormatter(this.baseUsage);
  }

  get formattedUsageAboveMinimum() {
    return numberFormatter(this.usageAboveMinimum);
  }

  get usageInfo() {
    return `${this.formattedUsage} ${this.pluralize(this.usage)}`;
  }

  get minimumUsageInfo() {
    return this.intl?.t('billing.pricing-metric.minimum-usage-info', {
      formattedMinimumUsage: this.formattedMinimumUsage,
      minimumUsage: this.pluralize(this.minimumUsage),
    });
  }

  get baseUsageInfo() {
    return this.intl?.t('billing.pricing-metric.base-usage-info', {
      formattedBaseUsage: this.formattedBaseUsage,
      baseUsage: this.pluralize(this.baseUsage),
    });
  }

  get usageAboveMinimumInfo() {
    return this.intl?.t('billing.pricing-metric.above-minimum-info', {
      formattedAboveMinimum: this.formattedUsageAboveMinimum,
      usageAboveMinimum: this.pluralize(this.usageAboveMinimum),
    });
  }

  tierInfo() {
    if (!this.tiers) {
      return [];
    }

    return this.tiers
      .filter(({ id }: any) => id <= this.currentTier.id && id > 1)
      .map(({ starting_unit, ending_unit, price, price_format }: any) => {
        let value;

        if (price_format === 'PerUnit') {
          value = this.intl?.t('billing.pricing-metric.price-per-unit', {
            price: price * this.blockSize,
            unit: numberFormatter(this.blockSize),
          });
        } else if (price_format === 'FlatFee') {
          value = `$${numericFormatter(price, 2, true)}`;
        }

        return {
          label: this.intl?.t('billing.pricing-metric.from-amount', {
            startingUnit: numberFormatter(starting_unit),
            endingUnit: numberFormatter(ending_unit),
          }),
          value,
          include: this.blockSize > 1,
          small: true,
        };
      });
  }

  getTierForUsage(usage: number) {
    if (!this.tiers || this.tiers.length === 0) {
      return null;
    }
    return this.tiers.find(
      ({ starting_unit, ending_unit }: any) => starting_unit <= usage && ending_unit >= usage,
    );
  }

  pricing5UsageIncludedText(amount?: number): string {
    if (!this.intl) {
      throw new Error('No IntlService is present');
    }
    switch (this.metric) {
      case Metric.sms_segments_sent_received_monthly_us:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_us:
        return this.intl.t('billing.pricing-metric.pricing-5-usage-included-texts.sms-region.us');
      case Metric.sms_segments_sent_received_monthly_uk:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk:
        return this.intl.t('billing.pricing-metric.pricing-5-usage-included-texts.sms-region.uk');
      case Metric.sms_segments_sent_received_monthly_ca:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca:
        return this.intl.t('billing.pricing-metric.pricing-5-usage-included-texts.sms-region.ca');
      case Metric.sms_segments_sent_received_monthly_au:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_au:
        return this.intl.t('billing.pricing-metric.pricing-5-usage-included-texts.sms-region.au');
    }

    return this.pluralize(amount);
  }

  pricing5PerAdditionalCostText(): string {
    if (!this.intl) {
      throw new Error('No IntlService is present');
    }
    switch (this.metric) {
      case Metric.sms_segments_sent_received_monthly_us:
      case Metric.sms_segments_sent_received_monthly_uk:
      case Metric.sms_segments_sent_received_monthly_ca:
      case Metric.sms_segments_sent_received_monthly_au:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_us:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_au:
        return this.intl.t('billing.pricing-metric.pricing-5-current-tier-text.sms-segment');
      case Metric.whatsapp_outbound:
        return this.intl.t('billing.pricing-metric.pricing-5-current-tier-text.whatsapp_outbound');
      case Metric.emails_sent:
        return this.intl.t('billing.pricing-metric.pricing-5-current-tier-text.emails_sent');
    }

    return this.pluralize(1);
  }

  pluralize(amount?: any): string {
    if (!this.intl) {
      throw new Error('No IntlService is present');
    }
    switch (this.metric) {
      case Metric.thirty_day_messaged_contacts:
        return this.intl.t('billing.pricing-metric.people-reached');
      case Metric.thirty_day_messaged_contacts_product_tours:
        return this.intl.t('billing.pricing-metric.guided-users', { count: amount });
      case Metric.latest_daily_admin_count:
      case Metric.support_seat_count:
      case Metric.engage_seat_count:
      case Metric.marketing_seat_count:
      case Metric.proactive_support_seat_count:
      case Metric.contracted_solution_seat_count:
      case Metric.contracted_agent_count:
      case Metric.support_seat_count_or_latest_daily_admin_count:
        return this.intl.t('billing.pricing-metric.seats', { count: amount });
      case Metric.core_seat_count:
        return this.intl.t('billing.pricing-metric.core_seats', { count: amount });
      case Metric.light_seat_count:
        return this.intl.t('billing.pricing-metric.light_seats', { count: amount });
      case Metric.inbox_seats:
        return this.intl.t('billing.pricing-metric.inbox-seats', { count: amount });
      case Metric.active_people:
      case Metric.monthly_active_people:
      case Metric.monthly_active_people_beta:
      case Metric.quarterly_active_people:
        return this.intl.t('billing.pricing-metric.active-people');
      case Metric.people_count:
        return this.intl.t('billing.pricing-metric.people-count');
      case Metric.monthly_calls_deflected:
        return this.intl.t('billing.pricing-metric.calls-deflected', { count: amount });
      case Metric.monthly_surveys_sent:
        return this.intl.t('billing.pricing-metric.surveys-sent', { count: amount });
      case Metric.fixed:
        return '';
      case Metric.resolutions:
        return this.intl.t('billing.pricing-metric.fin-resolutions', { count: amount });
      case Metric.resolutions_with_custom_answers:
      case Metric.mapped_resolutions_with_custom_answers:
        return this.intl.t('billing.pricing-metric.resolutions', { count: amount });
      case Metric.active_phone_numbers_count_us:
        return this.intl.t('billing.pricing-metric.sms-region.us');
      case Metric.active_phone_numbers_count_uk:
        return this.intl.t('billing.pricing-metric.sms-region.uk');
      case Metric.active_phone_numbers_count_ca:
        return this.intl.t('billing.pricing-metric.sms-region.ca');
      case Metric.active_phone_numbers_count_au:
        return this.intl.t('billing.pricing-metric.sms-region.au');
      case Metric.sms_segments_sent_received_monthly_us:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_us:
      case Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_us:
        return this.intl.t('billing.pricing-metric.sms-region.us');
      case Metric.sms_segments_sent_received_monthly_uk:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk:
      case Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_uk:
        return this.intl.t('billing.pricing-metric.sms-region.uk');
      case Metric.sms_segments_sent_received_monthly_ca:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca:
      case Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_ca:
        return this.intl.t('billing.pricing-metric.sms-region.ca');
      case Metric.sms_segments_sent_received_monthly_au:
      case Metric.sms_segments_sent_received_in_shifted_billing_cycle_au:
      case Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_au:
        return this.intl.t('billing.pricing-metric.sms-region.au');
      case Metric.whatsapp_inbound:
        return this.intl.t('billing.pricing-metric.whatsapp-inbound', { count: amount });
      case Metric.whatsapp_outbound:
        return this.intl.t('billing.pricing-metric.whatsapp-outbound', { count: amount });
      case Metric.emails_sent:
        return this.intl.t('billing.pricing-metric.emails-sent', { count: amount });
      case Metric.calling:
        return this.intl.t('billing.pricing-metric.calling', { count: amount });
      case Metric.messages_sent:
        return this.intl.t('billing.pricing-metric.messages-sent', { count: amount });
      case Metric.copilot_seat_count:
        return this.intl.t('billing.pricing-metric.copilot', { count: amount });
      case Metric.fin_bucket_resolutions:
        return this.intl.t('billing.pricing-metric.fin-bucket-resutions', { count: amount });
      default:
        // Never returns anything, but will fail to compile if a new metric is added without handling it in the switch.
        return this.staticallyCheckForUnhandledMetrics(this.metric);
    }
  }

  staticallyCheckForUnhandledMetrics(metric: never): string {
    throw new Error(`Pluralization of unknown metric (${metric}) is not supported.`);
  }

  get isSeatMetric(): boolean {
    return [
      Metric.latest_daily_admin_count,
      Metric.inbox_seats,
      Metric.support_seat_count,
      Metric.engage_seat_count,
      Metric.marketing_seat_count,
      Metric.proactive_support_seat_count,
      Metric.core_seat_count,
      Metric.light_seat_count,
      Metric.copilot_seat_count,
    ].includes(this.metric);
  }

  get isFixedMetric(): boolean {
    return Metric.fixed === this.metric;
  }

  allHigherTiersPerUnit(baseTierId: any) {
    let higherTiers = this.tiers?.filter(({ id }: any) => id >= baseTierId);
    return (
      isPresent(higherTiers) && higherTiers.every((tier: any) => tier.price_format === 'PerUnit')
    );
  }

  finSpecificTooltipSummary() {
    return {
      label: this.intl?.t('billing.pricing-metric.fin-price-per-unit', {
        price: numberFormatter(this.perUnitPrice / 100),
      }),
      include: this.isResolutionsMetric,
    };
  }

  p5PricingTooltipSeatsOnly() {
    return {
      label: this.intl?.t('paywalls.pricing-tooltip.pricing-five.core-seats', {
        usage: this.usage,
      }),
      value: `$${numericFormatter(this.perUnitPrice / 100, 2, true)}`,
    };
  }

  tooltipSummary() {
    let showMinimumUsageInfo =
      this.usageAboveMinimum > 0 || (this.usageAboveMinimum === 0 && this.usage === 0);

    let minimumUsageRow = {
      label: showMinimumUsageInfo
        ? this.minimumUsageInfo
        : this.intl?.t('billing.pricing-metric.tooltip-summary-usage', {
            minimumUsageInfo: this.minimumUsageInfo,
            usage: this.usage,
          }),
      // We dont include minimumUsage for resolutions.
      // We dont include minimumUsage for metrics with a zero value of included.
      include: !this.isResolutionsMetric && this.minimumUsage !== 0,
    };

    let additionalUsageRow = {
      label: this.usageAboveMinimumInfo,
      value: this.formattedUsageAboveMinimumPrice,
      // We don't include usageAboveMinimum unless there is some and its not resolutions
      include: this.usageAboveMinimum > 0 && !this.isResolutionsMetric,
    };

    let tierInfoRow = this.tierInfo();

    let finInfoRow = this.finSpecificTooltipSummary();

    return [finInfoRow, minimumUsageRow, additionalUsageRow, ...tierInfoRow].filter(
      ({ include }) => include,
    );
  }

  // The minimum tier that actually costs something
  get minimumPaidTier() {
    return this.tiers.find((tier: any) => tier.price > 0) || this.tiers.firstObject;
  }

  formattedMinimumPaidTier() {
    return `$${this.minimumPaidTier!.price}`;
  }

  get hasLinearPricing() {
    if (this.blockSize && this.blockSize !== 1) {
      return false;
    }
    if (this.chargeModel === ChargeModel.PerUnit) {
      return true;
    }
    if (this.tiers?.length !== 2) {
      return false;
    }

    // Resolutions on VBP 2.2 has a free first tier with usage included but has unit pricing after that
    let firstTier = this.tiers[0];
    let secondTier = this.tiers[1];
    return (
      firstTier.price_format === TierPriceFormat.FlatFee &&
      firstTier.price === 0 &&
      secondTier.price_format === TierPriceFormat.PerUnit
    );
  }

  get isResolutionsMetric(): boolean {
    return (
      this.metric === Metric.resolutions || this.metric === Metric.resolutions_with_custom_answers
    );
  }
}
